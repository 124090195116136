import axios from "axios";

export async function postFiles(idJoueur, destFile, formData) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}postFile/?id_joueur=${idJoueur}&dest_file=${destFile}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Assurez-vous que le type de contenu est correct
            },
        });
        return response.status;
    } catch (error) {
        console.error('An error occurred during file upload:', error.message); // Affichez le message d'erreur
        console.error('Response data:', error.response.data); // Affichez les données de la réponse (si disponibles)
        console.error('Response status:', error.response.status); // Affichez le code d'état HTTP de la réponse (si disponibles)
    }
}