import axios from "axios";

export async function updateCompositionsMatchs(id_match, titulaires, remplacants, numero_maillots){
    try{
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}updateCompositionsMatchs/?id_match=${id_match}&titulaires=${titulaires}&remplacants=${remplacants}&numero_maillots=${numero_maillots}`)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `Update compositions match [${id_match}] in database : SUCCES !`
            return msg;
        } 
    } catch (error) {
        console.log(error);
    }
}
