import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import UcFirst from '../fonctions/UcFirst';
import { getCompositionsById } from '../../api/compositions/getCompositionsById';
import { getCompositionsJoueursByIdComposition } from '../../api/compositions/getCompositionsJoueursByIdComposition';
import { getSystemesById } from '../../api/systemes/getSystemesById';
import { getSystemes } from '../../api/systemes/getSystemes';
import { getJoueursById } from '../../api/joueurs/getJoueursById';


function InformationsCompositions() {
    // CHANGER ELEMENT AU SCROLL
    // const [scroll, setScroll] = useState(false);
    // const elementRef = useRef(null);
    // useEffect(() => {
    //     const handleScroll = ChangeElementToScroll(setScroll, elementRef);
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    // useEffect(() => {
    //     if (scroll === true) {
    //         const navbarChildrenRight = document.querySelector('.navbar-children.right');
    //         if (navbarChildrenRight) {
    //             navbarChildrenRight.classList.remove('desactive');
    //         }
    //     } else {
    //         const navbarChildrenRight = document.querySelector('.navbar-children.right');
    //         if (navbarChildrenRight) {
    //             navbarChildrenRight.classList.add('desactive');
    //         }
    //     }
    // }, [scroll]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////   

    // RECUPERATION Compositions DE LA BASE DE DONNEES
    const [CompositionsById, setCompositionsById] = useState([]);
    const { id } = useParams();
    const fetchId = useCallback(async () => {
        const CompositionsById = await getCompositionsById(id);
        setCompositionsById(CompositionsById);
    }, [id]);

    useEffect(() => {
        fetchId(id);
    }, [id, fetchId]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // RÉCUPÉRATION Systemes DE LA BASE DE DONNÉES

    const [Systemes, setSystemes] = useState([]);
    const [SystemeComposition, setSystemeComposition] = useState([]);
    const [reversedSysteme, setReversedSysteme] = useState([]);
    let [joueursDeChamp, setjoueursDeChamp] = useState("");
    let [joueursRemplacants, setjoueursRemplacants] = useState("");

    useEffect(() => {
        CompositionsById.forEach(async (item) => {
            const systemes = await getSystemes();
            const systemeComposition = await getSystemesById(item.id_systeme);
            setSystemes(() => [
                ...systemes,
            ]);
            setSystemeComposition(systemeComposition[0])

            const systeme = systemeComposition[0].disposition;
            const systemeParts = systeme.split("-");
            setReversedSysteme(systemeParts.reverse());

            setjoueursDeChamp(systemeComposition[0].joueurs_de_champ)
            setjoueursRemplacants(systemeComposition[0].joueurs_de_champ)

        });
    }, [CompositionsById]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // CHANGER VALEUR ADRESSE STADE
    const handleSystemeChange = async (event) => {
        const newValue = event.target.value;
        setSystemeComposition(newValue);

        // Récupérer nouveau systeme
        const selectedOptionId = event.target.options[event.target.selectedIndex].id;
        const systemeComposition = await getSystemesById(selectedOptionId);
        setSystemeComposition(systemeComposition[0])
        const systeme = systemeComposition[0].disposition;
        const systemeParts = systeme.split("-");
        setReversedSysteme(systemeParts.reverse());
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // RÉCUPÉRATION Joueurs composition DE LA BASE DE DONNÉES

    const [infosJoueurs, setInfosJoueurs] = useState([]);

    useEffect(() => {
        const fetchJoueurs = async () => {
            const allJoueursComposition = [];
            for (const item of CompositionsById) {
                const IdJoueurs = await getCompositionsJoueursByIdComposition(item.id);
                const joueursCompositionPromises = IdJoueurs.map(async (item) => {
                    const JoueursComposition = await getJoueursById(item.id_joueur);
                    return JoueursComposition;
                });
                const joueursCompositionResults = await Promise.all(joueursCompositionPromises);
                allJoueursComposition.push(...joueursCompositionResults);
            }
            setInfosJoueurs(allJoueursComposition);
        };

        fetchJoueurs();
    }, [CompositionsById]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className='informationsCompositions-children'>
            {CompositionsById.map((item, index) => {
                return (
                    <div key={index}>
                        <div className='formulaires-corpContainer'>
                            <div className='formulaires-infosContainer matchs-hidden'>
                                <div className='entete'>Informations compositions</div>
                                <div className='corps'>
                                    <label>Nom :</label>
                                    <input
                                        readOnly
                                        type="text"
                                        defaultValue={UcFirst(item.nom)}
                                    />

                                    <label>Système de jeu :</label>
                                    <select value={SystemeComposition.nom} onChange={handleSystemeChange}>
                                        {Systemes.map((itemSysteme, index) => (
                                            <option key={index} value={itemSysteme.nom} id={itemSysteme.id}>
                                                {itemSysteme.nom}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className='formulaires-infosContainer'>
                                <div className='entete'>Titulaires</div>
                                <div className='corps composition-container'>
                                    <div className={`composition-joueurContainer systeme_${reversedSysteme.length}_ligne`}>
                                        {reversedSysteme.map((part, index) => {
                                            const count = parseInt(part);
                                            const elements = [];
                                            for (let i = 0; i < count; i++) {
                                                const joueurIndex = joueursDeChamp - i - 1;
                                                const joueur = infosJoueurs[joueurIndex];

                                                elements.push(
                                                    <div key={i}>
                                                        <div><img src={joueur ? joueur[0].photo : ''} alt="Logo" /></div>
                                                        <div>{joueur ? joueur[0].prenom : ''} {joueur ? joueur[0].nom.charAt(0) + '.' : ''}</div>
                                                    </div>
                                                );
                                            }
                                            joueursDeChamp -= count;
                                            return (
                                                <div className='composition-joueurChildren' key={index} style={{ gridTemplateColumns: `repeat(${part}, 1fr)` }}>
                                                    {elements}
                                                </div>
                                            );
                                        })}

                                        <div className='composition-joueurChildren' key={index} style={{ gridTemplateColumns: `repeat(1, 1fr)` }}>
                                            {infosJoueurs.length > 0 &&
                                                <div>
                                                    <div><img src={infosJoueurs[0][0].photo} alt="Logo" /></div>
                                                    <div>{infosJoueurs[0][0].prenom} {infosJoueurs[0][0].nom.charAt(0) + '.'}</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='formulaires-infosContainer'>
                                <div className='entete'>Remplaçants</div>
                                <div className='corps composition-remplacantsContainer'>
                                    {infosJoueurs.slice(joueursRemplacants).map((itemInfosJoueurs, index) => (
                                        <div className="composition-remplacantsChildren" key={index}>
                                            <div><img src={itemInfosJoueurs[0].photo} alt="Logo" /></div>
                                            <div>{itemInfosJoueurs[0].prenom} {itemInfosJoueurs[0].nom.charAt(0) + '.'}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default InformationsCompositions;
