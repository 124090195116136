import React, { useEffect, useState, useCallback } from 'react';
import { getSystemes } from '../../api/systemes/getSystemes';
import { getJoueurs } from '../../api/joueurs/getJoueurs';
import { getDirigeants } from '../../api/dirigeants/getDirigeants';
import { getMatchsById } from '../../api/matchs/getMatchsById';
import { insertCompositions } from '../../api/compositions/insertCompositions';
import { updateCompositions } from '../../api/compositions/updateCompositions';
import { getRoles } from '../../api/roles/getRoles';
import { getPostes } from '../../api/postes/getPostes'
import Modal from '../Modal'
import UcFirst from '../fonctions/UcFirst';
import ModalOuiNon from '../modal/ModalOuiNon'
import { useLocation } from 'react-router-dom';
import Tuile from '../tuiles/tuiles'
import { useNavigate } from 'react-router-dom';
import globalStorage from '../../components/fonctions/GlobalStorage';
import Draggable from 'react-draggable';

function Composition({ show, tabComposition }) {
    ///////////////
    // VARIABLES //
    ///////////////

    // RECUPERATION ID MATCH
    const navigate = useNavigate();
    const location = useLocation();
    const currentURL = location.pathname;
    const parts = currentURL.split("/"); // Divise la chaîne en segments en utilisant le séparateur "/"
    const valeur = parts.pop(); // Récupère le dernier élément du tableau
    const IdMatch = valeur.replace(/%20/g, " "); // Remplace les %20 en espace

    const [NbTitulaires, setNbTitulaires] = useState([])
    const [NbRemplacants, setNbRemplacants] = useState([])
    const [NbDirigeants, setNbDirigeants] = useState(1)
    const [InfosJoueurs, setInfosJoueurs] = useState([])
    const [LignesTerrain, setLignesTerrain] = useState([])
    let [CompteurTitulaires, setCompteurTitulaires] = useState([])
    let [LabelFiltre, setLabelFiltre] = useState([])
    const [ValeursParLabel, setValeursParLabel] = useState([])
    const [Systeme, setSysteme] = useState("")
    const [IdSysteme, setIdSysteme] = useState("")

    const [ShowModal, setShowModal] = useState(false);
    const [ContentModal, setContentModal] = useState(false);
    const [ReloadPage, setReloadPage] = useState(false);

    const [FilterCategorie, setFilterCategorie] = useState("");
    const [FilterPoste, setFilterPoste] = useState("");
    const [FilterPiedFort, setFilterPiedFort] = useState("");
    const [FilterRole, setFilterRole] = useState("");
    const [TypeLicencieSelected, setTypeLicencieSelected] = useState("");

    const [IdTitulaireSelected, setIdTitulaireSelected] = useState(null); // Initialisation à null
    const [IdLicencie, setIdLicencie] = useState(null); // Initialisation à null

    const [ListeLicencies, setListeLicencies] = useState([]);

    const [IdCapitaine, setIdCapitaine] = useState([]);

    const [InfosDirigeants, setInfosDirigeants] = useState([])
    const [ListeLicenciesDirigeants, setListeLicenciesDirigeants] = useState([]);
    const [IdLicencieDirigeant, setIdLicencieDirigeant] = useState(null); // Initialisation à null
    const [IdDirigeantSelected, setIdDirigeantSelected] = useState(null); // Initialisation à null

    const [NomMatch, setNomMatch] = useState([])

    const [CacherElement, setCacherElement] = useState(false)
    const [CacherNoms, setCacherNoms] = useState(false)
    const [CacherContainerBouton, setCacherContainerBouton] = useState(false)
    const [CacherNums, setCacherNums] = useState(false)
    const [CacherMutation, setCacherMutation] = useState(false)
    const [CacherCapitaine, setCacherCapitaine] = useState(false)
    const [CacherModifierCompo, setCacherModifierCompo] = useState(false)
    const [CacherBanc, setCacherBanc] = useState(false)
    const [BoutonAnnuler, setBoutonAnnuler] = useState(false)

    const [PleinEcran, setPleinEcran] = useState(false)
    const [BoutonCacherContainer, setBoutonCacherContainer] = useState(<i className="fa-solid fa-xmark"></i>)

    const [Rotation, setRotation] = useState(0)
    const { gsToggleDisplayFooter, gsToggleDisplayHeader } = globalStorage();

    const MinZoom = 8;
    const MaxZoom = 22;
    const [ImageSize, setImageSize] = useState('')

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    const fetchId = useCallback(async () => {
        // VARIABLES
        const nbTitulairesSelected = parseInt(NbTitulaires, 10);
        const systemeSelected = Systeme;

        // FILTRES
        const labelFiltre = [
            { id: 1, nom: "Nb titulaires", tab: "nbTitulaires" },
            { id: 2, nom: "Systèmes", tab: "systemes" },
            { id: 3, nom: "Remplaçants", tab: "remplacants" },
            { id: 4, nom: "Dirigeants", tab: "dirigeants" },
            { id: 5, nom: "Capitaine", tab: "capitaine" },
        ];
        setLabelFiltre(labelFiltre)

        // GET MATCH
        const matchById = await getMatchsById(IdMatch);
        setNomMatch(matchById[0].nom)

        // GET SYSTEMES
        const allSystemes = await getSystemes();
        let systemes = allSystemes.filter(item => item.joueurs_de_champ === nbTitulairesSelected);
        systemes = systemes.map(value => ({
            nom: value.nom
        }));

        // GET SYSTEM
        if (systemeSelected) {
            let idSysteme = allSystemes.find(item => item.nom === systemeSelected).id;
            setIdSysteme(idSysteme)
        }

        // GET DISPOSITION
        if (systemeSelected) {
            let disposition = allSystemes.find(item => item.nom === systemeSelected).disposition;
            disposition = disposition.split("-");
            const reversedDisposition = [...disposition].reverse();
            reversedDisposition.push('1');
            setLignesTerrain(reversedDisposition)
        }

        // GET JOUEURS DE CHAMP
        let nbTitulaires = [...new Set(allSystemes.map(systeme => systeme.joueurs_de_champ))];
        nbTitulaires = nbTitulaires.map(value => ({
            nom: value
        }));

        // GET REMPLACANTS JOUEURS STANDARD
        const remplacants = Array.from({ length: 12 }, (_, index) => (
            {
                nom: index + 1,
            }
        ));

        // GET DIRIGEANTS STANDARD
        const dirigeants = Array.from({ length: 5 }, (_, index) => (
            {
                nom: index + 1,
            }
        ));

        // GET CAPITAINE LISTE
        let capitaine = [...new Set(InfosJoueurs.map(item => item.idLicencie))];
        capitaine = capitaine.filter(obj => obj !== undefined);
        if (capitaine.length > 0) {
            capitaine = capitaine
                .filter(value => value !== "") // Filtrer les valeurs vides
                .map(value => {
                    const joueur = InfosJoueurs.find(item => parseInt(item.idLicencie) === parseInt(value));

                    if (joueur.role === 'titulaire') {
                        return {
                            nom: joueur.numeroMaillot + ' - ' + UcFirst(joueur.prenom) + ' ' + joueur.nom.toUpperCase(),
                            id: joueur.idLicencie
                        };
                    } else {
                        return {

                        }
                    }
                });
        }

        // Supprimer les lignes vides du tableau
        capitaine = capitaine.filter(obj => Object.keys(obj).length !== 0);

        // CONSTRUTION TABLEAU LABEL
        const valeursParLabel = {
            nbTitulaires: nbTitulaires,
            systemes: systemes,
            remplacants: remplacants,
            capitaine: capitaine,
            dirigeants: dirigeants,
            // ... ajoutez plus de labels et leurs valeurs ici
        };
        setValeursParLabel(valeursParLabel)

        // GET JOUEURS
        let listeJoueurs = await getJoueurs();
        listeJoueurs = listeJoueurs.filter(itemFilter => itemFilter.statut !== 'supprimé');

        // GET DIRIGEANTS
        const listeDirigeants = await getDirigeants();

        // GET ROLES
        const listeRoles = await getRoles();

        // GET CATEGORIES
        let categorie = [...new Set(listeJoueurs.map(joueur => joueur.categorie))];
        categorie = categorie.map(item => [item]);

        // GET POSTE
        let poste = await getPostes();
        poste = poste.map(item => item.nom);

        // GET PIED_FORT
        let pied_fort = [...new Set(listeJoueurs.map(joueur => joueur.pied_fort))];
        pied_fort = pied_fort.map(item => [item]);

        // GET ROLES
        let roles = [...new Set(listeRoles.map(role => role.nom))];
        roles = roles.map(item => [item]);

        // FILTRES
        const joueursFiltres = listeJoueurs.filter(item => {
            const categorie = FilterCategorie !== '' ? item.categorie.toLowerCase().includes(FilterCategorie) : true;
            const poste = FilterPoste !== '' ? item.postes.toLowerCase().includes(FilterPoste) : true;
            const pied_fort = FilterPiedFort !== '' ? item.pied_fort.toLowerCase().includes(FilterPiedFort) : true;

            return categorie && poste && pied_fort;
        });
        const dirigeantsFiltres = listeDirigeants.filter(item => {
            const roles = FilterRole !== '' ? item.roles.toLowerCase().includes(FilterRole) : true;

            return roles;
        });

        // GENERER BOUTONS FILTRES
        function generateFilterOptions(label, filterList, filterValue) {
            return (
                <div className='listeFiltre-children'>
                    <label>{label} : </label><br />
                    <select
                        onChange={(e) => handleFilterJoueursChange(e)}
                        id={label.toLowerCase()}
                        defaultValue={filterValue}
                    >
                        <option value="">---</option>
                        {filterList.length > 0 ? (
                            filterList.map((item, index) => (
                                <option
                                    key={index}
                                    value={item}
                                >
                                    {UcFirst(String(item).toLocaleLowerCase())}
                                </option>
                            ))
                        ) : (
                            <option disabled>ERREUR !!!</option>
                        )}
                    </select>
                </div>
            );
        }

        // MAJ TABLEAU TITULAIRES
        if (IdLicencie !== null) {
            const updatedInfosJoueurs = InfosJoueurs.map(item => {
                if (item.id === parseInt(IdTitulaireSelected)) {
                    return {
                        ...item,
                        idLicencie: listeJoueurs.find(item => item.id === parseInt(IdLicencie)).id,
                        photo: listeJoueurs.find(item => item.id === parseInt(IdLicencie)).photo,
                        nom: listeJoueurs.find(item => item.id === parseInt(IdLicencie)).nom,
                        prenom: listeJoueurs.find(item => item.id === parseInt(IdLicencie)).prenom,
                        mutation: listeJoueurs.find(item => item.id === parseInt(IdLicencie)).mutation.toLowerCase(),
                    };
                }
                return item;
            });
            setInfosJoueurs(updatedInfosJoueurs)
            setIdLicencie(null)
        }

        // MAJ TABLEAU DIRIGEANTS
        if (IdLicencieDirigeant !== null) {
            let updatedInfosDirigeants = InfosDirigeants;

            if (IdDirigeantSelected === 'adjoint') {
                updatedInfosDirigeants.push({
                    id: updatedInfosDirigeants.length + 1,
                    idLicencie: listeDirigeants.find(item => item.id === parseInt(IdLicencieDirigeant)).id,
                    photo: listeDirigeants.find(item => item.id === parseInt(IdLicencieDirigeant)).photo,
                    nom: listeDirigeants.find(item => item.id === parseInt(IdLicencieDirigeant)).nom,
                    prenom: listeDirigeants.find(item => item.id === parseInt(IdLicencieDirigeant)).prenom,
                    role: 'adjoint'
                });
            } else {
                updatedInfosDirigeants = InfosDirigeants.map(item => {
                    if (item.id === parseInt(IdDirigeantSelected)) {
                        return {
                            ...item,
                            idLicencie: listeDirigeants.find(item => item.id === parseInt(IdLicencieDirigeant)).id,
                            photo: listeDirigeants.find(item => item.id === parseInt(IdLicencieDirigeant)).photo,
                            nom: listeDirigeants.find(item => item.id === parseInt(IdLicencieDirigeant)).nom,
                            prenom: listeDirigeants.find(item => item.id === parseInt(IdLicencieDirigeant)).prenom,
                        };
                    }
                    return item;
                });
            }

            setInfosDirigeants(updatedInfosDirigeants)
            setIdLicencieDirigeant(null)
        }

        // MAJ CONTENU MODAL LISTE JOUEURS
        const listeLicencies = () => (
            <div className='selectionJoueur-container'>
                <div className='listeFiltre-container bg-filtre'>
                    {generateFilterOptions("Catégorie", categorie, FilterCategorie)}
                    {generateFilterOptions("Poste", poste, FilterPoste)}
                    {generateFilterOptions("Pied fort", pied_fort, FilterPiedFort)}
                </div>

                <div className='tuile-container '>
                    {joueursFiltres.length > 0 ? (
                        joueursFiltres.map((item, index) => {
                            return (
                                <div key={index}>
                                    <Tuile
                                        var_handleOnClick={handleLicencieClick}
                                        var_id={item.id}
                                        var_link={item.id}
                                        var_image={item.photo}
                                        var_infos={[
                                            UcFirst(item.prenom) + ' ' + item.nom.toUpperCase(),
                                            UcFirst(item.categorie.toLowerCase()),
                                            item.postes,
                                            UcFirst(item.pied_fort),
                                            UcFirst(item.licence),
                                            UcFirst(item.mutation),
                                        ]}
                                        var_class={InfosJoueurs.some(search => parseInt(search.idLicencie, 10) === parseInt(item.id, 10)) ? 'bg-selectedNone' : ''}
                                    />
                                </div>
                            )
                        })
                    ) : (
                        <div className='aucunResultat'>Aucun élément trouvé</div>
                    )
                    }
                </div>
            </div>
        )

        setListeLicencies(listeLicencies)

        // MAJ CONTENU MODAL LISTE DIRIGEANTS
        const listeLicenciesDirigeants = () => (
            <div className='selectionDirigeant-container'>
                <div className='listeFiltre-container bg-filtre'>
                    {generateFilterOptions("Rôles", roles, FilterRole)}
                </div>

                <div className='tuile-container '>
                    {dirigeantsFiltres.length > 0 ? (
                        dirigeantsFiltres.map((item, index) => {
                            return (
                                <div key={index}>
                                    <Tuile
                                        var_handleOnClick={handleLicencieDirigeantsClick}
                                        var_id={item.id}
                                        var_link={item.id}
                                        var_image={item.photo}
                                        var_infos={[
                                            UcFirst(item.prenom) + ' ' + item.nom.toUpperCase(),
                                            UcFirst(item.categories.toLowerCase()),
                                            UcFirst(item.roles),
                                            UcFirst(item.licence)
                                        ]}
                                        var_class={InfosDirigeants.some(search => parseInt(search.idLicencie, 10) === parseInt(item.id, 10)) ? 'bg-selectedNone' : ''}
                                    />
                                </div>
                            )
                        })
                    ) : (
                        <div className='aucunResultat'>Aucun élément trouvé</div>
                    )
                    }
                </div>
            </div>
        )

        setListeLicenciesDirigeants(listeLicenciesDirigeants)

        if (TypeLicencieSelected === "joueur") {
            setContentModal(listeLicencies)
        } else if (TypeLicencieSelected === "dirigeant") {
            setContentModal(listeLicenciesDirigeants)
        }

        // SI LA COMPO EXISTE DEJA
        if ((tabComposition.length > 0 && (InfosJoueurs.length === 0 || BoutonAnnuler === true))) {
            const systemeComposition = allSystemes.filter(itemFilter => itemFilter.id === tabComposition[0].id_systeme)
            const tabIdTitulaires = tabComposition[0].titulaires.split(',')
            const tabIdRemplacants = tabComposition[0].remplacants.split(',')
            const tabIdDirigeants = tabComposition[0].dirigeants.split(',')
            const tabIdCapitaine = tabComposition[0].id_capitaine
            const tabNumeroMaillots = tabComposition[0].numero_maillots.split(',')
            const joueursData = [];
            const dirigeantsData = [];

            tabIdTitulaires.forEach((idTitulaire, index) => {
                const joueur = listeJoueurs.filter(itemFilter => parseInt(itemFilter.id) === parseInt(idTitulaire));
                if (joueur) {
                    joueursData.push({
                        id: index + 1,
                        nom: joueur[0].nom,
                        prenom: joueur[0].prenom,
                        photo: joueur[0].photo,
                        numeroMaillot: tabNumeroMaillots[index],
                        role: 'titulaire',
                        idLicencie: joueur[0].id,
                        mutation: joueur[0].mutation.toLowerCase(),
                    });
                }
            });

            if (tabComposition[0].remplacants !== '') {
                tabIdRemplacants.forEach((idRemplacant, index) => {
                    const joueur = listeJoueurs.filter(itemFilter => parseInt(itemFilter.id) === parseInt(idRemplacant));
                    if (joueur) {
                        joueursData.push({
                            id: tabIdTitulaires.length + 1,
                            nom: joueur[0].nom,
                            prenom: joueur[0].prenom,
                            photo: joueur[0].photo,
                            numeroMaillot: tabNumeroMaillots[tabIdTitulaires.length + index],
                            role: 'remplacant',
                            idLicencie: joueur[0].id,
                            mutation: joueur[0].mutation.toLowerCase(),
                        });
                    }
                });
            }

            tabIdDirigeants.forEach((idDirigeant, index) => {
                const dirigeant = listeDirigeants.filter(itemFilter => parseInt(itemFilter.id) === parseInt(idDirigeant));
                if (dirigeant) {
                    dirigeantsData.push({
                        id: index + 1,
                        nom: dirigeant[0].nom,
                        prenom: dirigeant[0].prenom,
                        photo: dirigeant[0].photo,
                        role: 'entraineur',
                        idLicencie: dirigeant[0].id,
                    });
                }
            });

            setCacherElement(true)
            setNbTitulaires(systemeComposition[0].joueurs_de_champ)
            setSysteme(systemeComposition[0].nom)
            setCompteurTitulaires(systemeComposition[0].joueurs_de_champ)
            setInfosJoueurs(joueursData)
            setInfosDirigeants(dirigeantsData)
            setIdCapitaine(tabIdCapitaine)
            setNbRemplacants(tabComposition[0].remplacants !== "" ? tabComposition[0].remplacants.split(',').length : '')

            if (BoutonAnnuler === true) {
                setBoutonAnnuler(false)
            }
        }
    }, [tabComposition, NbTitulaires, Systeme, FilterCategorie, FilterPoste, FilterPiedFort, FilterRole, TypeLicencieSelected, IdTitulaireSelected, IdDirigeantSelected, IdLicencie, IdLicencieDirigeant, InfosJoueurs, InfosDirigeants, IdMatch, BoutonAnnuler]);

    useEffect(() => {
        fetchId();
    }, [fetchId]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////
    // FONCTIONS CLIC //
    ////////////////////

    const handleFilterChange = (e) => {
        let newValue = e.target.value;
        const idValue = e.target.id;
        if (newValue === '---') {
            newValue = ''
        }
        if (idValue === 'nbTitulaires') {
            const joueursData = Array.from({ length: newValue }, (_, index) => (
                {
                    id: index + 1,
                    nom: '',
                    prenom: 'Joueur ' + (index + 1),
                    photo: "/default/default-joueurs.png",
                    numeroMaillot: index + 1,
                    role: 'titulaire'
                }
            ));

            const dirigeantsData = Array.from({ length: 1 }, (_, index) => (
                {
                    id: index + 1,
                    nom: 'Principal',
                    prenom: 'Entraineur',
                    photo: "/default/default-dirigeants.png",
                    role: 'entraineur'
                }
            ));

            setLignesTerrain([])
            setInfosJoueurs(joueursData)
            setInfosDirigeants(dirigeantsData)
            setCompteurTitulaires(joueursData.length)
            setNbTitulaires(newValue)
            setSysteme('')
        } else if (idValue === 'systemes') {
            setSysteme(newValue)
            setInfosJoueurs(InfosJoueurs)

        } else if (idValue === 'remplacants') {
            const nbTitulaires = parseInt(NbTitulaires)
            const joueursTitulairesData = InfosJoueurs.filter((joueur) => joueur.role === 'titulaire');

            for (let index = 1; index <= newValue; index++) {
                joueursTitulairesData.push({
                    id: nbTitulaires + index,
                    nom: '',
                    prenom: 'Joueur ' + (nbTitulaires + index),
                    photo: "/default/default-joueurs.png",
                    numeroMaillot: nbTitulaires + index,
                    role: 'remplacant'
                });
            }

            setInfosJoueurs(joueursTitulairesData)
            setNbRemplacants(newValue)

        } else if (idValue === 'dirigeants') {
            let listeDirigeants = InfosDirigeants
            const nbDirigeantsAvant = listeDirigeants.length
            const nbDirigeantsApres = newValue - nbDirigeantsAvant

            if (nbDirigeantsApres > 0) {
                for (let index = 1; index <= nbDirigeantsApres; index++) {
                    listeDirigeants.push({
                        id: listeDirigeants.length + 1,
                        nom: 'Adjoint',
                        prenom: 'Entraineur',
                        photo: "/default/default-dirigeants.png",
                        idLicencie: "",
                        role: 'adjoint'
                    });
                }
            } else {
                listeDirigeants = listeDirigeants.slice(0, nbDirigeantsApres);
            }

            setInfosDirigeants(listeDirigeants)
            setNbDirigeants(newValue)
        } else if (idValue === 'capitaine') {
            setIdCapitaine(newValue)
        }
    };

    const handleFilterJoueursChange = (e) => {
        const value = e.target.value.toLowerCase();
        const id = e.target.id;
        if (id === 'catégorie') {
            setFilterCategorie(value)
        } else if (id === 'poste') {
            setFilterPoste(value)
        } else if (id === 'pied fort') {
            setFilterPiedFort(value)
        } else if (id === 'rôles') {
            setFilterRole(value)
        }
    };

    const handleJoueurClick = (e) => {
        const idTitulaireSelected = e.target.id;
        setShowModal(true)
        setContentModal(ListeLicencies)
        setIdTitulaireSelected(idTitulaireSelected)
        setTypeLicencieSelected('joueur')
    };

    const handleLicencieClick = (e) => {
        const idLicencie = e.target.id;
        setIdLicencie(idLicencie)
        setShowModal(false)
        setContentModal("")
    };

    const handleDirigeantsClick = (e) => {
        const idDirigeantSelected = e.target.id;
        setShowModal(true)
        setContentModal(ListeLicenciesDirigeants)
        setIdDirigeantSelected(idDirigeantSelected)
        setTypeLicencieSelected('dirigeant')
    };

    const handleLicencieDirigeantsClick = (e) => {
        const idLicencieDirigeant = e.target.id;
        setIdLicencieDirigeant(idLicencieDirigeant)
        setShowModal(false)
        setContentModal("")
    };

    async function handleSubmit(e) {
        // VARIABLES
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        let tabNumeroMaillots = [];
        let tabIdLicencies = [];

        // RECUPERATION INFOS FORMULAIRE
        for (const entry of formData.entries()) {
            const [key, value] = entry;

            if (key.includes("numeroMaillot")) {
                tabNumeroMaillots.push(value);
            } else if (key.includes("idLicencieJoueur")) {
                tabIdLicencies.push(value);
            }
        }

        // RECUPERATION ID JOUEURS TITULAIRES
        const tabJoueursAbsents = tabNumeroMaillots.filter((element, index) => {
            return tabIdLicencies[index] === "";
        });

        // VERIFICATION DOUBLON MAILLOTS
        let doublonNumeroMaillot;
        tabNumeroMaillots.forEach((item) => {
            if (InfosJoueurs.filter(search => parseInt(search.numeroMaillot) === parseInt(item)).length > 1) {
                doublonNumeroMaillot = true;
            }
        });

        // VERIFICATION SAISIE AVANT AJOUT EN BASE
        if (tabJoueursAbsents.length > 0) {
            // VERIFICATION PRESENCE DE TOUS LES JOUEURS
            setShowModal(true)
            setContentModal(
                <div>
                    <i className="fa-solid fa-triangle-exclamation fa-shake fa-lg" style={{ color: "#dfdb11", }}></i> Les joueurs suivants n'ont pas été sélectionnés : <br /><br />
                    <div className='cl-principalBold' style={{ fontWeight: "bold" }}>{tabJoueursAbsents.join(', ')}</div>
                </div>
            )
        } else {
            if (doublonNumeroMaillot === true) {
                // VERIFICATION DOUBLONS MAILLOTS
                setShowModal(true)
                setContentModal(
                    <div>
                        <i className="fa-solid fa-triangle-exclamation fa-shake fa-lg" style={{ color: "#dfdb11", }}></i> Il y a des doublons dans les numéros de maillots !
                    </div>
                )
            } else {
                // VERIFICATION PRESENCE CAPITAINE
                if (IdCapitaine.length === 0) {
                    setShowModal(true)
                    setContentModal(
                        <div>
                            <i className="fa-solid fa-triangle-exclamation fa-shake fa-lg" style={{ color: "#dfdb11", }}></i> Veuillez sélectionner un Capitaine
                        </div>
                    )
                } else {
                    // VERIFICATION PRESENCE ENTRAINEUR
                    if (InfosDirigeants[0].idLicencie === undefined || InfosDirigeants[0].idLicencie === "") {
                        setShowModal(true)
                        setContentModal(
                            <div>
                                <i className="fa-solid fa-triangle-exclamation fa-shake fa-lg" style={{ color: "#dfdb11", }}></i> Veuillez sélectionner l'entraineur principal
                            </div>
                        )
                    } else {
                        // VERIFICATION PRESENCE ADJOINT
                        let idLicencieVide = false;

                        if (InfosDirigeants.length > 1) {
                            for (let i = 0; i < InfosDirigeants.length; i++) {
                                if (InfosDirigeants[i].idLicencie === '') {
                                    idLicencieVide = true;
                                    break;
                                }
                            }
                        }

                        if (idLicencieVide === true) {
                            setShowModal(true)
                            setContentModal(
                                <div>
                                    <i className="fa-solid fa-triangle-exclamation fa-shake fa-lg" style={{ color: "#dfdb11", }}></i> Veuillez sélectionner tous les entraineurs adjoints
                                </div>
                            )
                        } else {
                            let joueurs = InfosJoueurs.map(item => item.idLicencie);
                            let numeroMaillots = InfosJoueurs.map(item => item.numeroMaillot);
                            joueurs = joueurs.join(', ');
                            joueurs = joueurs.split(',').map(valeur => valeur.trim())
                            const titulaires = joueurs.slice(0, NbTitulaires)
                            let affichageModal

                            let remplacants = [];
                            if (NbRemplacants && NbRemplacants > 0) {
                                remplacants = joueurs.slice(-NbRemplacants)
                            }

                            let dirigeants = InfosDirigeants.map(item => item.idLicencie);
                            dirigeants = dirigeants.join(', ');

                            const compositionExistante = tabComposition.filter(itemFilter => itemFilter.id_match === parseInt(IdMatch))

                            if (compositionExistante.length > 0) {
                                const idComposition = compositionExistante[0].id
                                affichageModal = "La composition suivante a été modifiée avec succès !"
                                const updateSql = await updateCompositions(idComposition, IdSysteme, IdCapitaine, IdMatch, NomMatch, joueurs, numeroMaillots, titulaires, remplacants, dirigeants);
                                console.log(updateSql)
                            } else {
                                affichageModal = "La composition suivante a été ajoutée avec succès !"
                                const insertSql = await insertCompositions(IdSysteme, IdCapitaine, IdMatch, NomMatch, joueurs, numeroMaillots, titulaires, remplacants, dirigeants);
                                console.log(insertSql)
                            }

                            setShowModal(true)
                            setContentModal(
                                <div>
                                    <div>{affichageModal}</div><br />
                                    <div className='cl-principalBold'>{NomMatch}</div>
                                </div>
                            )
                            setReloadPage(true)
                        }
                    }
                }
            }
        }

    };

    const handleNumeroMaillotChange = (e) => {
        const newNumeroMaillot = parseInt(e.target.value)
        const idJoueur = e.target.id
        const indexJoueur = InfosJoueurs.findIndex(item => parseInt(item.id) === parseInt(idJoueur));

        if (indexJoueur !== -1) {
            const updatedInfosJoueurs = [...InfosJoueurs];

            updatedInfosJoueurs[indexJoueur] = {
                ...updatedInfosJoueurs[indexJoueur],
                numeroMaillot: newNumeroMaillot
            };
            setInfosJoueurs(updatedInfosJoueurs)
        }
    };

    const handleDeleteJoueurClick = (e) => {
        const idElementTab = e.target.id

        // MODIFICATION TABLEAU
        const updatedArray = InfosJoueurs.map(joueur => {
            if (parseInt(joueur.id) === parseInt(idElementTab)) {
                return {
                    ...joueur,
                    nom: '',
                    prenom: 'Joueur ' + joueur.id,
                    photo: "/default/default-joueurs.png",
                    idLicencie: "",
                    mutation: "",
                };
            } else {
                return joueur; // Pas de changement pour les autres joueurs
            }
        });

        // SUPPRESSION CAPITAINE
        if (parseInt(idElementTab) === parseInt(IdCapitaine)) {
            setIdCapitaine('')
        }

        setInfosJoueurs(updatedArray)
    };

    const handleDeleteDirigeantClick = (e) => {
        const idElementTab = e.target.id
        let updatedArray = [];

        if (idElementTab > 1) {

            updatedArray = InfosDirigeants.filter(dirigeant => parseInt(dirigeant.idLicencie) !== parseInt(idElementTab));
        } else {
            //MODIFICATION TABLEAU
            updatedArray = InfosDirigeants.map(dirigeant => {
                if (parseInt(dirigeant.id) === parseInt(idElementTab)) {
                    return {
                        ...dirigeant,
                        nom: 'Principal',
                        prenom: 'Entraineur',
                        photo: "/default/default-dirigeants.png",
                        idLicencie: "",
                    };
                } else {
                    return dirigeant; // Pas de changement pour les autres dirigeants
                }
            });
        }

        setInfosDirigeants(updatedArray)
    };

    const handleResetClick = (e) => {
        setShowModal(true)
        setContentModal(
            <ModalOuiNon handleClick={handleResetValidationClick} affichage={'Etes-vous sur de vouloire réinitialiser la compo. ?'} />
        )
    }

    const handleAnnulerClick = (e) => {
        setBoutonAnnuler(true)
    }

    const handlePleinEcranClick = (e) => {
        // CHANGER GRID TEMPLATE ROW SITE-CONTAINER
        document.documentElement.style.setProperty('--gridTemplateRows-siteContainer', '1fr');
        gsToggleDisplayFooter()
        gsToggleDisplayHeader()
        setCacherModifierCompo(!CacherModifierCompo)
        setCacherBanc(!CacherBanc)
        setPleinEcran(!PleinEcran)
    }

    const handleRotationClick = (e) => {
        const id = parseInt(e.target.id)

        if (id === 0) {
            setRotation(90)
        } else if (id === 90) {
            setRotation(0)
        }
    }

    const handleZoomClick = (e) => {
        const id = e.target.id

        if (ImageSize === '' && id === '+') {
            setImageSize(MinZoom + 2)
        } else {

            if (id === '+') {
                if (ImageSize < MaxZoom) {
                    setImageSize(ImageSize + 2)
                }
            } else {
                if (ImageSize > MinZoom) {
                    setImageSize(ImageSize - 2)
                }
            }
        }
    }

    const handleEditClick = (e) => {
        setCacherElement(false)
        setCacherCapitaine(false)
        setCacherNums(false)
        setCacherNoms(false)
        setCacherMutation(false)
        setImageSize(MinZoom)
    }

    const handleCacherContainerBoutonsClick = (e) => {
        setCacherContainerBouton(!CacherContainerBouton)
        if (CacherContainerBouton === false) {
            setBoutonCacherContainer(<i className="fa-solid fa-chevron-left"></i>)
        } else {
            setBoutonCacherContainer(<i className="fa-solid fa-xmark"></i>)
        }
    }

    const handleCacherNomsClick = (e) => {
        setCacherNoms(!CacherNoms)
    }

    const handleCacherNumsClick = (e) => {
        setCacherNums(!CacherNums)
    }

    const handleCacherMutationClick = (e) => {
        setCacherMutation(!CacherMutation)
    }

    const handleCacherCapitaineClick = (e) => {
        setCacherCapitaine(!CacherCapitaine)
    }

    const handleResetValidationClick = (e) => {
        const newValue = e.target.value
        if (newValue === 'oui') {
            const joueursData = Array.from({ length: NbTitulaires }, (_, index) => (
                {
                    id: index + 1,
                    nom: '',
                    prenom: 'Joueur ' + (index + 1),
                    photo: "/default/default-joueurs.png",
                    numeroMaillot: index + 1,
                    role: 'titulaire'
                }
            ));

            const nbTitulaires = parseInt(NbTitulaires)
            const joueursTitulairesData = joueursData;

            for (let index = 1; index <= NbRemplacants; index++) {
                joueursTitulairesData.push({
                    id: nbTitulaires + index,
                    nom: '',
                    prenom: 'Joueur ' + (nbTitulaires + index),
                    photo: "/default/default-joueurs.png",
                    numeroMaillot: nbTitulaires + index,
                    role: 'remplacant'
                });
            }

            let dirigeantsData = [];
            dirigeantsData.push({
                id: 1,
                nom: 'Principal',
                prenom: 'Entraineur',
                photo: "/default/default-dirigeants.png",
                role: "entraineur"
            });

            setInfosDirigeants(dirigeantsData)
            setInfosJoueurs(joueursTitulairesData)
            setIdCapitaine('')
        }

        setShowModal(false)
        setContentModal('')
    }

    async function handleReloadPageClick(e) {
        if (ReloadPage === true) {
            navigate('/matchs');
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////
    // COMPTEUR //
    //////////////

    let compteur = CompteurTitulaires;

    return (
        <form
            className={show === true ? 'composition-container rotation' + Rotation : 'composition-container general-displayNone'}
            onSubmit={handleSubmit}
        >
            {CacherElement === false && (
                <div className='filtre-container'>
                    {LabelFiltre.map((item, index) => (
                        <div className='filtre-children' key={index}>
                            <label>{item.nom}</label><br />
                            <select
                                id={item.tab}
                                onChange={(e) => handleFilterChange(e, index)}
                                value={
                                    item.nom === "Nb titulaires" ? NbTitulaires.toString() :
                                        item.nom === "Systèmes" ? Systeme :
                                            item.nom === "Remplaçants" ? NbRemplacants.toString() :
                                                item.nom === "Capitaine" ? IdCapitaine.toString() :
                                                    item.nom === "Dirigeants" ? NbDirigeants.toString() : ''
                                }
                            >
                                {item.tab !== 'dirigeants' &&
                                    < option >---</option>
                                }

                                {ValeursParLabel[item.tab] && ValeursParLabel[item.tab].map((value, valueIndex) => (
                                    <option id={value ? value.id : ''} key={valueIndex} value={value && item.tab === 'capitaine' ? value.id : value.nom}>
                                        {value ? value.nom : ''}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ))}
                </div>
            )}

            {Systeme !== '' && (
                <div className={PleinEcran === true ? 'terrain-container general-PleinEcran' : 'terrain-container'}>
                    <div className={'boutonFormulaire-container translate-' + CacherContainerBouton}>
                        {CacherElement === false && (
                            <div className="reinitialiser bg-reinitialiser" onClick={(e) => { handleResetClick(); }}>Réinitialiser</div>
                        )}

                        {CacherElement === false && (
                            <div className="annuler bg-annuler" onClick={(e) => { handleAnnulerClick(); }}>Annuler</div>
                        )}

                        {CacherElement === false && (
                            <button className="sauvegarder bg-principal" type='submit'>Sauvegarder</button>
                        )}

                        {CacherElement === true && (
                            <div className={CacherContainerBouton === false ? "bg-close cl-bold bg-" + CacherNoms : "bg-noir cl-bold bg-" + CacherNoms} onClick={(e) => { handleCacherContainerBoutonsClick(); }}>{BoutonCacherContainer}</div>
                        )}

                        {CacherElement === true && CacherModifierCompo === false && (
                            <div className="modifierCompo bg-modifierCompo cl-bold" onClick={(e) => { handleEditClick(); }}>Modifier compo</div>
                        )}

                        {CacherElement === true && (
                            <div className={"bg-noir cl-bold bg-" + CacherNoms} onClick={(e) => { handleCacherNomsClick(); }}>Nom</div>
                        )}

                        {CacherElement === true && (
                            <div className={"modifierCompo bg-modifierCompo cl-bold bg-" + CacherNums} onClick={(e) => { handleCacherNumsClick(); }}>10</div>
                        )}

                        {CacherElement === true && (
                            <div className={"mutation cl-bold bg-" + CacherMutation} onClick={(e) => { handleCacherMutationClick(); }}>
                                <div className={'radiusLeft bg-mutée bg-' + CacherMutation}>M</div>
                                <div className={'radiusRight bg-mutée hors période bg-' + CacherMutation}>MHP</div>
                            </div>
                        )}

                        {CacherElement === true && (
                            <div className={"bg-capitaine cl-bold bg-" + CacherCapitaine} onClick={(e) => { handleCacherCapitaineClick(); }}>CAP</div>
                        )}

                        {CacherElement === true && (
                            <div id={'-'} className={ImageSize === MinZoom || ImageSize === '' ? "bg-selected" : "bg-zoom"} onClick={(e) => { handleZoomClick(e); }}>
                                <img id={'-'} src='/icones/dézoomer.png' alt='dézoomer' />
                            </div>
                        )}

                        {CacherElement === true && (
                            <div id={'+'} className={ImageSize === MaxZoom ? "bg-selected" : "bg-zoom"} onClick={(e) => { handleZoomClick(e); }}>
                                <img id={'+'} src='/icones/zoomer.png' alt='zoomer' />
                            </div>
                        )}

                        {CacherElement === true && (
                            <div id={Rotation} className={"rotationCompo bg-rotation rotation" + Rotation} onClick={(e) => { handleRotationClick(e); }}>
                                <img id={Rotation} src='/icones/rotation.png' alt='rotation' />
                            </div>
                        )}

                        {CacherElement === true && (
                            <div className="aggrandirCompo bg-aggrandir" onClick={(e) => { handlePleinEcranClick(); }}>
                                <img src='/icones/aggrandir.png' alt='aggrandir' />
                            </div>
                        )}
                    </div>

                    {LignesTerrain.map((item, index) => (
                        <div className={Systeme === '' ? 'general-displayNone' : 'ligneTerrain'} key={index}>
                            {Array.from({ length: parseInt(item, 10) }).map((_, playerIndex) => {
                                compteur = compteur - 1;
                                return (

                                    <div
                                        key={playerIndex}
                                        className='joueur-container'
                                    >

                                        <div className='joueur-children'>
                                            <div className='image-container'>
                                                <Draggable>
                                                    <img
                                                        id={InfosJoueurs[compteur].id}
                                                        src={InfosJoueurs[compteur].photo}
                                                        alt={InfosJoueurs[compteur].prenom}
                                                        onClick={CacherElement === false ? (e) => handleJoueurClick(e) : null}
                                                        style={{ width: ImageSize + 'vh', height: ImageSize + 'vh' }}
                                                    />
                                                </Draggable>
                                                {InfosJoueurs[compteur].mutation === ("mutée") && CacherMutation === false && (
                                                    <div className={'mutation cl-bold bg-' + InfosJoueurs[compteur].mutation}>M</div>
                                                )}
                                                {InfosJoueurs[compteur].mutation === ("mutée hors période") && CacherMutation === false && (
                                                    <div className={'mutation cl-bold bg-' + InfosJoueurs[compteur].mutation}>MHP</div>
                                                )}
                                                {CacherElement === false && (
                                                    <i
                                                        className={InfosJoueurs[compteur].idLicencie ? 'fa-solid fa-circle-xmark supprimerJoueur' : 'general-displayNone'}
                                                        style={{ color: "#eb0505" }}
                                                        onClick={(e) => { e.stopPropagation(); handleDeleteJoueurClick(e); }}
                                                        id={InfosJoueurs[compteur].id}
                                                    ></i>
                                                )}
                                            </div>
                                            {CacherNoms === false &&
                                                <div
                                                    id={InfosJoueurs[compteur].id}
                                                    className='nom bg-noir'
                                                    onClick={CacherElement === false ? (e) => handleJoueurClick(e) : null}
                                                >
                                                    {InfosJoueurs[compteur].prenom + ' ' + InfosJoueurs[compteur].nom.charAt() + '.'}
                                                </div>
                                            }
                                            <div className={CacherElement === false ? 'numeroMaillot-container' : 'numeroMaillot-container deux'}>
                                                {CacherNums === false &&

                                                    <input
                                                        pattern='[0-9]*'
                                                        type='number'
                                                        id={InfosJoueurs[compteur].id}
                                                        value={isNaN(InfosJoueurs[compteur].numeroMaillot) ? "" : InfosJoueurs[compteur].numeroMaillot}
                                                        onClick={(e) => e.stopPropagation()}
                                                        onChange={(e) => handleNumeroMaillotChange(e)}
                                                        name={'numeroMaillot' + InfosJoueurs[compteur].id}
                                                        required
                                                        maxLength="2"
                                                        className={parseInt(InfosJoueurs.filter(item => parseInt(item.numeroMaillot) === parseInt(InfosJoueurs[compteur].numeroMaillot)).length) > 1 ? 'cl-bold bg-principal bg-doublonNumeroMaillot' : 'cl-bold bg-principal'}
                                                        readOnly={CacherElement === false ? false : true}
                                                    />
                                                }
                                                {CacherElement === false && (
                                                    <i className="fa-solid fa-pen-to-square modifier bg-edit"></i>
                                                )}
                                                {CacherCapitaine === false && (
                                                    <i
                                                        className={InfosJoueurs[compteur].idLicencie && parseInt(InfosJoueurs[compteur].idLicencie) === parseInt(IdCapitaine) ? 'fa-solid fa-c fa-beat capitaine bg-capitaine' : "general-displayNone"}
                                                    ></i>
                                                )}
                                            </div>
                                            <input
                                                type='hidden'
                                                readOnly
                                                defaultValue={InfosJoueurs[compteur].idLicencie}
                                                onClick={(e) => e.stopPropagation()}
                                                name={'idLicencieJoueur' + InfosJoueurs[compteur].id}
                                            />
                                        </div>

                                    </div>
                                );
                            })}
                        </div>
                    ))}

                    {CacherMutation === false && (
                        <div className='legendeMutee-container'>
                            <div className='legendeMutee-children'>
                                <div className='mutation cl-bold bg-mutée'>M</div>
                                <div>: Joueur muté</div>
                            </div>
                            <div className='legendeMutee-children'>
                                <div className='mutation cl-bold bg-mutée hors période'>MHP</div>
                                <div>: Joueur muté hors-période</div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {Systeme !== '' && CacherBanc === false && (
                <div className='banc-container'>
                    <div className='remplacants-container bg-remplacants'>
                        {InfosJoueurs
                            .filter(item => item.role === 'remplacant')
                            .map((item, index) => (
                                <div key={index}
                                    className='joueur-container'
                                    onClick={CacherElement === false ? (e) => handleJoueurClick(e) : null}
                                >
                                    <div className='image-container'>
                                        <img id={item.id} src={item.photo} alt={item.prenom} />
                                        {CacherElement === false && (
                                            <i
                                                className={item.idLicencie ? 'fa-solid fa-circle-xmark supprimerJoueur' : 'general-displayNone'}
                                                style={{ color: "#eb0505" }}
                                                onClick={(e) => { e.stopPropagation(); handleDeleteJoueurClick(e); }}
                                                id={item.id}
                                            ></i>
                                        )}
                                        {item.mutation === ("mutée") && (
                                            <div className={'mutation cl-bold bg-' + item.mutation}>M</div>
                                        )}
                                        {item.mutation === ("mutée hors période") && (
                                            <div className={'mutation cl-bold bg-' + item.mutation}>MHP</div>
                                        )}
                                    </div>
                                    <div
                                        onClick={CacherElement === false ? (e) => handleJoueurClick(e) : null}
                                        id={item.id} className='nom bg-noir'>{item.prenom + ' ' + item.nom.charAt() + '.'}
                                    </div>
                                    <div className={CacherElement === false ? 'numeroMaillot-container' : 'numeroMaillot-container deux'}>
                                        <input
                                            pattern='[0-9]*'
                                            type='number'
                                            id={item.id}
                                            defaultValue={item.numeroMaillot}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(e) => handleNumeroMaillotChange(e)}
                                            name={'numeroMaillot' + item.id}
                                            readOnly={CacherElement === false ? false : true}
                                            required
                                            maxLength="2"
                                            className={parseInt(InfosJoueurs.filter(itemFilter => parseInt(itemFilter.numeroMaillot) === parseInt(item.numeroMaillot)).length) > 1 ? 'cl-bold bg-principal bg-doublonNumeroMaillot' : 'cl-bold bg-principal'}
                                        />
                                        {CacherElement === false && (
                                            <i className="fa-solid fa-pen-to-square modifier bg-edit"></i>
                                        )}
                                    </div>
                                    <input
                                        type='hidden'
                                        readOnly
                                        defaultValue={item.idLicencie}
                                        onClick={(e) => e.stopPropagation()}
                                        name={'idLicencieJoueur' + item.id}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    <div className='dirigeants-container bg-entraineurs'>
                        {InfosDirigeants
                            .map((item, index) => (
                                <div
                                    key={index}
                                    className='joueur-container'
                                    onClick={CacherElement === false ? (e) => handleDirigeantsClick(e) : null}
                                >
                                    <div className='image-container'>
                                        <img id={item.id} src={item.photo} alt={item.prenom} />
                                        {CacherElement === false && (
                                            <i
                                                className={item.idLicencie && item.idLicencie !== "" ? 'fa-solid fa-circle-xmark supprimerJoueur' : 'general-displayNone'}
                                                style={{ color: "#eb0505" }}
                                                onClick={(e) => { e.stopPropagation(); handleDeleteDirigeantClick(e); }}
                                                id={item.id}
                                            ></i>
                                        )}
                                    </div>
                                    <div id={item.id} className='nom bg-noir'>{item.prenom + ' ' + item.nom.charAt() + '.'}</div>
                                    <div id={item.id}>{item.id === 1 ? 'Entraineur' : 'Adjoint'}</div>
                                    <input
                                        type='hidden'
                                        readOnly
                                        defaultValue={item.idLicencie}
                                        onClick={(e) => e.stopPropagation()}
                                        name={'idLicencieDirigeant' + item.id}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            )}

            <Modal
                showModal={ShowModal}
                contentModal={ContentModal}
                handleCloseModal={() => {
                    setShowModal(false);
                    setContentModal("");
                    handleReloadPageClick();
                }}
            />
        </form >
    );
}

export default Composition;
