function TrierParPrenom(a, b) {
    const prenomA = a.prenom.toLowerCase();
    const prenomB = b.prenom.toLowerCase();

    if (prenomA < prenomB) {
        return -1;
    }
    if (prenomA > prenomB) {
        return 1;
    }
    return 0;
}

export default TrierParPrenom;
