import axios from "axios";

export async function updateGroupesEntrainementsJoueurs(
    id_groupe_entrainement,
    joueurs
) {
    try {
        const { data } = await axios.get(`
            ${process.env.REACT_APP_API_URL}updateGroupesEntrainementsJoueurs/?id_groupe_entrainement=${id_groupe_entrainement}&joueurs=${joueurs}
        `)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `Update groupe entrainement (joueur) [${id_groupe_entrainement}] : [SUCCESS]`
            return msg;
        }
    } catch (error) {
        console.log(error);
    }
}
