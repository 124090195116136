import React, { useContext, useEffect, useState, useCallback } from 'react';
import { SettingsContext } from '../settings/RecuperationSettings';
import BoutonAction from '../boutons/boutonAction';
import { getJoueurs } from '../../api/joueurs/getJoueurs';
import Tuile from '../tuiles/tuiles';
import { useNavigate, useLocation } from 'react-router-dom';
import UcFirst from '../fonctions/UcFirst'
import BoutonRecherche from '../boutons/boutonRecherche';
import globalStorage from '../fonctions/GlobalStorage';

function AfficherJoueurs() {

    ///////////////
    // VARIABLES //
    ///////////////
    
    // GLOBAL STORAGE
    const {gsSetTitreHeaderSecondaire} = globalStorage();

    const [Joueurs, setJoueurs] = useState([]);
    const [NbElements, setNbElements] = useState([]);
    const navigate = useNavigate(); // Utilisez useNavigate

    // RECUPERER PARAMETRE DE RECHERCHE
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const UrlRecherche = searchParams.get('recherche') || ''; // Obtenez la valeur de recherche de la chaîne de requête

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////
    // SETTINGS //
    //////////////

    const { settings } = useContext(SettingsContext);
    let WidthTuiles = '';

    if (Array.isArray(settings)) {
        const settingsWidthTuiles = settings.find((setting) => setting.name === 'WIDTH_TUILES');
        WidthTuiles = settingsWidthTuiles?.value || '';
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    const fetch = useCallback(async () => {
        let joueurs = await getJoueurs();
        joueurs = joueurs.filter(item => item.statut !== 'supprimé');
        setJoueurs(joueurs);
    }, []);

    useEffect(() => {
        fetch();

        // EDIT TITRE HEADER
        gsSetTitreHeaderSecondaire('Joueurs')
    }, [fetch,gsSetTitreHeaderSecondaire]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////
    // FILTRES //
    /////////////

    const [Recherche, setRecherche] = useState(UrlRecherche);

    const handleRechercheChange = (e) => {
        setRecherche(e.target.value);
        if (e.target.value === '') {
            navigate('')
        }
    };

    const filtered = Joueurs.filter((result) => {
        const nomComplet =
            result.nom.toLowerCase() +
            '' +
            result.prenom.toLowerCase() +
            '' +
            result.date_de_naissance.toLowerCase() +
            '' +
            result.email.toLowerCase() +
            '' +
            result.telephone_joueur.toLowerCase() +
            '' +
            result.telephone_mere.toLowerCase() +
            '' +
            result.telephone_pere.toLowerCase() +
            '' +
            result.categorie.toLowerCase() +
            '' +
            result.postes.toLowerCase() +
            '' +
            result.pied_fort.toLowerCase() +
            '' +
            result.numero_licence.toLowerCase() +
            '' +
            result.mutation.toLowerCase() +
            '' +
            result.licence.toLowerCase();
        return (
            nomComplet.includes(Recherche.toLowerCase())
        );
    });

    // Déplacez setNbElements ici
    useEffect(() => {
        setNbElements(filtered.length);
    }, [filtered]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////
    // HANDLE CLICK //
    //////////////////

    const handleBoutonClick = (e) => {
        navigate("ajouter");
    };

    async function handleOnClick(e) {
        const id = e.target.id;

        if (Recherche !== '') {
            navigate(`?recherche=${Recherche}`);
        }

        navigate(id);
    }

    return (
        <div className='joueurs-children'>
            <div>
                <div className='boutons-container'>
                    <BoutonAction content={"Ajouter un joueur"} addClass={'bg-principal'} handleBoutonClick={handleBoutonClick} />
                </div>

                <div className='boutonRecherche-container'>
                    <BoutonRecherche recherche={Recherche} handleRechercheChange={handleRechercheChange} />
                </div>

                <div className='infosTuile-container'>{'Nb de joueur : ' + NbElements}</div>
                <div className='tuile-container ' style={{ maxWidth: 'calc((20px * ' + NbElements + ') + (' + WidthTuiles + ' * ' + NbElements + '))' }}>
                    {Joueurs.length > 0 ? (
                        filtered.map((item, index) => {
                            return (
                                <div className='key' key={index}>
                                    <Tuile
                                        var_handleOnClick={handleOnClick}
                                        var_id={item.id}
                                        var_image={item.photo}
                                        var_infos={[
                                            UcFirst(item.prenom) + ' ' + item.nom.toUpperCase(),
                                            UcFirst(item.categorie.toLowerCase()),
                                            item.postes,
                                            UcFirst(item.pied_fort),
                                            ' ' + item.numero_licence && item.numero_licence === '' ? "Numéro licence : N/A" : 'Numéro licence : ' + UcFirst(item.numero_licence),
                                            UcFirst(item.licence),
                                            UcFirst(item.mutation)
                                        ]}
                                    />
                                </div>
                            )
                        })
                    ) : (
                        <div className='general-aucunElement'>Aucun élément trouvé.</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AfficherJoueurs;
