import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormInputText, FormInputSubmit, FormInputTel, FormBouton, FormSelect } from '../formulaires/elementsFormulaires';
import { getStades } from '../../api/stades/getStades';
import { getClubs } from '../../api/clubs/getClubs';
import { getSurfacesDeJeu } from '../../api/surfacesDeJeu/getSurfacesDeJeu';
import { insertStades } from '../../api/stades/insertStades'
import { updateStades } from '../../api/stades/updateStades';
import { updateRestaurationSuppressionStade } from '../../api/stades/updateRestaurationSuppressionStade'
import UcFirst from '../fonctions/UcFirst';
import Modal from '../Modal'
import { useNavigate } from 'react-router-dom';
import ModalOuiNon from '../modal/ModalOuiNon';
import globalStorage from '../fonctions/GlobalStorage';


function InformationsStades() {

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////
    // RECUPERATION ID ELEMENT //
    /////////////////////////////
    const location = useLocation();
    const currentURL = location.pathname;
    const parts = currentURL.split("/"); // Divise la chaîne en segments en utilisant le séparateur "/"
    const valeur = parts.pop(); // Récupère le dernier élément du tableau
    const IdElement = valeur.replace(/%20/g, " "); // Remplace les %20 en espace

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // VARIABLES //
    ///////////////

    // GLOBAL STORAGE
    const { gsSetTitreHeaderSecondaire } = globalStorage();

    const navigate = useNavigate();
    const [ListeStades, setListeStades] = useState([])
    const [ListeClubs, setListeClubs] = useState([])
    const [ClubSelected, setClubSelected] = useState([])
    const [ListeSurfacesDeJeu, setListeSurfacesDeJeu] = useState([])
    const [ShowModal, setShowModal] = useState(false);
    const [ContentModal, setContentModal] = useState(false);
    const [InfosModal, setInfosModal] = useState(false);
    const [ReloadPage, setReloadPage] = useState(false);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    // GET MATCH
    const fetch = useCallback(async () => {

        // GET SURFACE DE JEU
        const listeSurfacesDeJeu = await getSurfacesDeJeu();
        const nomsSurfacesDeJeu = listeSurfacesDeJeu.map(item => item.nom);
        setListeSurfacesDeJeu(nomsSurfacesDeJeu)

        // GET CLUBS
        const listeClubs = await getClubs();
        const nomsClubs = listeClubs.map(item => item.nom);
        setListeClubs(nomsClubs)

        // GET STADES
        const listeStades = await getStades();
        setListeStades(listeStades)
        if (IdElement !== 'ajouter') {
            const selectedStade = listeStades.filter(item => parseInt(item.id) === parseInt(IdElement) && item.statut !== 'supprimé')
            if (selectedStade.length === 0) {
                navigate('/notFound');
            } else {
                setListeStades(selectedStade)
                setClubSelected(listeClubs.filter(item => item.id === selectedStade[0].id_club))
            }

            // EDIT TITRE HEADER
            gsSetTitreHeaderSecondaire(selectedStade[0].nom)
        } else {
            // EDIT TITRE HEADER
            gsSetTitreHeaderSecondaire('Ajouter stade')
        }
    }, [IdElement, gsSetTitreHeaderSecondaire, navigate]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////
    // HANDLE //
    ////////////

    async function handleSubmit(e) {
        // VARIABLES
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const club = formData.get('club').toUpperCase();
        const nom = formData.get('nom').toUpperCase();
        const adresse = UcFirst(formData.get('adresse').toLowerCase());
        const ville = formData.get('ville');
        const code_postal = formData.get('code postal').toLowerCase();
        const surface_de_jeu = formData.get('surface de jeu');
        const statut = 'en ligne'

        // VERIFICATION
        const verifStadeExistant = ListeStades.filter(item => item.nom.toLowerCase() === nom.toLowerCase() && item.ville.toLowerCase() === ville.toLowerCase())
        let verifIdStadeExistant;
        if (verifStadeExistant.length > 0 && IdElement !== "ajouter") {
            verifIdStadeExistant = ListeStades.find(item => item.nom.toLowerCase() === nom.toLowerCase() && item.ville.toLowerCase() === ville.toLowerCase()).id
        }

        if (verifStadeExistant.length > 0 && parseInt(verifIdStadeExistant) !== parseInt(IdElement)) {
            const verifStadeStatut = ListeStades.find(item => item.nom.toLowerCase() === nom.toLowerCase() && item.ville.toLowerCase() === ville.toLowerCase()).statut
            verifIdStadeExistant = ListeStades.find(item => item.nom.toLowerCase() === nom.toLowerCase() && item.ville.toLowerCase() === ville.toLowerCase()).id
            if (verifStadeStatut === 'supprimé') {
                // AFFICHER MODAL
                const affichageModal = 'Voulez-vous restaurer le stade suivant ?';
                setShowModal(true)
                setContentModal(
                    <ModalOuiNon handleClick={handleOuiNonClick} affichage={affichageModal} infos={nom} var_name={'restauration'} var_id={verifIdStadeExistant} />
                )
            } else {
                // AFFICHER MODAL
                const affichageModal = 'Ce stade existe déjà !';
                setShowModal(true)
                setContentModal(
                    <div>
                        {affichageModal}
                    </div>
                )
                setInfosModal(
                    <div>{club}<br/>{nom}</div>
                )
                setReloadPage(false)
            }
        } else {
            // AFFICHER MODAL
            setShowModal(true)

            let affichageModal = "";
            let insertSql = [];
            let updateSql = [];

            // GET ID CLUBS
            let idClub = await getClubs();
            idClub = idClub.find(item => item.nom.toLowerCase() === club.toLowerCase()).id;

            if (IdElement === 'ajouter') {
                // INSERTION DONNEES EN BASE
                insertSql = await insertStades(idClub, nom, adresse, ville, code_postal, surface_de_jeu, statut);
                affichageModal = 'Le stade suivant a bien été ajouté';
            } else {
                // MISE A JOUR DONNEES EN BASE
                updateSql = await updateStades(IdElement, idClub, nom, adresse, ville, code_postal, surface_de_jeu, statut);
                affichageModal = 'Le stade suivant a bien été mis à jour';
            }

            if (insertSql.includes("SUCCESS") || updateSql.includes("SUCCESS")) {
                setReloadPage(true)
                setContentModal(
                    <div>{affichageModal}</div>
                )
                setInfosModal(
                    <div>{club}<br/>{nom}</div>
                )
            }
        }
    };

    async function handleButtonClick(e) {
        const value = e.target.name

        const form = document.querySelector('.formulaire-container');
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const nom = formData.get('nom').toUpperCase();


        if (value === 'supprimer') {
            // AFFICHER MODAL
            const affichageModal = 'Voulez-vous vraiment supprimer le stade suivant ?';

            setShowModal(true)
            setContentModal(
                <ModalOuiNon handleClick={handleOuiNonClick} affichage={affichageModal} infos={nom} var_name={'suppression'} />
            )
            setReloadPage(false)
        }
    }

    async function handleOuiNonClick(e) {
        const name = e.target.name
        const value = e.target.value
        const id = e.target.id

        const form = document.querySelector('.formulaire-container');
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const club = formData.get('club').toUpperCase();
        const nom = formData.get('nom').toUpperCase();
        const adresse = UcFirst(formData.get('adresse').toLowerCase());
        const ville = formData.get('ville');
        const code_postal = formData.get('code postal').toLowerCase();
        const surface_de_jeu = formData.get('surface de jeu');

        if (name === 'suppression') {
            if (value === 'oui') {
                const statut = 'supprimé'

                // GET ID CLUBS
                let idClub = await getClubs();
                idClub = idClub.find(item => item.nom.toLowerCase() === club.toLowerCase()).id;

                // MISE A JOUR DONNEES EN BASE
                const updateSql = await updateStades(IdElement, idClub, nom, adresse, ville, code_postal, surface_de_jeu, statut);
                const affichageModal = 'Le stade suivant a bien été supprimé';

                if (updateSql.includes("SUCCESS")) {
                    // AFFICHER MODAL
                    setContentModal(
                        <div>
                            <div>{affichageModal}</div><br />
                            <div className='cl-principalBold'>{nom}</div>
                        </div>
                    )
                }
                setReloadPage(true)
            } else {
                setShowModal(false)
            }
        } else if (name === 'restauration') {
            if (value === 'oui') {
                const updateSql = await updateRestaurationSuppressionStade(id, 'en ligne');
                if (updateSql.includes("SUCCESS")) {
                    const affichageModal = 'Le stade suivant a bien été restauré !';
                    // AFFICHER MODAL
                    setContentModal(
                        <div>
                            <div>{affichageModal}</div><br />
                            <div className='cl-principalBold'>{nom}</div>
                        </div>
                    )
                    setReloadPage(true)
                }
            } else {
                setShowModal(false)
            }
        }

    }

    async function handleReloadPageClick(e) {
        if (ReloadPage === true) {
            navigate('/Stades');
        }
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className='informationsStades-children'>
            <form
                className='formulaire-container'
                onSubmit={(e) => { handleSubmit(e) }}
            >
                <FormSelect
                    var_readOnly={false}
                    var_nom={'club'}
                    var_class={"bg-main"}
                    var_array={ListeClubs}
                    var_required={true}
                    var_value={ListeStades.length > 0 && IdElement !== 'ajouter' ? ClubSelected[0].nom.toUpperCase() : ''}
                />
                <FormInputText
                    var_readOnly={false}
                    var_nom={'nom'}
                    var_class={"bg-main"}
                    var_required={true}
                    var_value={ListeStades.length > 0 && IdElement !== 'ajouter' ? ListeStades[0].nom : ''}
                />
                <FormInputText
                    var_readOnly={false}
                    var_nom={'adresse'}
                    var_class={"bg-main"}
                    var_required={true}
                    var_value={ListeStades.length > 0 && IdElement !== 'ajouter' ? ListeStades[0].adresse : ''}
                />
                <FormInputText
                    var_readOnly={false}
                    var_nom={'ville'}
                    var_class={"bg-main"}
                    var_required={true}
                    var_value={ListeStades.length > 0 && IdElement !== 'ajouter' ? ListeStades[0].ville : ''}
                />
                <FormInputTel
                    var_readOnly={false}
                    var_nom={'code postal'}
                    var_required={true}
                    var_class={"bg-main"}
                    var_value={ListeStades.length > 0 && IdElement !== 'ajouter' ? ListeStades[0].code_postal : ''}
                />
                <FormSelect
                    var_readOnly={false}
                    var_nom={'surface de jeu'}
                    var_class={"bg-main"}
                    var_array={ListeSurfacesDeJeu}
                    var_required={true}
                    var_value={ListeStades.length > 0 && IdElement !== 'ajouter' ? ListeStades[0].surface_de_jeu : ''}
                />
                <FormInputSubmit
                    var_nom={'submit'}
                    var_value={IdElement === 'ajouter' ? 'ajouter' : 'sauvegarder'}
                    var_class={'bg-principal'}
                />
                {IdElement !== 'ajouter' && (
                    <FormBouton
                        var_nom={'supprimer'}
                        var_value={'supprimer'}
                        var_class={'bg-supprimer'}
                        var_handleButtonClick={handleButtonClick}
                    />
                )}
            </form>

            <Modal
                showModal={ShowModal}
                contentModal={ContentModal}
                handleCloseModal={() => {
                    setShowModal(false);
                    setContentModal("");
                    handleReloadPageClick();
                }}
                infosModal={InfosModal}
            />
        </div>
    )
}

export default InformationsStades;
