import axios from "axios";

export async function updateRestaurationSuppressionEntrainement(
    id_entrainement,
    statut
) {
    try {
        const { data } = await axios.get(`
            ${process.env.REACT_APP_API_URL}updateRestaurationSuppressionEntrainement/?id_entrainement=${id_entrainement}&statut=${statut}
        `)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `SUCCESS`
            return msg;
        }
    } catch (error) {
        console.log(error);
    }
}
