import React from 'react';
import { FormInputSubmit } from '../formulaires/elementsFormulaires';

function ModalPartager({ handleSubmit, affichage, infos, var_name, var_id }) {
    ///////////////
    // VARIABLES //
    ///////////////

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////
    // FONCTION CLICK //
    ////////////////////

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <form
            className='modalPartager-container'
            onSubmit={(e) => {handleSubmit(e) }}
        >
            {affichage}
            <div className='bouton-container'>
                <FormInputSubmit
                    var_nom={'submit'}
                    var_value={'PARTAGER'}
                    var_class={'bg-principal'}
                />
            </div>
        </form>
    );
}

export default ModalPartager;
