import axios from "axios";

export async function updatePresenceEntrainements(
    id_entrainement,
    presences,
    absences,
    absences_justifies,
    retards,
    retards_justifies,
) {
    try {
        const { data } = await axios.get(`
            ${process.env.REACT_APP_API_URL}updatePresenceEntrainements/?id_entrainement=${id_entrainement}&presences=${presences}&absences=${absences}&retards=${retards}&absences_justifies=${absences_justifies}&retards_justifies=${retards_justifies}
        `)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `Update entrainement (présence) [${id_entrainement}] : [SUCCESS]`
            return msg;
        }
    } catch (error) {
        console.log(error);
    }
}
