import React from 'react';
import BoutonsRetourArriere from './BoutonsRetourArriere';
import ListeJoueurs from '../matchs/ListeJoueurs'

function BoutonsJoueurs({ handleBoutonsStatsClick, idClub, idMatch, typeBouton, nomBouton, chrono, scoreHote, scoreVisiteur, typeClub, acteurs, nomActeurs, miTemps}) {
    return (
        <div>
            <BoutonsRetourArriere handleBoutonsStatsClick={handleBoutonsStatsClick} />
            <div className='boutonsJoueurs-container'>
                <ListeJoueurs handleBoutonsStatsClick={handleBoutonsStatsClick} idClub={idClub} idMatch={idMatch} typeBouton={typeBouton} nomBouton={nomBouton} chrono={chrono} scoreHote={scoreHote} scoreVisiteur={scoreVisiteur} typeClub={typeClub} acteurs={acteurs} nomActeurs={nomActeurs} miTemps={miTemps} />
            </div>
        </div>
    );
}

export default BoutonsJoueurs;
