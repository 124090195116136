import React, { useContext, useState, useEffect, useCallback } from 'react';
import { SettingsContext } from '../settings/RecuperationSettings';
import BoutonAction from '../boutons/boutonAction';
import { getMatchs } from '../../api/matchs/getMatchs';
import { getClubs } from '../../api/clubs/getClubs';
import { getCategories } from '../../api/categories/getCategories';
import { getStades } from '../../api/stades/getStades';
import { getCompetitions } from '../../api/competitions/getCompetitions';
import { getCompositions } from '../../api/compositions/getCompositions';
import { getMatchsInfos } from '../../api/matchs/getMatchsInfos';
import { getJoueurs } from '../../api/joueurs/getJoueurs';
import TuileMatch from '../tuiles/tuilesMatchs';
import { useNavigate, useLocation } from 'react-router-dom';
import UcFirst from '../fonctions/UcFirst'
import BoutonRecherche from '../boutons/boutonRecherche';
import getCurrentTime from '../fonctions/getCurrentTime';
import { insertMatchsInfos } from '../../api/matchs/insertMatchsInfos';
import Modal from '../Modal'
import ModalPartager from '../modal/ModalPartager';
import ShareMessage from '../fonctions/ShareMessage';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { FormTextarea } from '../formulaires/elementsFormulaires'
import BoutonItineraire from '../boutons/boutonItineraire';
import globalStorage from '../fonctions/GlobalStorage';

function AfficherMatchs() {
    ///////////////
    // VARIABLES //
    ///////////////

    // GLOBAL STORAGE
    const {gsSetTitreHeaderSecondaire} = globalStorage();

    const [ListeMatchs, setListeMatchs] = useState([]);
    const [ListeMatchsInfos, setListeMatchsInfos] = useState([]);
    const [ListeClubs, setListeClubs] = useState([]);
    const [ListeCategories, setListeCategories] = useState([]);
    const [ListeStades, setListeStades] = useState([]);
    const [ListeCompetitions, setListeCompetitions] = useState([]);
    const [ListeCompositions, setListeCompositions] = useState([]);
    const [ListeJoueurs, setListeJoueurs] = useState([]);
    const [SelectedIdMatch, setSelectedIdMatch] = useState([]);
    const navigate = useNavigate(); // Utilisez useNavigate
    const [NbElements, setNbElements] = useState([]);

    const [ShowModal, setShowModal] = useState(false);
    const [ContentModal, setContentModal] = useState(false);
    const [ReloadPage, setReloadPage] = useState(false);

    const { settings } = useContext(SettingsContext);

    // RECUPERER PARAMETRE DE RECHERCHE
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const UrlRecherche = searchParams.get('recherche') || ''; // Obtenez la valeur de recherche de la chaîne de requête

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////
    // SETTINGS //
    //////////////

    let WidthTuilesMatchs = '';

    if (Array.isArray(settings)) {
        const settingsWidthTuilesMatchs = settings.find((setting) => setting.name === 'WIDTH_TUILES_MATCHS');
        WidthTuilesMatchs = settingsWidthTuilesMatchs?.value || '';
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    const fetch = useCallback(async () => {
        // GET MATCHS
        let listeMatchs = await getMatchs();
        listeMatchs = listeMatchs.filter(itemFilter => itemFilter.statut !== 'supprimé')
        setListeMatchs(listeMatchs);

        // GET CLUBS
        let listeClubs = await getClubs();
        listeClubs = listeClubs.filter(itemFilter => itemFilter.statut !== 'supprimé')
        setListeClubs(listeClubs);

        // GET CATEGORIES
        let listeCategories = await getCategories();
        listeCategories = listeCategories.filter(itemFilter => itemFilter.statut !== 'supprimé')
        setListeCategories(listeCategories);

        // GET STADES
        let listeStades = await getStades();
        listeStades = listeStades.filter(itemFilter => itemFilter.statut !== 'supprimé')
        setListeStades(listeStades);

        // GET COMPETITIONS
        let listeCompetitions = await getCompetitions();
        listeCompetitions = listeCompetitions.filter(itemFilter => itemFilter.statut !== 'supprimé')
        setListeCompetitions(listeCompetitions);

        // GET MATCHS INFOS
        let listeMatchsInfos = await getMatchsInfos();
        listeMatchsInfos = listeMatchsInfos.filter(itemFilter => itemFilter.statut !== 'supprimé')
        setListeMatchsInfos(listeMatchsInfos);

        // GET COMPOSITION
        let listeCompositions = await getCompositions();
        listeCompositions = listeCompositions.filter(itemFilter => itemFilter.statut !== 'supprimé')
        setListeCompositions(listeCompositions);

        // GET JOUEURS
        let listeJoueurs = await getJoueurs();
        listeJoueurs = listeJoueurs.filter(itemFilter => itemFilter.statut !== 'supprimé')
        setListeJoueurs(listeJoueurs);
    }, []);

    useEffect(() => {
        fetch();

        // EDIT TITRE HEADER
        gsSetTitreHeaderSecondaire('Matchs')
    }, [fetch,gsSetTitreHeaderSecondaire]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////
    // HANDLE CLICK //
    //////////////////

    async function handleReloadPageClick(e) {
        if (ReloadPage === true) {
            navigate('composition/' + SelectedIdMatch);
        }
    }

    const handleBoutonClick = (e) => {
        navigate("ajouter")
    };

    async function handleAfficherOptionCacheClick(e) {
        const id = e.target.id
        setSelectedIdMatch(id)
    }

    async function handlePartagerClick(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const message = formData.get('message');
        ShareMessage(message)

        setShowModal(false)
        setContentModal("");
    }

    async function handleOptionCacheClick(e) {
        e.stopPropagation();
        const id = e.target.id
        const value = e.target.value
        setSelectedIdMatch(id)
        let url
        // VARIABLES INFOS MATCHS
        const infosMatch = ListeMatchs.filter(itemFilter => itemFilter.id === parseInt(id))
        const categorie = ListeCategories.find(itemFilter => itemFilter.id === parseInt(infosMatch[0].id_categorie)).nom
        const competition = ListeCompetitions.find(itemFilter => itemFilter.id === parseInt(infosMatch[0].id_competition)).nom
        const adversaire = ListeClubs.find(itemFilter => itemFilter.id === parseInt(infosMatch[0].id_adversaire)).nom
        const stade = ListeStades.find(itemFilter => itemFilter.id === parseInt(infosMatch[0].id_stade)).nom
        const adresse = ListeStades.find(itemFilter => itemFilter.id === parseInt(infosMatch[0].id_stade)).adresse
        const ville = ListeStades.find(itemFilter => itemFilter.id === parseInt(infosMatch[0].id_stade)).code_postal + ' - ' + ListeStades.find(itemFilter => itemFilter.id === parseInt(infosMatch[0].id_stade)).ville
        const surface = ListeStades.find(itemFilter => itemFilter.id === parseInt(infosMatch[0].id_stade)).surface_de_jeu

        if (value === 'démarrer match') {
            navigate(`?recherche=${Recherche}`);
            const presenceComposition = ListeCompositions.filter(itemFilter => parseInt(itemFilter.id_match) === parseInt(id))
            if (presenceComposition.length > 0) {
                const currentTime = getCurrentTime();
                const insertBddMatchsInfos = await insertMatchsInfos(id, currentTime, "1ère mi-temps", "en cours", "terminer mi-temps");
                console.log(insertBddMatchsInfos)
                url = id
                navigate(url)
            } else {
                setShowModal(true);
                setContentModal(
                    <div>
                        Une compo. doit être présente pour démarrer le match
                    </div>
                )
                setReloadPage(true)
            }
        } else if (value === 'voir match') {
            navigate(`?recherche=${Recherche}`);
            url = id
            navigate(url)
        } else if (value === 'convocation') {
            const presenceComposition = ListeCompositions.filter(itemFilter => parseInt(itemFilter.id_match) === parseInt(id))
            if (presenceComposition.length > 0) {
                // GET DATE
                let date = infosMatch[0].date
                date = parseISO(date);
                date = format(date, "eeee dd MMMM yyyy", { locale: fr });
                date = UcFirst(date)
                // GET JOUEURS
                const listeIdsJoueurs = ListeCompositions.find(itemFilter => itemFilter.id_match === parseInt(id)).joueurs
                const idsArray = listeIdsJoueurs.split(',').map(id => parseInt(id)); // Convertir la chaîne en tableau d'entiers
                const listeJoueurs = ListeJoueurs
                    .filter(joueur => idsArray.includes(joueur.id))
                    .map(joueur => ({ nom: joueur.nom, prenom: joueur.prenom }));

                // GET CURRENT TIME
                const currentTime = getCurrentTime();
                let hourCurrentTime = currentTime.split(':')
                hourCurrentTime = parseInt(currentTime[0]);

                // CONVOCATION
                let message = "🔵⚪ CONVOCATION 🔵⚪\n\n▪️Infos match :\n  🆚 " + adversaire + "\n  🏆 " + competition + "\n  💬 " + infosMatch[0].description + "\n  📈 " + categorie + "\n  📌 " + infosMatch[0].emplacement + "\n\n▪️Horaires :\n  📅 " + date + "\n  🕒 " + infosMatch[0].heure_match + " (coup d'envoi)\n  ⏰ " + infosMatch[0].heure_rdv + " (rdv)\n  🏠 " + infosMatch[0].lieu_rdv + " (lieu rdv)\n\n▪️Infos stade :\n  🏟 " + stade + "\n  📍 " + adresse + "\n  🏙️ " + ville + "\n  🥅 " + surface + "\n\n▪️Liste des convoqués :";

                // Ajouter la liste des joueurs
                listeJoueurs.forEach((joueur, index) => {
                    message += `\n  ${index + 1} - ${joueur.prenom} ${joueur.nom.toUpperCase()}`;
                });
                message += "\n\n⚠ Merci de m'informer si vous n'êtes pas disponible. ⚠";

                if (hourCurrentTime >= 18) {
                    message += "\n\nBonne soirée.";
                } else {
                    message += "\n\nBonne journée.";
                }

                setShowModal(true);
                setContentModal(
                    < div className='modalPartager-container' >
                        <ModalPartager
                            handleSubmit={handlePartagerClick}
                            affichage={
                                <FormTextarea
                                    var_readOnly={false}
                                    var_nom={'message'}
                                    var_class={"bg-main"}
                                    var_required={false}
                                    var_value={message}
                                />
                            }
                            var_name={'message'}
                        />
                    </div >
                )
                setReloadPage(false)
            } else {
                setShowModal(true);
                setContentModal(
                    <div>
                        Une compo. doit être présente avant d'envoyer la convocation
                    </div>
                )
                setReloadPage(true)
            }
        } else if (value === 'informations stade') {
            const url = "https://www.google.com/maps?q=" + encodeURIComponent(stade + ", " + adresse + ", " + ville)
            let message = "▪️Infos stade :\n  🏟 " + stade + "\n  📍 " + adresse + "\n  🏙️ " + ville + "\n  🥅 " + surface + "\n  🗺️ " + url;

            setShowModal(true);
            setContentModal(
                < div className='modalPartager-container' >
                    <ModalPartager
                        handleSubmit={handlePartagerClick}
                        affichage={
                            <FormTextarea
                                var_readOnly={false}
                                var_nom={'message'}
                                var_class={"bg-main"}
                                var_required={false}
                                var_value={message}
                            />
                        }
                        var_name={'message'}
                    />
                    <br/>
                    <BoutonItineraire var_nom="🗺️ Ouvrir dans Google Maps" var_class="bg-secondaire" var_url={url}/>
                </div >
            )
            setReloadPage(false)
        } else {
            navigate(`?recherche=${Recherche}`);
            url = value.split(' ')[0] + '/' + id
            navigate(url)
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////
    // FILTRES //
    /////////////

    const [Recherche, setRecherche] = useState(UrlRecherche);

    const handleRechercheChange = (e) => {
        setRecherche(e.target.value);
        if (e.target.value === '') {
            navigate('')
        }
    };

    const filtered = ListeMatchs.filter((result) => {
        const nomComplet =
            result.nom.toLowerCase() +
            '' +
            result.description.toLowerCase() +
            '' +
            result.emplacement.toLowerCase() +
            '' +
            result.date.toLowerCase() +
            '' +
            result.heure_match.toLowerCase() +
            '' +
            result.lieu_rdv.toLowerCase() +
            '' +
            result.score.toLowerCase() +
            '' +
            result.resultat.toLowerCase();

        const listeCategories = ListeCategories.find(itemFind => parseInt(itemFind.id) === parseInt(result.id_categorie));
        const nomCategorie = listeCategories ? listeCategories.nom.toLowerCase() : '';

        const listeClubs = ListeClubs.find(itemFind => parseInt(itemFind.id) === parseInt(result.id_adversaire));
        const nomClub = listeClubs ? listeClubs.nom.toLowerCase() : '';

        const listeClubPrincipal = ListeClubs.find(itemFind => parseInt(itemFind.id) === parseInt(1));
        const nomClubPrincipal = listeClubPrincipal ? listeClubPrincipal.nom.toLowerCase() : '';

        const listeStades = ListeStades.find(itemFind => parseInt(itemFind.id) === parseInt(result.id_stade));
        const nomStade = listeStades ? listeStades.nom.toLowerCase() : '';
        const adresse = listeStades ? listeStades.adresse.toLowerCase() : '';
        const ville = listeStades ? listeStades.ville.toLowerCase() : '';
        const codePostal = listeStades ? listeStades.code_postal.toLowerCase() : '';
        const surfaceDeJeu = listeStades ? listeStades.surface_de_jeu.toLowerCase() : '';

        const listeCompetitions = ListeCompetitions.find(itemFind => parseInt(itemFind.id) === parseInt(result.id_competition));
        const nomCompetiton = listeCompetitions ? listeCompetitions.nom.toLowerCase() : '';

        const date = new Date(result.date);
        const optionsMonth = { month: 'long' };
        const formattedDateMonth = date.toLocaleDateString('fr-FR', optionsMonth).toLowerCase();
        return (
            nomComplet.includes(Recherche.toLowerCase()) ||
            nomCategorie.includes(Recherche.toLowerCase()) ||
            nomClub.includes(Recherche.toLowerCase()) ||
            nomCompetiton.includes(Recherche.toLowerCase()) ||
            nomStade.includes(Recherche.toLowerCase()) ||
            formattedDateMonth.includes(Recherche.toLowerCase()) ||
            nomClubPrincipal.includes(Recherche.toLowerCase()) ||
            adresse.includes(Recherche.toLowerCase()) ||
            ville.includes(Recherche.toLowerCase()) ||
            codePostal.includes(Recherche.toLowerCase()) ||
            surfaceDeJeu.includes(Recherche.toLowerCase())
        );
    });

    // Déplacez setNbElements ici
    useEffect(() => {
        setNbElements(filtered.length);
    }, [filtered]);

    return (
        <div className='matchs-children'>
            <div>
                <div className='boutons-container'>
                    <BoutonAction content={"Ajouter un match"} addClass={'bg-principal'} handleBoutonClick={handleBoutonClick} />
                </div>

                <div className='boutonRecherche-container'>
                    <BoutonRecherche recherche={Recherche} handleRechercheChange={handleRechercheChange} />
                </div>

                <div className='infosTuile-container'>{'Nb de matchs : ' + NbElements}</div>
                <div className='tuile-container ' style={{ maxWidth: 'calc((20px * ' + NbElements + ') + (' + WidthTuilesMatchs + ' * ' + NbElements + '))' }}>
                    {ListeMatchs.length > 0 ? (
                        filtered.map((item, index) => {

                            // RECUPERATION HORRAIRE MATCH
                            const date = new Date(item.date);
                            const optionsDay = { day: 'numeric' };
                            const optionsMonth = { month: 'short' };
                            const formattedDateDay = date.toLocaleDateString('fr-FR', optionsDay).toUpperCase();
                            const formattedDateMonth = date.toLocaleDateString('fr-FR', optionsMonth).toUpperCase();

                            // RECUPERATION INFOS CLUBS
                            let infosClubHote = []
                            let infosClubVisiteur = []
                            const idClubPrincipal = 1
                            const idClubAdverse = item.id_adversaire

                            if (item.emplacement === 'Domicile') {
                                infosClubHote = ListeClubs.filter(itemFilter => parseInt(itemFilter.id) === parseInt(idClubPrincipal))
                                infosClubVisiteur = ListeClubs.filter(itemFilter => parseInt(itemFilter.id) === parseInt(idClubAdverse))
                            } else {
                                infosClubHote = ListeClubs.filter(itemFilter => parseInt(itemFilter.id) === parseInt(idClubAdverse))
                                infosClubVisiteur = ListeClubs.filter(itemFilter => parseInt(itemFilter.id) === parseInt(idClubPrincipal))
                            }

                            infosClubHote = infosClubHote.length > 0 ? infosClubHote[0] : ''
                            infosClubVisiteur = infosClubVisiteur.length > 0 ? infosClubVisiteur[0] : ''

                            // RECUPERATION CATEGORIE MATCH
                            let categorieMatch = (ListeCategories.filter(itemFilter => parseInt(itemFilter.id) === parseInt(item.id_categorie)))
                            categorieMatch = categorieMatch.length > 0 ? categorieMatch[0].nom : ''

                            // RECUPERATION INFOS STADE
                            const infosStades = (ListeStades.filter(itemFilter => parseInt(itemFilter.id) === parseInt(item.id_stade)))
                            const stade = infosStades.length > 0 ? infosStades[0].nom : ''
                            const code_postal = infosStades.length > 0 ? infosStades[0].code_postal : ''
                            const ville = infosStades.length > 0 ? infosStades[0].ville : ''
                            const surfaceDeJeu = infosStades.length > 0 ? infosStades[0].surface_de_jeu : ''

                            // RECUPERATION COMPETITION
                            let infosCompetition = (ListeCompetitions.filter(itemFilter => parseInt(itemFilter.id) === parseInt(item.id_competition)))
                            infosCompetition = infosCompetition.length > 0 ? infosCompetition[0].nom : ''

                            // RECUPERATION HEURE DEMARRAGE MATCH
                            const heureDemarrageMatch = ListeMatchsInfos.length > 0 ? ListeMatchsInfos.filter(itemFind => itemFind.id_match === item.id) : ''
                            let statutMatch

                            if (heureDemarrageMatch.length > 0) {
                                statutMatch = "voir match"
                            } else {
                                statutMatch = "démarrer match"
                            }

                            return (
                                <div className='key' key={index}>
                                    <TuileMatch
                                        var_id={item.id} var_image={''}
                                        var_description={UcFirst(item.description.toLowerCase())}
                                        var_date={[
                                            formattedDateDay + ' ' + formattedDateMonth,
                                        ]}
                                        var_heure={[
                                            item.heure_match
                                        ]}
                                        var_infos={[
                                            'Compétition : ' + infosCompetition,
                                            'Catégorie : ' + UcFirst(categorieMatch.toLowerCase()),
                                            'RDV : ' + item.heure_rdv + ' / ' + item.lieu_rdv,
                                            ' -',
                                            UcFirst(stade.toUpperCase()),
                                            code_postal + ' - ' + ville.toUpperCase(),
                                            UcFirst(surfaceDeJeu.toLowerCase()),
                                        ]}
                                        var_clubHote={infosClubHote}
                                        var_clubVisiteur={infosClubVisiteur}
                                        var_score={item.score}
                                        var_resultat={item.resultat.toLowerCase()}
                                        var_handleOnClick={handleAfficherOptionCacheClick}
                                        var_arrayOptions={['informations match', 'composition', 'convocation', 'informations stade', statutMatch]}
                                        var_handleOptionClick={handleOptionCacheClick}
                                        var_selectedIdMatch={SelectedIdMatch}
                                    />
                                </div>
                            )
                        })
                    ) : (
                        <div className='general-aucunElement'>Aucun élément trouvé.</div>
                    )}
                </div>
            </div>


            <Modal
                showModal={ShowModal}
                contentModal={ContentModal}
                handleCloseModal={() => {
                    setShowModal(false);
                    setContentModal("");
                    handleReloadPageClick();
                }}
            />
        </div>
    );
}

export default AfficherMatchs;
