function CalculateMinutesDiff(startHour, endHour, miTemps, dureeMiTemps, nbMiTemps, nbProlongation, dureeProlongation) {
    const [startHourStr, startMinuteStr] = startHour.split(':');
    const [endHourStr, endMinuteStr] = endHour.split(':');

    const startHourNum = parseInt(startHourStr);
    const startMinuteNum = parseInt(startMinuteStr);
    const endHourNum = parseInt(endHourStr);
    const endMinuteNum = parseInt(endMinuteStr);

    const startTotalMinutes = startHourNum * 60 + startMinuteNum;
    const endTotalMinutes = endHourNum * 60 + endMinuteNum;

    let diff = endTotalMinutes - startTotalMinutes;
    let tempsReglementaire = diff;
    let tempsReglementaireMax = dureeMiTemps;
    let tempsAdditionnel = tempsReglementaire - tempsReglementaireMax;

    if (miTemps.includes("mi-temps")) {
        tempsReglementaire = (dureeMiTemps * (miTemps.charAt(0) - 1)) + diff;
        tempsReglementaireMax = dureeMiTemps * miTemps.charAt(0);
    } else if (miTemps.includes("prolongation")) {
        tempsReglementaire = (dureeMiTemps * nbMiTemps) + (dureeProlongation * (miTemps.charAt(0) - 1)) + diff;
        tempsReglementaireMax = (dureeMiTemps * nbMiTemps) + (dureeProlongation * miTemps.charAt(0));
    }

    if (tempsReglementaire <= tempsReglementaireMax) {
        return tempsReglementaire + "'";
    } else {
        tempsAdditionnel = tempsReglementaire - tempsReglementaireMax;
        tempsReglementaire = tempsReglementaireMax;
        return tempsReglementaire + "+" + tempsAdditionnel + "'";
    }
}

export default CalculateMinutesDiff;
