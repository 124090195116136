import axios from "axios";

export async function updateCompositions(
    id_composition,
    id_systeme,
    id_capitaine,
    id_match,
    nom,
    joueurs,
    numero_maillots,
    titulaires,
    remplacants,
    dirigeants
) {
    try {
        const { data } = await axios.get(`
            ${process.env.REACT_APP_API_URL}updateCompositions/?id_composition=${id_composition}&id_systeme=${id_systeme}&id_capitaine=${id_capitaine}&id_match=${id_match}&nom=${nom}&joueurs=${joueurs}&numero_maillots=${numero_maillots}&titulaires=${titulaires}&remplacants=${remplacants}&dirigeants=${dirigeants}
        `)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `Update compositions [${nom}] in database : SUCCES !`
            return msg;
        }
    } catch (error) {
        console.log(error);
    }
}
