import React from 'react';

const Tuile = ({ var_id, var_infos, var_image, var_handleOnClick, var_class }) => {
    return (
        <div id={var_id} className={'tuile-children mosaique bs-principal bg-secondaire '+var_class} onClick={var_handleOnClick}>
            <div id={var_id} className='infos-container'>
                <div id={var_id} className='image'>
                    <img id={var_id} src={var_image} alt="Logo" />
                </div>
                <div id={var_id} className='infos'>
                    {var_infos.map((item, index) => (
                        item === "Pas de licence" ? (
                            <div id={var_id} key={index} style={{ display: 'flex' }}>
                                <div id={var_id} style={{ marginRight: '3px' }}>Licence : </div>
                                <div id={var_id} key={index} className="cl-licencePasDeLicence">{item}</div>
                            </div>
                        ) : item === "Valide" ? (
                            <div id={var_id} key={index} style={{ display: 'flex' }}>
                                <div id={var_id} style={{ marginRight: '3px' }}>Licence : </div>
                                <div id={var_id} key={index} className="cl-licenceValide">{item}</div>
                            </div>
                        ) : item === "Incomplète" ? (
                            <div id={var_id} key={index} style={{ display: 'flex' }}>
                                <div id={var_id} style={{ marginRight: '3px' }}>Licence : </div>
                                <div id={var_id} key={index} className="cl-licenceIncomplete">{item}</div>
                            </div>
                        ) : item === "En attente" ? (
                            <div id={var_id} key={index} style={{ display: 'flex' }}>
                                <div id={var_id} style={{ marginRight: '3px' }}>Licence : </div>
                                <div id={var_id} key={index} className="cl-licenceEnAttente">{item}</div>
                            </div>
                        ) : item === "Mutée" ? (
                            <div id={var_id} key={index} style={{ display: 'flex' }}>
                                <div id={var_id} style={{ marginRight: '3px' }}>Mutation : </div>
                                <div id={var_id} key={index} className="cl-mutée">{item}</div>
                            </div>
                        ) : item === "Mutée hors période" ? (
                            <div id={var_id} key={index} style={{ display: 'flex' }}>
                                <div id={var_id} style={{ marginRight: '3px' }}>Mutation : </div>
                                <div id={var_id} key={index} className="cl-mutéeHorsPériode">{item}</div>
                            </div>
                        ) : item === "Non mutée" ? (
                            <div id={var_id} key={index} style={{ display: 'flex' }}>
                                <div id={var_id} style={{ marginRight: '3px' }}>Mutation : </div>
                                <div id={var_id} key={index} className="">{item}</div>
                            </div>
                        ) : (
                            <div id={var_id} key={index} className="">
                                {item}
                            </div>
                        )
                    ))}
                </div>
                <div id={var_id} className='icone'><i className="fas fa-chevron-right"></i></div>
            </div>
        </div>
    );
};

export default Tuile;
