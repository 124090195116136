import React, { } from 'react';
import AfficherStades from '../components/stades/AfficherStades';
import AfficherInformationsStades from '../components/stades/AfficherInformationsStades';

const Stades = () => {

    return (
        <div className='stades-container'>
            <AfficherStades />
        </div>
    );
};

const AjouterStades = () => {
    return (
        <div className='informationsStades-container'>
            <AfficherInformationsStades />
        </div>
    );
};

const InformationsStades = () => {
    return (
        <div className='informationsStades-container'>
            <AfficherInformationsStades />
        </div>
    );
};

export { Stades, AjouterStades, InformationsStades };