import React from 'react';
import AfficherClubs from '../components/clubs/AfficherClubs';
import AfficherInformationsClubs from '../components/clubs/AfficherInformationsClubs';

const Clubs = () => {
    return (
        <div className='clubs-container'>
            <AfficherClubs />
        </div>
    );
};

const AjouterClubs = () => {
    return (
        <div className='informationsClubs-container'>
            <AfficherInformationsClubs />
        </div>
    );
};

const InformationsClubs = () => {
    return (
        <div className='informationsClubs-container'>
            <AfficherInformationsClubs />
        </div>
    );
};

export { Clubs, AjouterClubs, InformationsClubs };