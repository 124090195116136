import axios from "axios";

export async function updateDirigeants(
    id_joueur,
    nom,
    prenom,
    photo,
    date_de_naissance,
    email,
    telephone,
    categories,
    roles,
    numero_licence,
    licence,
    statut
) {
    try {
        const { data } = await axios.get(`
            ${process.env.REACT_APP_API_URL}updateDirigeants/?id_joueur=${id_joueur}&nom=${nom}&prenom=${prenom}&photo=${photo}&date_de_naissance=${date_de_naissance}&email=${email}&telephone=${telephone}&categories=${categories}&roles=${roles}&numero_licence=${numero_licence}&licence=${licence}&statut=${statut}
        `)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `SUCCESS`
            return msg;
        }
    } catch (error) {
        console.log(error);
    }
}
