import React, { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from '../pages/Home';
import Test from '../pages/Test';
import { Entrainements, InformationsEntrainements, GroupesEntrainements, InformationsGroupesEntrainements, PresencesEntrainements } from '../pages/Entrainements';
import { Matchs, AjouterMatchs, InformationsMatchs, CompositionMatch, MatchEnCours } from '../pages/Matchs';
// import { Matchs, InformationsMatchs, CompositionMatch, MatchEnCours } from '../pages/Matchs';
import { Clubs, AjouterClubs, InformationsClubs } from '../pages/Clubs';
import NotFound from '../pages/NotFound';
import { Joueurs, AjouterJoueurs, InformationsJoueurs } from '../pages/Joueurs';
import { Stades, InformationsStades } from '../pages/Stades';
import { Dirigeants, InformationsDirigeants } from '../pages/Dirigeants';
import { Compositions, InformationsCompositions } from '../pages/Compositions';
import globalStorage from '../components/fonctions/GlobalStorage';

const Main = () => {
  const location = useLocation();
  const { gsDisplayHeaderSecondaire, gsToggleDisplayHeaderSecondaire, gsDisplayHeaderPrincipal, gsToggleDisplayHeaderPrincipal } = globalStorage();

  useEffect(() => {
    if (location.pathname === '/') {
      // ACTIVATION HEADER PRINCIPAL
      if (gsDisplayHeaderSecondaire === true) {
        gsToggleDisplayHeaderSecondaire();
        if (gsDisplayHeaderPrincipal === false) {
          gsToggleDisplayHeaderPrincipal();
        }
      }
    } else {
      // ACTIVATION HEADER SECONDAIRE
      if (gsDisplayHeaderSecondaire === false) {
        gsToggleDisplayHeaderSecondaire();
        if (gsDisplayHeaderPrincipal === true) {
          gsToggleDisplayHeaderPrincipal();
        }
      }
    }
  }, [location.pathname, gsDisplayHeaderSecondaire, gsDisplayHeaderPrincipal, gsToggleDisplayHeaderPrincipal, gsToggleDisplayHeaderSecondaire]);

  return (
    <div className="main-container cl-site bg-main site-children">
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <Home />
            }
          />
          <Route
            path="/Test"
            element={
              <Test />
            }
          />
          <Route
            path="/Clubs"
            element={
              <Clubs />
            }
          />
          <Route
            path="/Clubs/:id"
            element={
              <InformationsClubs />
            }
          />
          <Route
            path="/Clubs/ajouter"
            element={
              <AjouterClubs />
            }
          />
          <Route
            path="/Matchs"
            element={
              <Matchs />
            }
          />
          <Route
            path="/Matchs/ajouter"
            element={
              <AjouterMatchs />
            }
          />
          <Route
            path="/Matchs/informations/:id"
            element={
              <InformationsMatchs />
            }
          />
          <Route
            path="/Matchs/composition/:id"
            element={
              <CompositionMatch />
            }
          />
          <Route
            path="/Matchs/:id"
            element={
              <MatchEnCours />
            }
          />
          <Route
            path="/Entrainements"
            element={
              <Entrainements />
            }
          />
          <Route
            path="/Entrainements/informations/:id"
            element={
              <InformationsEntrainements />
            }
          />
          <Route
            path="/Entrainements/ajouter"
            element={
              <InformationsEntrainements />
            }
          />
          <Route
            path="/Entrainements/groupes"
            element={
              <GroupesEntrainements />
            }
          />
          <Route
            path="/Entrainements/groupes/:id"
            element={
              <InformationsGroupesEntrainements />
            }
          />
          <Route
            path="/Entrainements/présence/:id"
            element={
              <PresencesEntrainements />
            }
          />
          <Route
            path="/Joueurs"
            element={
              <Joueurs />
            }
          />
          <Route
            path="/Joueurs/:id"
            element={
              <InformationsJoueurs />
            }
          />
          <Route
            path="/Joueurs/ajouter"
            element={
              <AjouterJoueurs />
            }
          />
          <Route
            path="/stades"
            element={
              <Stades />
            }
          />
          <Route
            path="/stades/:id"
            element={
              <InformationsStades />
            }
          />
          <Route
            path="/dirigeants"
            element={
              <Dirigeants />
            }
          />
          <Route
            path="/dirigeants/:id"
            element={
              <InformationsDirigeants />
            }
          />
          <Route
            path="/compositions"
            element={
              <Compositions />
            }
          />
          <Route
            path="/compositions/:id"
            element={
              <InformationsCompositions />
            }
          />
          <Route
            path="/*"
            element={
              <NotFound />
            }
          />
        </Routes>
      </AnimatePresence>
    </div>
  );
};

export default Main;
