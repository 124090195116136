import { create } from 'zustand';

const globalStorage = create((set) => ({
  // FOOTER
  gsDisplayFooter: true,
  gsToggleDisplayFooter: () => set((state) => ({ gsDisplayFooter: !state.gsDisplayFooter })),

  // HEADER
  gsDisplayHeader: true,
  gsToggleDisplayHeader: () => set((state) => ({ gsDisplayHeader: !state.gsDisplayHeader })),

  // HEADER PRINCIPAL
  gsDisplayHeaderPrincipal: true,
  gsToggleDisplayHeaderPrincipal: () => set((state) => ({ gsDisplayHeaderPrincipal: !state.gsDisplayHeaderPrincipal })),

  // HEADER SECONDAIRE
  gsDisplayHeaderSecondaire: false,
  gsToggleDisplayHeaderSecondaire: () => set((state) => ({ gsDisplayHeaderSecondaire: !state.gsDisplayHeaderSecondaire })),
  
  // TITRE HEADER SECONDAIRE
  gsTitreHeaderSecondaire: "Nom par défaut",
  gsSetTitreHeaderSecondaire: (titre) => set({ gsTitreHeaderSecondaire: titre }),
}));

export default globalStorage;
