import React, { useContext, useEffect, useState, useCallback } from 'react';
import { SettingsContext } from '../settings/RecuperationSettings';
import BoutonAction from '../boutons/boutonAction';
import { getStades } from '../../api/stades/getStades';
import { getClubs } from '../../api/clubs/getClubs';
import Tuile from '../tuiles/tuiles';
import { useNavigate, useLocation } from 'react-router-dom';
import UcFirst from '../fonctions/UcFirst'
import BoutonRecherche from '../boutons/boutonRecherche';
import globalStorage from '../fonctions/GlobalStorage';

function AfficherStades() {

    ///////////////
    // VARIABLES //
    ///////////////
    
    // GLOBAL STORAGE
    const {gsSetTitreHeaderSecondaire} = globalStorage();

    const [Stades, setStades] = useState([]);
    const [Clubs, setClubs] = useState([]);
    const [NbElements, setNbElements] = useState([]);
    const navigate = useNavigate(); // Utilisez useNavigate

    // RECUPERER PARAMETRE DE RECHERCHE
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const UrlRecherche = searchParams.get('recherche') || ''; // Obtenez la valeur de recherche de la chaîne de requête

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////
    // SETTINGS //
    //////////////

    const { settings } = useContext(SettingsContext);
    let WidthTuiles = '';

    if (Array.isArray(settings)) {
        const settingsWidthTuiles = settings.find((setting) => setting.name === 'WIDTH_TUILES');
        WidthTuiles = settingsWidthTuiles?.value || '';
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    const fetch = useCallback(async () => {
        // GET STADES
        let stades = await getStades();
        stades = stades.filter(item => item.statut !== 'supprimé')
        setStades(stades);

        // GET CLUBS
        let clubs = await getClubs();
        clubs = clubs.filter(item => item.statut !== 'supprimé')
        setClubs(clubs);
    }, []);

    useEffect(() => {
        fetch();

        // EDIT TITRE HEADER
        gsSetTitreHeaderSecondaire('Stades')
    }, [fetch,gsSetTitreHeaderSecondaire]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////
    // HANDLE CLICK //
    //////////////////

    const handleBoutonClick = (e) => {
        navigate("ajouter")
    };

    async function handleOnClick(e) {
        const id = e.target.id;

        if (Recherche !== '') {
            navigate(`?recherche=${Recherche}`);
        }

        navigate(id);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////
    // FILTRES //
    /////////////

    const [Recherche, setRecherche] = useState(UrlRecherche);

    const handleRechercheChange = (e) => {
        setRecherche(e.target.value);
        if (e.target.value === '') {
            navigate('')
        }
    };

    const filtered = Stades.filter((result) => {
        const nomComplet =
            result.nom.toLowerCase() +
            '' +
            result.adresse.toLowerCase() +
            '' +
            result.code_postal.toLowerCase() +
            '' +
            result.ville.toLowerCase() +
            '' +
            result.surface_de_jeu.toLowerCase();

        const club = Clubs.find(itemFind => parseInt(itemFind.id) === parseInt(result.id_club));
        const nomClub = club ? club.nom.toLowerCase() : '';
        return (
            nomComplet.includes(Recherche.toLowerCase()) ||
            nomClub.includes(Recherche.toLowerCase())
        );
    });

    // Déplacez setNbElements ici
    useEffect(() => {
        setNbElements(filtered.length);
    }, [filtered]);

    return (
        <div className='stades-children'>
            <div>
                <div className='boutons-container'>
                    <BoutonAction content={"Ajouter un stade"} addClass={'bg-principal'} handleBoutonClick={handleBoutonClick} />
                </div>

                <div className='boutonRecherche-container'>
                    <BoutonRecherche recherche={Recherche} handleRechercheChange={handleRechercheChange} />
                </div>

                <div className='infosTuile-container'>{'Nb de stades : ' + NbElements}</div>
                <div className='tuile-container ' style={{ maxWidth: 'calc((20px * ' + NbElements + ') + (' + WidthTuiles + ' * ' + NbElements + '))' }}>
                    {Stades.length > 0 ? (
                        filtered.map((item, index) => {
                            let nomClub = "";
                            let photoClub = "";
                            const clubMatch = Clubs.find(itemFind => parseInt(itemFind.id) === parseInt(item.id_club));
                            if (clubMatch) {
                                nomClub = clubMatch.nom;
                                photoClub = clubMatch.photo;
                            }
                            return (
                                <div className='key' key={index}>
                                    <Tuile var_handleOnClick={handleOnClick} var_id={item.id} var_image={photoClub} var_infos={[nomClub.toUpperCase(), item.nom.toUpperCase(), item.code_postal + ' - ' + item.ville.toUpperCase(), UcFirst(item.surface_de_jeu.toLowerCase())]} />
                                </div>
                            )
                        })
                    ) : (
                        <div className='general-aucunElement'>Aucun élément trouvé.</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AfficherStades;
