import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
////////////////////////////////////////////////////////////////////////////////////////
import { getMatchsStats } from '../../api/matchs/getMatchsStats';
import { getMatchsBoutonsStats } from '../../api/matchs/getMatchsBoutonsStats'
import { getCompositions } from '../../api/compositions/getCompositions';
import { getJoueurs } from '../../api/joueurs/getJoueurs';
////////////////////////////////////////////////////////////////////////////////////////

function OngletResume({ idClubHote, dureeMiTemps, dureeProlongation }) {
    // RECUPERATION ID MATCH
    const location = useLocation();
    const currentURL = location.pathname;
    const parts = currentURL.split("/"); // Divise la chaîne en segments en utilisant le séparateur "/"
    const valeur = parts.pop(); // Récupère le dernier élément du tableau
    const idMatch = valeur.replace(/%20/g, " "); // Remplace les %20 en espace

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // VARIABLES
    const [ListeStatsMatchs, setListeStatsMatchs] = useState([]);
    const [MiTemps, setMiTemps] = useState([]);
    const [ListeJoueurs, setListeJoueurs] = useState([]);

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // FONCTIONS
    const fetchMatchsStatsById = useCallback(async () => {
        // GET BOUTONS STATS
        let listeBoutonsStats = await getMatchsBoutonsStats();
        listeBoutonsStats = listeBoutonsStats.filter(itemFilter => itemFilter.onglets === 'résumé')

        // GET STATS MATCHS
        let listeStatsMatchs = await getMatchsStats();
        listeStatsMatchs = listeStatsMatchs.filter(itemFilter => itemFilter.id_match === parseInt(idMatch))
        listeStatsMatchs = listeStatsMatchs.filter(itemFilter => listeBoutonsStats.some(button => button.nom === itemFilter.nom));
        setListeStatsMatchs(listeStatsMatchs);

        // LISTE COMPOSITIONS
        const listeJoueurs = await getJoueurs();

        // LISTE COMPOSITIONS
        const listeCompositions = await getCompositions();
        const listeIdJoueurs = listeCompositions.find(itemFind => itemFind.id_match === parseInt(idMatch)).joueurs.split(',')
        const joueursPromises = listeIdJoueurs.map(async (item) => {
            const joueur = listeJoueurs.filter(itemFilter => itemFilter.id === parseInt(item));
            return joueur; // Renvoie la promesse pour ce joueur
        });
        // Attendez toutes les promesses de joueurs pour résoudre
        const listeJoueursArray = await Promise.all(joueursPromises);
        // Concaténez tous les tableaux de joueurs en un seul tableau
        const listeJoueursFinal = listeJoueursArray.flat();
        setListeJoueurs(listeJoueursFinal);

        // MI-TEMPS
        const miTempsUnique = new Set();
        listeStatsMatchs.forEach(item => {
            miTempsUnique.add(item.mi_temps);
        });
        const miTempsSansDoublons = Array.from(miTempsUnique);

        setMiTemps(miTempsSansDoublons)
    }, [idMatch]);

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // USE EFFECT
    useEffect(() => {
        fetchMatchsStatsById();
    }, [fetchMatchsStatsById]);

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className='matchsResume-container'>
            {
                MiTemps.length > 0 ? (
                    MiTemps.map((item, index) => (
                        <div key={index} className='matchsResume-children'>
                            <div className='miTemps bg-titre'>{item} {item.includes("mi-temps") ? '- (' + dureeMiTemps + ' min)' : item.includes("prolongation") ? '- (' + dureeProlongation + ' min)' : ""}</div>
                            {ListeStatsMatchs
                                .filter(item2 => item2.mi_temps === item)
                                .map((item2, index2) => (
                                    <div key={index2} className={item2.id_club === idClubHote ? "clubHote" : "clubVisiteur"}>
                                        <div className='chrono'>{item2.chrono}</div>
                                        <div className='icone'>
                                            <img src={`/icones/${item2.nom === 'buts' || item2.nom === 'penalty réussis' ? 'buts2' : item2.nom}.png`} alt={item2.nom} />
                                        </div>
                                        <div className={item2.score === null ? 'general-displayNone' : 'score'}>{`(${item2.score})`}</div>
                                        <div className='acteurPrimaire'>
                                            {
                                                item2.id_club === 1 ?
                                                    ListeJoueurs.find(item => item.id === parseInt(item2.acteur_primaire))?.prenom + ' ' + ListeJoueurs.find(item => item.id === parseInt(item2.acteur_primaire))?.nom.charAt(0) + '.'
                                                    :
                                                    item2.acteur_primaire
                                            }
                                        </div>
                                        <div className={'acteurSecondaire ' + item2.acteur_secondaire}>
                                            {
                                                item2.id_club === 1 && item2.acteur_secondaire !== null ?
                                                    '(' + ListeJoueurs.find(item => item.id === parseInt(item2.acteur_secondaire))?.prenom + ' ' + ListeJoueurs.find(item => item.id === parseInt(item2.acteur_secondaire))?.nom.charAt(0) + '.)'
                                                    :
                                                    item2.acteur_secondaire !== null ?
                                                        '(' + item2.acteur_secondaire + ')'
                                                        :
                                                        ''
                                            }
                                            {
                                                item2.nom.includes("penalty") ? '('+item2.nom+')' : ''
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                ) : (
                    <div style={{ textAlign: 'center' }}>Aucun élément trouvé.</div>
                )
            }
        </div>
    );
}

export default OngletResume;
