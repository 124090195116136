import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormInputText, FormInputSubmit, FormInputPhoto, FormBouton } from '../formulaires/elementsFormulaires';
import { postFiles } from '../../api/files/postFiles';
import { getClubs } from '../../api/clubs/getClubs';
import { insertClubs } from '../../api/clubs/insertClubs'
import { updateClubs } from '../../api/clubs/updateClubs';
import { updateRestaurationSuppressionClub } from '../../api/clubs/updateRestaurationSuppressionClub'
import Modal from '../Modal'
import { useNavigate } from 'react-router-dom';
import ModalOuiNon from '../modal/ModalOuiNon';
import { v4 as uuidv4 } from 'uuid'
import globalStorage from '../fonctions/GlobalStorage';

function InformationsClubs() {

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////
    // RECUPERATION ID ELEMENT //
    /////////////////////////////
    const location = useLocation();
    const currentURL = location.pathname;
    const parts = currentURL.split("/"); // Divise la chaîne en segments en utilisant le séparateur "/"
    const valeur = parts.pop(); // Récupère le dernier élément du tableau
    const IdElement = valeur.replace(/%20/g, " "); // Remplace les %20 en espace

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // VARIABLES //
    ///////////////

    // GLOBAL STORAGE
    const { gsSetTitreHeaderSecondaire } = globalStorage();

    const navigate = useNavigate();
    const [ListeClubs, setListeClubs] = useState([])
    const [ShowModal, setShowModal] = useState(false);
    const [ContentModal, setContentModal] = useState(false);
    const [InfosModal, setInfosModal] = useState(false);
    const [ReloadPage, setReloadPage] = useState(false);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    // GET MATCH
    const fetch = useCallback(async () => {
        // GET Clubs
        const listeClubs = await getClubs();
        setListeClubs(listeClubs)
        if (IdElement !== 'ajouter') {
            const selectedClub = listeClubs.filter(item => parseInt(item.id) === parseInt(IdElement) && item.statut !== 'supprimé')
            if (selectedClub.length === 0) {
                navigate('/notFound');
            } else {
                setListeClubs(selectedClub)
            }

            // EDIT TITRE HEADER
            gsSetTitreHeaderSecondaire(selectedClub[0].nom)
        } else {
            // EDIT TITRE HEADER
            gsSetTitreHeaderSecondaire('Ajouter club')
        }
    }, [IdElement, gsSetTitreHeaderSecondaire, navigate]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////
    // HANDLE //
    ////////////

    async function handleSubmit(e) {
        // VARIABLES
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const nom = formData.get('nom').toUpperCase();
        const acronyme = formData.get('acronyme').toUpperCase();
        let photo = formData.get('photo');
        const statut = 'en ligne'
        const extension = photo.name.split('.').pop()

        // VERIFICATION
        const verifClubExistant = ListeClubs.filter(item => item.nom.toLowerCase() === nom.toLowerCase())
        let verifIdClubExistant;
        if (verifClubExistant.length > 0 && IdElement !== "ajouter") {
            verifIdClubExistant = ListeClubs.find(item => item.nom.toLowerCase() === nom.toLowerCase()).id
        }

        if (verifClubExistant.length > 0 && parseInt(verifIdClubExistant) !== parseInt(IdElement)) {
            const verifClubstatut = ListeClubs.find(item => item.nom.toLowerCase() === nom.toLowerCase()).statut
            verifIdClubExistant = ListeClubs.find(item => item.nom.toLowerCase() === nom.toLowerCase()).id
            if (verifClubstatut === 'supprimé') {
                // AFFICHER MODAL
                const affichageModal = 'Voulez-vous restaurer le club suivant ?';
                setShowModal(true)
                setContentModal(
                    <ModalOuiNon handleClick={handleOuiNonClick} affichage={affichageModal} infos={nom} var_name={'restauration'} var_id={verifIdClubExistant} />
                )
            } else {
                // AFFICHER MODAL
                const affichageModal = 'Ce club existe déjà !';
                setShowModal(true)
                setContentModal(
                    <div>
                        {affichageModal}
                    </div>
                )
                setInfosModal(
                    <div>
                        {nom}
                    </div>
                )
                setReloadPage(false)
            }
        } else {
            const selectedFile = photo
            const destDefaultPictures = 'default'
            const destPictures = 'photos/clubs'
            let postFileStatus = '';

            if (selectedFile.name) {
                // AJOUT PHOTO DANS LE SERVEUR
                let nomPhoto
                if (IdElement === 'ajouter' || selectedFile.name.includes('default-')) {
                    nomPhoto = uuidv4();
                } else {
                    nomPhoto = ListeClubs[0].photo.split('/')
                    nomPhoto = nomPhoto[nomPhoto.length - 1];
                    nomPhoto = nomPhoto.split('.');
                    nomPhoto = nomPhoto[0];

                    if (nomPhoto.includes('default-')) {
                        nomPhoto = uuidv4();
                    }
                }

                const fileInput = document.getElementById('input-photo'); // Remplacez par l'élément input de type "file" de votre formulaire
                formData.append('photo', fileInput);
                const postFile = await postFiles(nomPhoto, destPictures, formData);
                postFileStatus = postFile
                photo = '/' + destPictures + '/' + nomPhoto + '.' + extension
            } else {
                postFileStatus = "N/A"
                if (IdElement === 'ajouter') {
                    photo = '/' + destDefaultPictures + '/default-clubs.png'
                } else {
                    photo = ListeClubs[0].photo
                }
            }

            // AFFICHER MODAL
            setShowModal(true)

            if (postFileStatus === 200 || postFileStatus === 'N/A') {
                let affichageModal = "";
                let insertSql = [];
                let updateSql = [];

                if (IdElement === 'ajouter') {
                    // INSERTION DONNEES EN BASE
                    insertSql = await insertClubs(nom, acronyme, photo, statut);
                    affichageModal = 'Le club suivant a bien été ajouté';
                } else {
                    // MISE A JOUR DONNEES EN BASE
                    updateSql = await updateClubs(IdElement, nom, acronyme, photo, statut);
                    affichageModal = 'Le club suivant a bien été mis à jour';
                }

                if (insertSql.includes("SUCCESS") || updateSql.includes("SUCCESS")) {
                    setReloadPage(true)
                    setContentModal(
                        <div>{affichageModal}</div>
                    )
                    setInfosModal(
                        <div>{nom}</div>
                    )
                }
            } else {
                setContentModal(
                    <div>
                        <div>
                            <i className="fa-solid fa-circle-xmark fa-beat" style={{ color: "#ff0000" }}></i> Une erreur s'est produite lors de l'envoie du formulaire
                            <div>{postFileStatus}</div>
                        </div>
                    </div>
                )
            }
        }
    };

    async function handleButtonClick(e) {
        const value = e.target.name

        const form = document.querySelector('.formulaire-container');
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const nom = formData.get('nom').toUpperCase();

        if (value === 'supprimer') {
            // AFFICHER MODAL
            const affichageModal = 'Voulez-vous vraiment supprimer le club suivant ?';

            setShowModal(true)
            setContentModal(
                <ModalOuiNon handleClick={handleOuiNonClick} affichage={affichageModal} infos={nom} var_name={'suppression'} />
            )
            setReloadPage(false)
        }
    }

    async function handleOuiNonClick(e) {
        const name = e.target.name
        const value = e.target.value
        const id = e.target.id

        const form = document.querySelector('.formulaire-container');
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const nom = formData.get('nom').toUpperCase();
        const acronyme = formData.get('acronyme').toUpperCase();
        let photo = ListeClubs[0].photo

        if (name === 'suppression') {
            if (value === 'oui') {
                const statut = 'supprimé'

                // MISE A JOUR DONNEES EN BASE
                const updateSql = await updateClubs(IdElement, nom, acronyme, photo, statut);
                const affichageModal = 'Le club suivant a bien été supprimé';

                if (updateSql.includes("SUCCESS")) {
                    // AFFICHER MODAL
                    setContentModal(
                        <div>
                            <div>{affichageModal}</div><br />
                            <div className='cl-principalBold'>{nom}</div>
                        </div>
                    )
                }
                setReloadPage(true)
            } else {
                setShowModal(false)
            }
        } else if (name === 'restauration') {
            if (value === 'oui') {
                const updateSql = await updateRestaurationSuppressionClub(id, 'en ligne');
                if (updateSql.includes("SUCCESS")) {
                    const affichageModal = 'Le club suivant a bien été restauré !';
                    // AFFICHER MODAL
                    setContentModal(
                        <div>
                            <div>{affichageModal}</div><br />
                            <div className='cl-principalBold'>{nom}</div>
                        </div>
                    )
                    setReloadPage(true)
                }
            } else {
                setShowModal(false)
            }
        }

    }

    async function handleReloadPageClick(e) {
        if (ReloadPage === true) {
            navigate('/Clubs');
        }
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className='informationsClubs-children'>
            <form
                className='formulaire-container'
                onSubmit={(e) => { handleSubmit(e) }}
            >
                <FormInputPhoto
                    var_readOnly={false}
                    var_nom={'photo'}
                    var_height='150px'
                    var_width='150px'
                    var_value={ListeClubs.length > 0 && IdElement !== 'ajouter' ? ListeClubs[0].photo : '/icones/ajouter image.png'}
                />
                <FormInputText
                    var_readOnly={false}
                    var_nom={'nom'}
                    var_class={"bg-main"}
                    var_required={true}
                    var_value={ListeClubs.length > 0 && IdElement !== 'ajouter' ? ListeClubs[0].nom : ''}
                />
                <FormInputText
                    var_readOnly={false}
                    var_nom={'acronyme'}
                    var_class={"bg-main"}
                    var_required={true}
                    var_value={ListeClubs.length > 0 && IdElement !== 'ajouter' ? ListeClubs[0].acronyme : ''}
                />
                <FormInputSubmit
                    var_nom={'submit'}
                    var_value={IdElement === 'ajouter' ? 'ajouter' : 'sauvegarder'}
                    var_class={'bg-principal'}
                />
                {IdElement !== 'ajouter' && IdElement !== '1' && (
                    <FormBouton
                        var_nom={'supprimer'}
                        var_value={'supprimer'}
                        var_class={'bg-supprimer'}
                        var_handleButtonClick={handleButtonClick}
                    />
                )}
            </form>

            <Modal
                showModal={ShowModal}
                contentModal={ContentModal}
                handleCloseModal={() => {
                    setShowModal(false);
                    setContentModal("");
                    handleReloadPageClick();
                }}
                infosModal={InfosModal}
            />
        </div>
    )
}

export default InformationsClubs;
