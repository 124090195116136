import axios from "axios";

export async function insertJoueurs(
    nom,
    prenom,
    photo,
    date_de_naissance,
    email,
    telephone_joueur,
    telephone_pere,
    telephone_mere,
    categorie,
    postes,
    pied_fort,
    numero_licence,
    licence,
    mutation,
    commentaires,
    statut
) {
    try {
        const { data } = await axios.get(`
            ${process.env.REACT_APP_API_URL}insertJoueurs/?nom=${nom}&prenom=${prenom}&photo=${photo}&date_de_naissance=${date_de_naissance}&email=${email}&telephone_joueur=${telephone_joueur}&telephone_pere=${telephone_pere}&telephone_mere=${telephone_mere}&categorie=${categorie}&postes=${postes}&pied_fort=${pied_fort}&numero_licence=${numero_licence}&licence=${licence}&mutation=${mutation}&commentaires=${commentaires}&statut=${statut}
        `)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `Insert joueur : [SUCCESS]`
            return msg;
        }
    } catch (error) {
        console.log(error);
    }
}
