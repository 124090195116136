import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormInputText, FormInputSubmit, FormInputDate, FormInputHeure, FormSelect, FormSelectWithId, FormSelectNumeric, FormBouton } from '../formulaires/elementsFormulaires';
import { getMatchs } from '../../api/matchs/getMatchs';
import { getCategories } from '../../api/categories/getCategories'
import { getCompetitions } from '../../api/competitions/getCompetitions'
import { getClubs } from '../../api/clubs/getClubs'
import { getStades } from '../../api/stades/getStades'
import { getMatchsDureeMitemps } from '../../api/matchs/getMatchsDureeMitemps'
import { insertMatchs } from '../../api/matchs/insertMatchs'
import { updateMatchs } from '../../api/matchs/updateMatchs';
import { updateRestaurationSuppressionMatch } from '../../api/matchs/updateRestaurationSuppressionMatch'
import Modal from '../Modal'
import { useNavigate } from 'react-router-dom';
import ModalOuiNon from '../modal/ModalOuiNon';
import globalStorage from '../fonctions/GlobalStorage';

function InformationsMatchs() {

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////
    // RECUPERATION ID ELEMENT //
    /////////////////////////////
    const location = useLocation();
    const currentURL = location.pathname;
    const parts = currentURL.split("/"); // Divise la chaîne en segments en utilisant le séparateur "/"
    const valeur = parts.pop(); // Récupère le dernier élément du tableau
    const IdElement = valeur.replace(/%20/g, " "); // Remplace les %20 en espace

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // VARIABLES //
    ///////////////

    // GLOBAL STORAGE
    const { gsSetTitreHeaderSecondaire } = globalStorage();

    const navigate = useNavigate();
    const [ListeMatchs, setListeMatchs] = useState([])
    const [ListeCategories, setListeCategories] = useState([])
    const [ListeEmplacements, setListeEmplacements] = useState([])
    const [ListeLieuxRdv, setListeLieuxRdv] = useState([])
    const [ListeStades, setListeStades] = useState([])
    const [ListeCompteurMiTemps, setListeCompteurMiTemps] = useState([])
    const [ListeDureeMiTemps, setListeDureeMiTemps] = useState([])
    const [ListeCompetitions, setListeCompetitions] = useState([])
    const [ListeClubs, setListeClubs] = useState([])

    const [ShowModal, setShowModal] = useState(false);
    const [ContentModal, setContentModal] = useState(false);
    const [InfosModal, setInfosModal] = useState(false);
    const [ReloadPage, setReloadPage] = useState(false);

    const [SelectedCategorie, setSelectedCategorie] = useState([])
    const [SelectedCompetition, setSelectedCompetition] = useState([])

    const [Emplacement, setEmplacement] = useState(false)
    const [IdAdversaire, setIdAdversaire] = useState(false)

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    // GET MATCH
    const fetch = useCallback(async () => {
        // GET Matchs
        const listeMatchs = await getMatchs();
        setListeMatchs(listeMatchs)

        // GET CATEGORIES
        const listeCategories = await getCategories();
        setListeCategories(listeCategories)

        // GET COMPETITION
        const listeCompetitions = await getCompetitions();
        setListeCompetitions(listeCompetitions)

        // GET CLUBS
        const listeClubs = await getClubs();
        setListeClubs(listeClubs)

        // GET STADES
        let listeStades = await getStades();
        listeStades = listeStades.filter(itemFilter => itemFilter.statut !== 'supprimé')
        if (Emplacement === 'Domicile') {
            listeStades = listeStades.filter(itemFilter => itemFilter.id_club === 1)
        } else if (Emplacement === 'Extérieur' && IdAdversaire) {
            listeStades = listeStades.filter(itemFilter => itemFilter.id_club === parseInt(IdAdversaire))
        } else if (IdElement !== 'ajouter') {
            listeStades = listeStades.filter(itemFilter => itemFilter.statut !== 'supprimé')
        } else {
            listeStades = listeStades.filter(itemFilter => itemFilter.id_club === 0)
        }
        setListeStades(listeStades)

        // GET MATCH DUREE MI-TEMPS
        const listeDureeMiTemps = await getMatchsDureeMitemps();
        const nomsDureeMiTemps = listeDureeMiTemps.map(item => item.duree);
        setListeDureeMiTemps(nomsDureeMiTemps)

        // GET EMPLACEMENTS
        const nomsEmplacements = ['Domicile', 'Extérieur']
        setListeEmplacements(nomsEmplacements)

        // GET EMPLACEMENTS
        const nomsLieuxRdv = ['Mairie', 'Stade']
        setListeLieuxRdv(nomsLieuxRdv)

        // GET COMPTEUR NOMBRE DE MI-TEMPS
        const compteurNbMiTemps = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
        setListeCompteurMiTemps(compteurNbMiTemps)

        if (IdElement !== 'ajouter') {
            const selectedMatch = listeMatchs.filter(item => parseInt(item.id) === parseInt(IdElement) && item.statut !== 'supprimé')
            if (selectedMatch.length === 0) {
                navigate('/notFound');
            } else {
                setListeMatchs(selectedMatch)

                if (Emplacement === false) {
                    if (selectedMatch[0].emplacement === 'Domicile') {
                        setListeStades(listeStades.filter(itemFilter => itemFilter.id_club === 1))
                    } else {
                        if (IdAdversaire === false) {
                            setListeStades(listeStades.filter(itemFilter => itemFilter.id_club === selectedMatch[0].id_adversaire))
                            setIdAdversaire(selectedMatch[0].id_adversaire)
                        } else {
                            setListeStades(listeStades.filter(itemFilter => itemFilter.id_club === parseInt(IdAdversaire)))
                        }
                    }
                }

                const selectedCategorie = listeCategories.filter(itemFilter => itemFilter.id === selectedMatch[0].id_categorie)
                setSelectedCategorie(...selectedCategorie)

                const selectedCompetition = listeCompetitions.filter(itemFilter => itemFilter.id === selectedMatch[0].id_competition)
                setSelectedCompetition(...selectedCompetition)

                // EDIT TITRE HEADER
                gsSetTitreHeaderSecondaire(selectedMatch[0].nom)
            }
        } else {
            // EDIT TITRE HEADER
            gsSetTitreHeaderSecondaire('Ajouter match')
        }

    }, [IdElement, Emplacement, IdAdversaire, gsSetTitreHeaderSecondaire, navigate]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////
    // HANDLE //
    ////////////

    async function handleSubmit(e) {
        // VARIABLES
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        //const nom = formData.get('nom').toUpperCase();
        const description = formData.get('description');
        const categorie = ListeCategories.find(itemFind => itemFind.nom === formData.get('catégorie')).id;
        const competition = ListeCompetitions.find(itemFind => itemFind.nom === formData.get('compétition')).id;
        const adversaire = formData.get('adversaire');
        const emplacement = formData.get('emplacement');
        const date = formData.get('date');
        const heure_match = formData.get('heure match');
        const heure_rdv = formData.get('heure rdv');
        const lieu_rdv = formData.get('lieu rdv');
        const stade = formData.get('stade');
        const score = formData.get('score');
        const resultat = formData.get('résultat');
        const nb_mi_temps = formData.get('nombre de mi-temps');
        const duree_mi_temps = formData.get('durée mi-temps');
        const nb_prolongation = formData.get('nombre de prolongation');
        const duree_prolongation = formData.get('durée prolongation');
        const statut = 'en ligne'
        let nom
        if (emplacement === 'Domicile') {
            nom = ListeClubs.find(itemFilter => itemFilter.id === 1).acronyme + ' vs ' + ListeClubs.find(itemFilter => itemFilter.id === parseInt(adversaire)).acronyme
        } else {
            nom = ListeClubs.find(itemFilter => itemFilter.id === parseInt(adversaire)).acronyme + ' vs ' + ListeClubs.find(itemFilter => itemFilter.id === 1).acronyme
        }

        // VERIFICATION
        const verifMatchExistant = ListeMatchs.filter(item => item.nom.toLowerCase() === nom.toLowerCase() && item.date === date && item.heure_match === heure_match)
        let verifIdMatchExistant;
        if (verifMatchExistant.length > 0 && IdElement !== "ajouter") {
            verifIdMatchExistant = ListeMatchs.find(item => item.nom.toLowerCase() === nom.toLowerCase() && item.date === date && item.heure_match === heure_match).id
        }

        if (verifMatchExistant.length > 0 && parseInt(verifIdMatchExistant) !== parseInt(IdElement)) {
            const verifMatchstatut = ListeMatchs.find(item => item.nom.toLowerCase() === nom.toLowerCase() && item.date === date && item.heure_match === heure_match).statut
            verifIdMatchExistant = ListeMatchs.find(item => item.nom.toLowerCase() === nom.toLowerCase() && item.date === date && item.heure_match === heure_match).id
            if (verifMatchstatut === 'supprimé') {
                // AFFICHER MODAL
                const affichageModal = 'Voulez-vous restaurer le match suivant ?';
                setShowModal(true)
                setContentModal(
                    <ModalOuiNon handleClick={handleOuiNonClick} affichage={affichageModal} infos={nom} var_name={'restauration'} var_id={verifIdMatchExistant} />
                )
            } else {
                // AFFICHER MODAL
                const affichageModal = 'Ce match existe déjà !';
                setShowModal(true)
                setContentModal(
                    <div>
                        {affichageModal}
                    </div>
                )
                setInfosModal(
                    <div>
                        {nom}
                    </div>
                )
                setReloadPage(false)
            }
        } else {
            // AFFICHER MODAL
            setShowModal(true)

            let affichageModal = "";
            let insertSql = [];
            let updateSql = [];

            if (IdElement === 'ajouter') {
                // INSERTION DONNEES EN BASE
                insertSql = await insertMatchs(nom, description, categorie, competition, adversaire, emplacement, date, heure_match, heure_rdv, lieu_rdv, stade, score, resultat, nb_mi_temps, duree_mi_temps, nb_prolongation, duree_prolongation, statut);
                affichageModal = 'Le Match suivant a bien été ajouté';
            } else {
                // MISE A JOUR DONNEES EN BASE
                updateSql = await updateMatchs(IdElement, nom, description, categorie, competition, adversaire, emplacement, date, heure_match, heure_rdv, lieu_rdv, stade, score, resultat, nb_mi_temps, duree_mi_temps, nb_prolongation, duree_prolongation, statut);
                affichageModal = 'Le Match suivant a bien été mis à jour';
            }

            if (insertSql.includes("SUCCESS") || updateSql.includes("SUCCESS")) {
                setReloadPage(true)
                setContentModal(
                    <div>{affichageModal}</div>
                )
                setInfosModal(
                    <div>{nom}</div>
                )
            }
        }
    };

    async function handleButtonClick(e) {
        const value = e.target.name
        const form = document.querySelector('.formulaire-container');
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const nom = formData.get('nom').toUpperCase();

        if (value === 'supprimer') {
            // AFFICHER MODAL
            const affichageModal = 'Voulez-vous vraiment supprimer le match suivant ?';

            setShowModal(true)
            setContentModal(
                <ModalOuiNon handleClick={handleOuiNonClick} affichage={affichageModal} infos={nom} var_name={'suppression'} />
            )
            setReloadPage(false)
        }
    }

    async function handleOuiNonClick(e) {
        const name = e.target.name
        const value = e.target.value
        const id = e.target.id

        const form = document.querySelector('.formulaire-container');
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        // const nom = formData.get('nom').toUpperCase();
        const description = formData.get('description');
        const categorie = ListeCategories.find(itemFind => itemFind.nom === formData.get('catégorie')).id;
        const competition = ListeCompetitions.find(itemFind => itemFind.nom === formData.get('compétition')).id;
        const adversaire = formData.get('adversaire');
        const emplacement = formData.get('emplacement');
        const date = formData.get('date');
        const heure_match = formData.get('heure match');
        const heure_rdv = formData.get('heure rdv');
        const lieu_rdv = formData.get('lieu rdv');
        const stade = formData.get('stade');
        const score = formData.get('score');
        const resultat = formData.get('résultat');
        const nb_mi_temps = formData.get('nombre de mi-temps');
        const duree_mi_temps = formData.get('durée mi-temps');
        const nb_prolongation = formData.get('nombre de prolongation');
        const duree_prolongation = formData.get('durée prolongation');
        let nom
        if (emplacement === 'Domicile') {
            nom = ListeClubs.find(itemFilter => itemFilter.id === 1).acronyme + ' vs ' + ListeClubs.find(itemFilter => itemFilter.id === adversaire).acronyme
        } else {
            nom = ListeClubs.find(itemFilter => itemFilter.id === adversaire).acronyme + ' vs ' + ListeClubs.find(itemFilter => itemFilter.id === 1).acronyme
        }

        if (name === 'suppression') {
            if (value === 'oui') {
                const statut = 'supprimé'

                // MISE A JOUR DONNEES EN BASE
                const updateSql = await updateMatchs(IdElement, nom, description, categorie, competition, adversaire, emplacement, date, heure_match, heure_rdv, lieu_rdv, stade, score, resultat, nb_mi_temps, duree_mi_temps, nb_prolongation, duree_prolongation, statut);
                const affichageModal = 'Le match suivant a bien été supprimé';

                if (updateSql.includes("SUCCESS")) {
                    // AFFICHER MODAL
                    setContentModal(
                        <div>
                            <div>{affichageModal}</div><br />
                            <div className='cl-principalBold'>{nom}</div>
                        </div>
                    )
                }
                setReloadPage(true)
            } else {
                setShowModal(false)
            }
        } else if (name === 'restauration') {
            if (value === 'oui') {
                const updateSql = await updateRestaurationSuppressionMatch(id, 'en ligne');
                if (updateSql.includes("SUCCESS")) {
                    const affichageModal = 'Le match suivant a bien été restauré !';
                    // AFFICHER MODAL
                    setContentModal(
                        <div>
                            <div>{affichageModal}</div><br />
                            <div className='cl-principalBold'>{nom}</div>
                        </div>
                    )
                    setReloadPage(true)
                }
            } else {
                setShowModal(false)
            }
        }
    }

    async function handleReloadPageClick(e) {
        if (ReloadPage === true) {
            navigate('/Matchs');
        }
    }

    async function handleEmplacementChange(e) {
        setEmplacement(e.target.value)
    }

    async function handleAdversaireChange(e) {
        setIdAdversaire(e.target.value)
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className='informationsMatchs-children'>
            <form
                className='formulaire-container'
                onSubmit={(e) => { handleSubmit(e) }}
            >
                {IdElement !== 'ajouter' && (
                    <FormInputText
                        var_readOnly={false}
                        var_nom={'nom'}
                        var_class={"bg-main"}
                        var_required={true}
                        var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? ListeMatchs[0].nom : ''}
                    />
                )}
                <FormSelect
                    var_readOnly={false}
                    var_nom={'catégorie'}
                    var_class={"bg-main"}
                    var_array={ListeCategories.map(item => item.nom)}
                    var_required={true}
                    var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? SelectedCategorie.nom : ''}
                />
                <FormSelect
                    var_readOnly={false}
                    var_nom={'compétition'}
                    var_class={"bg-main"}
                    var_array={ListeCompetitions.map(item => item.nom)}
                    var_required={true}
                    var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? SelectedCompetition.nom : ''}
                />
                <FormSelectWithId
                    var_readOnly={false}
                    var_nom={'adversaire'}
                    var_class={"bg-main"}
                    var_array={ListeClubs}
                    var_required={true}
                    var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? ListeMatchs[0].id_adversaire : ''}
                    var_handleOnChange={handleAdversaireChange}
                />
                <FormInputText
                    var_readOnly={false}
                    var_nom={'description'}
                    var_class={"bg-main"}
                    var_required={false}
                    var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? ListeMatchs[0].description : ''}
                />
                <FormSelect
                    var_readOnly={false}
                    var_nom={'emplacement'}
                    var_class={"bg-main"}
                    var_array={ListeEmplacements}
                    var_required={true}
                    var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? ListeMatchs[0].emplacement : ''}
                    var_handleOnChange={handleEmplacementChange}
                />
                <FormInputDate
                    var_readOnly={false}
                    var_nom={'date'}
                    var_class={"bg-main"}
                    var_required={true}
                    var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? ListeMatchs[0].date : ''}
                />
                <FormInputHeure
                    var_readOnly={false}
                    var_nom={'heure match'}
                    var_class={"bg-main"}
                    var_required={true}
                    var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? ListeMatchs[0].heure_match : ''}
                />
                <FormInputHeure
                    var_readOnly={false}
                    var_nom={'heure rdv'}
                    var_class={"bg-main"}
                    var_required={true}
                    var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? ListeMatchs[0].heure_rdv : ''}
                />
                <FormSelect
                    var_readOnly={false}
                    var_nom={'lieu rdv'}
                    var_class={"bg-main"}
                    var_array={ListeLieuxRdv}
                    var_required={true}
                    var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? ListeMatchs[0].lieu_rdv : ''}
                />
                <FormSelectWithId
                    var_readOnly={false}
                    var_nom={'stade'}
                    var_class={"bg-main"}
                    var_array={ListeStades}
                    var_required={true}
                    var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? ListeMatchs[0].id_stade : ''}
                    var_legendeLabel={'(sélectionner adversaire + emplacement)'}
                />
                {ListeMatchs.length > 0 && ListeMatchs[0].score !== '' && IdElement !== 'ajouter' && (
                    <FormInputText
                        var_readOnly={false}
                        var_nom={'score'}
                        var_class={"bg-main"}
                        var_required={false}
                        var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? ListeMatchs[0].score : ''}
                    />
                )}
                {ListeMatchs.length > 0 && ListeMatchs[0].score !== '' && IdElement !== 'ajouter' && (
                    <FormInputText
                        var_readOnly={false}
                        var_nom={'résultat'}
                        var_class={"bg-main"}
                        var_required={false}
                        var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? ListeMatchs[0].resultat : ''}
                    />
                )}
                <FormSelectNumeric
                    var_readOnly={false}
                    var_nom={'nombre de mi-temps'}
                    var_class={"bg-main"}
                    var_array={ListeCompteurMiTemps}
                    var_required={true}
                    var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? ListeMatchs[0].nb_mi_temps : ''}
                />
                <FormSelectNumeric
                    var_readOnly={false}
                    var_nom={'durée mi-temps'}
                    var_class={"bg-main"}
                    var_array={ListeDureeMiTemps}
                    var_required={true}
                    var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? ListeMatchs[0].duree_mi_temps : ''}
                />
                <FormSelectNumeric
                    var_readOnly={false}
                    var_nom={'nombre de prolongation'}
                    var_class={"bg-main"}
                    var_array={ListeCompteurMiTemps}
                    var_required={false}
                    var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? ListeMatchs[0].nb_prolongation : ''}
                />
                <FormSelectNumeric
                    var_readOnly={false}
                    var_nom={'durée prolongation'}
                    var_class={"bg-main"}
                    var_array={ListeDureeMiTemps}
                    var_required={false}
                    var_value={ListeMatchs.length > 0 && IdElement !== 'ajouter' ? ListeMatchs[0].duree_prolongation : ''}
                />
                <FormInputSubmit
                    var_nom={'submit'}
                    var_value={IdElement === 'ajouter' ? 'ajouter' : 'sauvegarder'}
                    var_class={'bg-principal'}
                />
                {IdElement !== 'ajouter' && (
                    <FormBouton
                        var_nom={'supprimer'}
                        var_value={'supprimer'}
                        var_class={'bg-supprimer'}
                        var_handleButtonClick={handleButtonClick}
                    />
                )}
            </form>

            <Modal
                showModal={ShowModal}
                contentModal={ContentModal}
                handleCloseModal={() => {
                    setShowModal(false);
                    setContentModal("");
                    handleReloadPageClick();
                }}
                infosModal={InfosModal}
            />
        </div>
    )
}

export default InformationsMatchs;
