import React from 'react';
import AfficherMatchs from '../components/matchs/AfficherMatchs';
import AfficherInformationsMatchs from '../components/matchs/AfficherInformationsMatchs';
import AfficherCompositionMatch from '../components/matchs/AfficherCompositionMatch'
import AfficherMatchEnCours from '../components/matchs/AfficherMatchEnCours'

const Matchs = () => {
    return (
        <div className='matchs-container'>
            <AfficherMatchs />
        </div>
    );
};

const AjouterMatchs = () => {
    return (
        <div className='informationsMatchs-container'>
            <AfficherInformationsMatchs />
        </div>
    );
};

const InformationsMatchs = () => {
    return (
        <div className='informationsMatchs-container'>
            <AfficherInformationsMatchs />
        </div>
    );
};

const CompositionMatch = () => {
    return (
        <div className='matchsComposition-container'>
            <AfficherCompositionMatch />
        </div>
    );
};

const MatchEnCours = () => {
    return (
        <div className='matchEnCours-container'>
            <AfficherMatchEnCours />
        </div>
    );
};

export { Matchs, AjouterMatchs, InformationsMatchs, CompositionMatch, MatchEnCours };