import React, { useCallback, useState, useEffect } from 'react';
import { getMatchsBoutonsStats } from '../../api/matchs/getMatchsBoutonsStats';
import { insertMatchsStats } from '../../api/matchs/insertMatchsStats';
import CalculateMinutesDiff from '../fonctions/CalculateMinutesDiff';
import getCurrentTime from '../fonctions/getCurrentTime';
import BoutonsClubs from './BoutonsClubs';
import BoutonsJoueurs from './BoutonsJoueurs';
import Modal from '../Modal'
import { getCompositions } from '../../api/compositions/getCompositions';

function BoutonsStatsMatchs({ fetchId, clubHote, clubVisiteur, idMatch, heureDebutChrono, miTemps, dureeMiTemps, scoreHote, scoreVisiteur, nbMitemps, nbProlongation, dureeProlongation }) {

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // VARIABLES //
    ///////////////
    const [BoutonsStats, setBoutonsStats] = useState([]);
    const [ListeCompositions, setListeCompositions] = useState([]);
    const [affichage, setAffichage] = useState(null); // Nouvel état pour contrôler l'affichage

    const [ShowModal, setShowModal] = useState(false);
    const [ContentModal, setContentModal] = useState(false);
    const [ReloadPage, setReloadPage] = useState(false);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    const fetch = useCallback(async () => {
        // GET BOUTONS STATS
        const matchsBoutonsStats = await getMatchsBoutonsStats();
        // Tri par nom (supposons que chaque élément du tableau a une propriété "nom")
        matchsBoutonsStats.sort((a, b) => {
            const nomA = a.nom.toUpperCase(); // Convertir les noms en majuscules pour le tri insensible à la casse
            const nomB = b.nom.toUpperCase();

            if (nomA < nomB) {
                return -1;
            }
            if (nomA > nomB) {
                return 1;
            }

            return 0; // Les noms sont égaux
        });
        setBoutonsStats(matchsBoutonsStats);

        // GET COMPOSITIONS
        let listeCompositions = await getCompositions();
        listeCompositions = listeCompositions.filter(itemFilter => itemFilter.id_match === parseInt(idMatch))
        setListeCompositions(...listeCompositions);
    }, [idMatch]);

    useEffect(() => {
        fetch();
    }, [fetch]); // Appeler fetchMatchsBoutonsStats une seule fois lors du montage initial

    async function InsertStats(id_match, id_club, nom) {
        const currentTime = getCurrentTime();
        const chrono = CalculateMinutesDiff(heureDebutChrono, currentTime, miTemps, dureeMiTemps, nbMitemps, nbProlongation, dureeProlongation)

        const insertBdd = await insertMatchsStats(id_match, id_club, nom, miTemps, chrono);
        console.log(insertBdd)
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////
    // HANDLE //
    ////////////

    const handleBoutonsStatsClick = (typeBouton, nom, perimetre, idClub, typeClub, acteurs, nomActeurs) => {
        if (typeBouton === 'retour') {
            setAffichage(null);
        } else if (typeBouton === "rechargementScore") {
            setAffichage(null);
            fetchId()
        } else if (typeBouton === "boutonsStats") {
            setAffichage(<BoutonsClubs handleBoutonsStatsClick={handleBoutonsStatsClick} nom={nom} perimetre={perimetre} clubHote={clubHote} clubVisiteur={clubVisiteur} acteurs={acteurs} nomActeurs={nomActeurs} miTemps={miTemps} />);
        } else if (typeBouton === "boutonsClubs") {
            if (perimetre === "joueur") {
                let affichage = true;
                if (nom === 'changements' && idClub === 1 && ListeCompositions.remplacants === "") {
                    affichage = false
                    setShowModal(true);
                    setContentModal(
                        <div>
                            <i className="fa-solid fa-triangle-exclamation fa-beat" style={{ color: '#ffff00' }}></i> Pas de remplacant présent dans la compo.
                        </div>
                    );
                    setReloadPage(true)
                }

                if (affichage === true) {
                    const currentTime = getCurrentTime();
                    const chrono = CalculateMinutesDiff(heureDebutChrono, currentTime, miTemps, dureeMiTemps, nbMitemps, nbProlongation, dureeProlongation)
                    setAffichage(<BoutonsJoueurs handleBoutonsStatsClick={handleBoutonsStatsClick} idClub={idClub} idMatch={idMatch} typeBouton={typeBouton} nomBouton={nom} chrono={chrono} scoreHote={scoreHote} scoreVisiteur={scoreVisiteur} typeClub={typeClub} acteurs={acteurs} nomActeurs={nomActeurs} miTemps={miTemps} />);
                }
            } else {
                InsertStats(idMatch, idClub, nom);
                setAffichage(null);
            }
        }
    };

    async function handleReloadPageClick(e) {
        if (ReloadPage === true) {
            setAffichage(null);
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className='boutonsStatsMatchs-container'>
            {affichage ? (
                // Si le state 'affichage' est défini, affiche son contenu
                <div>
                    {affichage}
                </div>
            ) : (
                // Sinon, affiche le contenu initial avec les boutons
                <div className='boutonsStatsMatchs-children'>
                    {BoutonsStats.map((item, index) => (
                        <div
                            key={index}
                            className='boutonsStatsMatchs-tuilesContainer bg-secondaire bd-principal'
                            // style={{ backgroundColor: item.couleur }}
                            onClick={() => handleBoutonsStatsClick("boutonsStats", item.nom, item.perimetre, "", "", item.acteurs, item.nom_acteurs)}
                        >
                            <img src={'/icones/' + item.nom + '.png'} alt={item.nom}></img>
                            <div>{item.nom.toUpperCase()}</div>
                        </div>
                    ))}
                </div>
            )}

            <Modal
                showModal={ShowModal}
                contentModal={ContentModal}
                handleCloseModal={() => {
                    setShowModal(false);
                    setContentModal("");
                    handleReloadPageClick();
                }}
            />
        </div>
    );
}

export default BoutonsStatsMatchs;
