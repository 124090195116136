import React from 'react';
import UcFirst from '../fonctions/UcFirst';

const TuileEntrainements = ({ var_id, var_infos, var_date, var_heure, var_class, var_handleOnClick, var_arrayOptions, var_selectedId, var_handleOptionClick }) => {
    return (
        <div id={var_id} className={'tuile-children mosaique entrainement bs-principal bg-secondaire ' + var_class} onClick={var_handleOnClick}>
            <div id={var_id} className='infos-container'>
                <div id={var_id} className='date-container'>
                    {var_date.map((item, index) => (
                        <div id={var_id} key={index} className="">
                            {item}
                        </div>
                    ))}
                    <hr />
                    <div>{var_heure}</div>
                </div>

                <div id={var_id} className='infos'>
                    {var_infos.map((item, index) => (
                        item === "Inactive" ? (
                            <div id={var_id} key={index} style={{ display: 'flex' }}>
                                <div id={var_id} style={{ marginRight: '3px' }}>Licence : </div>
                                <div id={var_id} key={index} className="cl-licenceInactive">{item}</div>
                            </div>
                        ) : item === "Active" ? (
                            <div id={var_id} key={index} style={{ display: 'flex' }}>
                                <div id={var_id} style={{ marginRight: '3px' }}>Licence : </div>
                                <div id={var_id} key={index} className="cl-licenceActive">{item}</div>
                            </div>
                        ) : (
                            <div id={var_id} key={index} className="">
                                {item}
                            </div>
                        )
                    ))}
                </div>

                <div id={var_id} className='icone'><i className="fas fa-chevron-right"></i></div>
            </div>
            

            <div className={parseInt(var_selectedId) !== parseInt(var_id) ? 'general-displayNone' : 'optionCache-container bg-modalFont'}>
                {var_arrayOptions.map((item, index) => (
                    <button id={var_id} value={item} key={index} className='bg-principal' onClick={var_handleOptionClick}>
                        {UcFirst(item.toLowerCase())}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default TuileEntrainements;
