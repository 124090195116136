import React from 'react';

const Modal = ({ showModal, contentModal, handleCloseModal, infosModal }) => {
  const handleModalClick = (e) => {
    e.stopPropagation(); // Empêche la propagation de l'événement
  };

  return (
    <div
      className={showModal === true ? 'modal-container bg-modalFont' : 'modal-container close'}
      onClick={handleCloseModal}
    >
      <div className="modal-main bg-modalBack" onClick={handleModalClick}>
        <div className='modal-close bg-close' onClick={handleCloseModal}>X</div>
        <div className='modal-content'>{contentModal}</div>
        <div className='modal-infos cl-principalBold'>{infosModal}</div>
      </div>
    </div>
  );
};

export default Modal;