import React from 'react';

function BoutonItineraire({ var_nom, var_class, var_url }) {
    const handleClick = () => {
        // Rediriger vers l'URL lorsque la div est cliquée
        window.location.href = var_url;
    };

    return (
        <div
            className={'boutonItineraire-container ' + var_class}
            onClick={handleClick}
        >
            {var_nom}
        </div>
    );
}

export default BoutonItineraire;
