import React, { useEffect, useCallback, useState } from 'react';
import { getCompositions } from '../../api/compositions/getCompositions';
import { getJoueurs } from '../../api/joueurs/getJoueurs';
import { insertMatchsStats } from '../../api/matchs/insertMatchsStats';
import { updateMatchsInfosScores } from '../../api/matchs/updateMatchsInfosScores';
import { updateCompositionsMatchs } from '../../api/compositions/updateCompositionsMatchs';
import { getMatchsStats } from '../../api/matchs/getMatchsStats'
import UcFirst from '../fonctions/UcFirst';

function ListeJoueurs({ handleBoutonsStatsClick, idClub, idMatch, nomBouton, chrono, scoreHote, scoreVisiteur, typeClub, acteurs, nomActeurs, miTemps }) {
    // VARIABLES
    const [ListeJoueurs, setListeJoueurs] = useState([]);
    const [ListeJoueursRemplacants, setListeJoueursRemplacants] = useState([]);
    const [ListeNumeroMaillots, setListeNumeroMaillots] = useState([]);
    const [NumeroMaillotTitulaire, setNumeroMaillotTitulaire] = useState([]);
    const [ListeCompositions, setListeCompositions] = useState([]);
    const [JoueursExclus, setJoueursExclus] = useState([]);
    const [JoueursAvecUnCartonJaune, setJoueursAvecUnCartonJaune] = useState([]);
    const [NumeroActeurPrimaire, setNumeroActeurPrimaire] = useState([]);
    const [SelectedPlayer, setSelectedPlayer] = useState(null);
    const [Acteurs] = useState(acteurs - 1);
    const [Compteur, setCompteur] = useState(0);
    const NomActeurs = nomActeurs.split(",");
    const [AffichageConsigne, setAffichageConsigne] = useState("Sélectionnez le " + NomActeurs[0].toUpperCase());

    // FETCH
    const fetch = useCallback(async () => {
        if (idClub === 1) {
            // GET COMPOSITIONS
            let listeCompositions = await getCompositions();

            // GET JOUEURS
            const listeJoueurs = await getJoueurs();

            listeCompositions = listeCompositions.filter(itemFilter => itemFilter.id_match === parseInt(idMatch))
            setListeCompositions(listeCompositions)
            const listeJoueursTitulaires = listeCompositions[0].titulaires.split(',');
            const listeJoueursRemplacants = listeCompositions[0].remplacants.split(',');
            const listeNumeroMaillots = listeCompositions[0].numero_maillots.split(',');
            setListeNumeroMaillots(listeNumeroMaillots)

            // LISTE JOUEURS TITULAIRES
            let listeIdJoueurs = listeJoueursTitulaires;
            let joueursPromises = listeIdJoueurs.map(async (item) => {
                const joueur = listeJoueurs.filter(itemFilter => itemFilter.id === parseInt(item));
                return joueur; // Renvoie la promesse pour ce joueur
            });
            // Attendez toutes les promesses de joueurs pour résoudre
            let listeJoueursArray = await Promise.all(joueursPromises);
            // Concaténez tous les tableaux de joueurs en un seul tableau
            let listeJoueursFinal = listeJoueursArray.flat();
            setListeJoueurs(listeJoueursFinal);

            // LISTE JOUEURS REMPLACANTS
            listeIdJoueurs = listeJoueursRemplacants;
            joueursPromises = listeIdJoueurs.map(async (item) => {
                const joueur = listeJoueurs.filter(itemFilter => itemFilter.id === parseInt(item));
                return joueur; // Renvoie la promesse pour ce joueur
            });
            // Attendez toutes les promesses de joueurs pour résoudre
            listeJoueursArray = await Promise.all(joueursPromises);
            // Concaténez tous les tableaux de joueurs en un seul tableau
            listeJoueursFinal = listeJoueursArray.flat();
            setListeJoueursRemplacants(listeJoueursFinal);
        } else {
            const joueursData = Array.from({ length: 15 }, (_, index) => ({
                numero_joueur: index + 1,
                photo: "/default/default-maillot.png",
                nom_joueur: "Numéro"
            }));
            setListeJoueurs(joueursData)
        }

        // RECUPERATION JOUEUR EXCLUS
        let listeStatsMatchs = await getMatchsStats();
        listeStatsMatchs = listeStatsMatchs.filter(itemFilter => itemFilter.id_match === parseInt(idMatch))
        const joueursExclus = listeStatsMatchs.filter(itemFilter => itemFilter.nom === "cartons rouges" || itemFilter.nom === "deux cartons jaunes");
        setJoueursExclus(joueursExclus)

        // RECUPERATION JOUEUR AYANT DEJA UN CARTON JAUNE
        const joueursAvecUnCartonJaune = listeStatsMatchs.filter(item => item.nom === "cartons jaunes")
        setJoueursAvecUnCartonJaune(joueursAvecUnCartonJaune)
    }, [idMatch, idClub]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    // ONCLICK
    async function handleJoueurClick(numeroJoueur, nomJoueur, idJoueur, numeroMaillot) {
        if (Compteur === Acteurs) {
            let updateBdd;
            let insertBdd;
            let score = null;
            let acteurPrimaire = null;
            let acteurSecondaire = null;

            if (idJoueur) {
                if (Acteurs === 1) {
                    acteurPrimaire = NumeroActeurPrimaire;
                    console.log(idJoueur)
                    if (idJoueur !== 'aucun') {
                        acteurSecondaire = idJoueur;
                        console.log(acteurSecondaire)
                    }
                } else {
                    acteurPrimaire = idJoueur;
                }
            } else {
                if (Acteurs === 1) {
                    acteurPrimaire = nomJoueur + " " + NumeroActeurPrimaire;
                    acteurSecondaire = nomJoueur + " " + numeroJoueur;
                } else {
                    acteurPrimaire = nomJoueur + " " + numeroJoueur;
                }
            }

            if (nomBouton === "cartons jaunes") {
                if (JoueursAvecUnCartonJaune.filter(itemFilter => itemFilter.acteur_primaire === acteurPrimaire).length > 0 || JoueursAvecUnCartonJaune.filter(itemFilter => itemFilter.acteur_primaire === acteurPrimaire).length > 0) {
                    nomBouton = "deux cartons jaunes"
                }
            }

            if (typeClub === "clubHote") {
                if (nomBouton === "buts") {
                    const changeScoreHote = scoreHote + 1
                    score = changeScoreHote + "-" + scoreVisiteur
                    updateBdd = await updateMatchsInfosScores(idMatch, changeScoreHote, scoreVisiteur);
                    console.log(updateBdd)
                    insertBdd = await insertMatchsStats(idMatch, idClub, 'tirs cadrés', miTemps, chrono, acteurPrimaire, acteurSecondaire, score);
                    console.log(insertBdd)
                    insertBdd = await insertMatchsStats(idMatch, idClub, 'tirs', miTemps, chrono, acteurPrimaire, acteurSecondaire, score);
                    console.log(insertBdd)
                }
                if (nomBouton.includes('tir')) {
                    insertBdd = await insertMatchsStats(idMatch, idClub, 'tirs', miTemps, chrono, acteurPrimaire, acteurSecondaire, score);
                    console.log(insertBdd)
                }
                if (nomBouton.includes('penalty réussis')) {
                    const changeScoreHote = scoreHote + 1
                    score = changeScoreHote + "-" + scoreVisiteur
                    updateBdd = await updateMatchsInfosScores(idMatch, changeScoreHote, scoreVisiteur);
                    console.log(updateBdd)
                }
                if (nomBouton.includes('penalty réussis')) {
                    const changeScoreHote = scoreHote + 1
                    score = changeScoreHote + "-" + scoreVisiteur
                    updateBdd = await updateMatchsInfosScores(idMatch, changeScoreHote, scoreVisiteur);
                    console.log(updateBdd)
                }
                if (nomBouton === 'buts csc') {
                    const changeScoreVisiteur = scoreVisiteur + 1
                    score = scoreHote + "-" + changeScoreVisiteur
                    updateBdd = await updateMatchsInfosScores(idMatch, scoreHote, changeScoreVisiteur);
                    console.log(updateBdd)
                }
                if (nomBouton === "changements" && idJoueur) {
                    const numeroMaillotRemplacant = numeroMaillot

                    // MODIFICATION LISTE TITULAIRES
                    let listeTitulaires = ',' + ListeCompositions[0].titulaires + ','
                    listeTitulaires = listeTitulaires.replace(',' + acteurPrimaire + ',', ',' + acteurSecondaire + ',').slice(1, -1)

                    // MODIFICATION LISTE REMPLACANTS
                    let listeRemplacants = ',' + ListeCompositions[0].remplacants + ','
                    listeRemplacants = listeRemplacants.replace(',' + acteurSecondaire + ',', ',' + acteurPrimaire + ',').slice(1, -1)

                    // MODIFICATION LISTE REMPLACANTS
                    let listeNumeroMaillots = ',' + ListeCompositions[0].numero_maillots + ','
                    listeNumeroMaillots = listeNumeroMaillots.replace(',' + NumeroMaillotTitulaire + ',', ',' + NumeroMaillotTitulaire + '_TITU,')
                    listeNumeroMaillots = listeNumeroMaillots.replace(',' + numeroMaillotRemplacant + ',', ',' + NumeroMaillotTitulaire + ',')
                    listeNumeroMaillots = listeNumeroMaillots.replace(',' + NumeroMaillotTitulaire + '_TITU,', ',' + numeroMaillotRemplacant + ',').slice(1, -1)

                    // MISE A JOUR TABLE
                    updateBdd = await updateCompositionsMatchs(idMatch, listeTitulaires, listeRemplacants, listeNumeroMaillots);
                    console.log(updateBdd)
                }
                insertBdd = await insertMatchsStats(idMatch, idClub, nomBouton, miTemps, chrono, acteurPrimaire, acteurSecondaire, score);
                console.log(insertBdd)
            } else if (typeClub === "clubVisiteur") {
                if (nomBouton === "buts") {
                    const changeScoreVisiteur = scoreVisiteur + 1
                    score = scoreHote + "-" + changeScoreVisiteur
                    updateBdd = await updateMatchsInfosScores(idMatch, scoreHote, changeScoreVisiteur);
                    console.log(updateBdd)
                    insertBdd = await insertMatchsStats(idMatch, idClub, 'tirs cadrés', miTemps, chrono, acteurPrimaire, acteurSecondaire, score);
                    console.log(insertBdd)
                    insertBdd = await insertMatchsStats(idMatch, idClub, 'tirs', miTemps, chrono, acteurPrimaire, acteurSecondaire, score);
                    console.log(insertBdd)
                }
                if (nomBouton.includes('tir')) {
                    insertBdd = await insertMatchsStats(idMatch, idClub, 'tirs', miTemps, chrono, acteurPrimaire, acteurSecondaire, score);
                    console.log(insertBdd)
                }
                if (nomBouton.includes('penalty réussis')) {
                    const changeScoreVisiteur = scoreVisiteur + 1
                    score = scoreHote + "-" + changeScoreVisiteur
                    updateBdd = await updateMatchsInfosScores(idMatch, scoreHote, changeScoreVisiteur);
                    console.log(updateBdd)
                }
                if (nomBouton === "changements" && idJoueur) {
                    const numeroMaillotRemplacant = numeroMaillot

                    // MODIFICATION LISTE TITULAIRES
                    let listeTitulaires = ',' + ListeCompositions[0].titulaires + ','
                    listeTitulaires = listeTitulaires.replace(',' + acteurPrimaire + ',', ',' + acteurSecondaire + ',').slice(1, -1)

                    // MODIFICATION LISTE REMPLACANTS
                    let listeRemplacants = ',' + ListeCompositions[0].remplacants + ','
                    listeRemplacants = listeRemplacants.replace(',' + acteurSecondaire + ',', ',' + acteurPrimaire + ',').slice(1, -1)

                    // MODIFICATION LISTE REMPLACANTS
                    let listeNumeroMaillots = ',' + ListeCompositions[0].numero_maillots + ','
                    listeNumeroMaillots = listeNumeroMaillots.replace(',' + NumeroMaillotTitulaire + ',', ',' + NumeroMaillotTitulaire + '_TITU,')
                    listeNumeroMaillots = listeNumeroMaillots.replace(',' + numeroMaillotRemplacant + ',', ',' + NumeroMaillotTitulaire + ',')
                    listeNumeroMaillots = listeNumeroMaillots.replace(',' + NumeroMaillotTitulaire + '_TITU,', ',' + numeroMaillotRemplacant + ',').slice(1, -1)

                    // MISE A JOUR TABLE
                    updateBdd = await updateCompositionsMatchs(idMatch, listeTitulaires, listeRemplacants, listeNumeroMaillots);
                    console.log(updateBdd)
                }
                if (nomBouton === 'buts csc') {
                    const changeScoreHote = scoreHote + 1
                    score = changeScoreHote + "-" + scoreVisiteur
                    updateBdd = await updateMatchsInfosScores(idMatch, changeScoreHote, scoreVisiteur);
                    console.log(updateBdd)
                }
                insertBdd = await insertMatchsStats(idMatch, idClub, nomBouton, miTemps, chrono, acteurPrimaire, acteurSecondaire, score);
                console.log(insertBdd)
            }

            handleBoutonsStatsClick('rechargementScore');
        } else {
            setNumeroMaillotTitulaire(numeroMaillot)
            setAffichageConsigne("Sélectionnez le " + NomActeurs[Compteur + 1].toUpperCase())
            if (idJoueur) {
                setSelectedPlayer(idJoueur);
                setNumeroActeurPrimaire(idJoueur)
                if (nomBouton === "changements") {
                    setListeNumeroMaillots(ListeNumeroMaillots.slice(-(ListeJoueursRemplacants.length)))
                    setListeJoueurs(ListeJoueurs.filter(itemFilter => itemFilter.id === idJoueur).concat(ListeJoueursRemplacants));
                }
            } else {
                setNumeroActeurPrimaire("" + numeroJoueur + "")
                setSelectedPlayer(numeroJoueur);
            }
        }
        setCompteur(Compteur + 1)
    };

    async function handleCancelClick() {
        setAffichageConsigne("Sélectionnez le " + NomActeurs[Compteur - 1].toUpperCase())
        setNumeroActeurPrimaire("")
        setSelectedPlayer("");
        setCompteur(Compteur - 1)
        fetch()
    };

    return (
        <div>
            <div className='consigne-container'>{nomBouton.toUpperCase()} : {AffichageConsigne}</div>
            <div className='listeJoueurs-container'>
                {Compteur > 0 && nomBouton !== 'changements' && (
                    <div
                        className={'joueur-container'}
                        id={'aucun'}
                    >
                        <div
                            className='joueur-children'
                            onClick={() => handleJoueurClick('aucun', 'aucun', 'aucun', 'aucun')}
                        >
                            <div className='photo-container'><img src='/icones/ban.png' alt="Logo" /></div>
                        </div>
                        <div className='iconeBan-container'>
                            <i className="fa-solid fa-ban fa-2xl"></i>
                        </div>
                        <div>Aucun</div>
                        <div className='cancel bg-annuler' onClick={(e) => { e.stopPropagation(); handleCancelClick(); }}>ANNULER</div>
                    </div>
                )}

                {ListeJoueurs.map((item, index) => {
                    let numero_joueur;
                    let nom_joueur;
                    let id_joueur;
                    let numero_maillot;

                    if (item.id) {
                        numero_joueur = item.id;
                        nom_joueur = item.nom;
                        id_joueur = item.id;
                        if (NumeroMaillotTitulaire.length > 0) {
                            numero_maillot = ListeNumeroMaillots[index - 1];
                        } else {
                            numero_maillot = ListeNumeroMaillots[index];
                        }
                    } else {
                        numero_joueur = item.numero_joueur;
                        nom_joueur = item.nom_joueur;
                    }

                    return (
                        <div
                            key={index}
                            className={
                                SelectedPlayer === numero_joueur
                                    ? 'joueur-container selected bg-selected'
                                    : JoueursExclus.filter(itemFilter => parseInt(itemFilter.acteur_primaire) === item.id).length > 0 || JoueursExclus.filter(itemFilter => itemFilter.acteur_primaire === item.nom_joueur + " " + item.numero_joueur).length > 0
                                        ? 'joueur-container joueurExclus'
                                        : 'joueur-container'
                            }
                            id={numero_joueur}
                        >
                            <div
                                className='joueur-children'
                                onClick={() => handleJoueurClick(numero_joueur, nom_joueur, id_joueur, numero_maillot)}
                            >
                                <div className='photo-container'><img src={item.photo} alt="Logo" /></div>
                                <div className={item.numero_joueur <= 9 ? 'numeroJoueur chiffre' : 'numeroJoueur nombre'} >{item.numero_joueur}</div>
                            </div>
                            <div className='iconeBan-container'>
                                <i className="fa-solid fa-ban fa-2xl"></i>
                            </div>
                            <div className='iconeExclus-container bg-joueurExclus'>
                                <div className="">EXCLUS</div>
                            </div>
                            <div>{idClub === 1 ? UcFirst(item.prenom) + " " + UcFirst(item.nom).charAt(0) + "." : ""}</div>
                            <div className='cancel bg-annuler' onClick={(e) => { e.stopPropagation(); handleCancelClick(); }}>ANNULER</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default ListeJoueurs;
