import axios from "axios";

export async function updateRestaurationSuppressionDirigeant(
    id_dirigeant,
    statut
) {
    try {
        const { data } = await axios.get(`
            ${process.env.REACT_APP_API_URL}updateRestaurationSuppressionDirigeant/?id_dirigeant=${id_dirigeant}&statut=${statut}
        `)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `SUCCESS`
            return msg;
        }
    } catch (error) {
        console.log(error);
    }
}
