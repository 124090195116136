function ShareMessage(message) {

    // Utiliser l'API Web Share pour afficher la boîte de dialogue de partage
    if (navigator.share) {
        navigator.share({
            text: message,
        })
            .then(() => console.log('Message envoyé'))
            .catch((error) => console.error('Erreur de partage', error));
    } else {
        // Gérer le partage pour les navigateurs qui ne prennent pas en charge l'API Web Share
        alert('Le partage n\'est pas pris en charge par votre navigateur.');
    }
}

export default ShareMessage;
