import React from 'react';
import BoutonsRetourArriere from './BoutonsRetourArriere';

function BoutonsClubs({ handleBoutonsStatsClick, nom, perimetre, clubHote, clubVisiteur, acteurs, nomActeurs, miTemps }) {

    return (
        <div className='boutonsClubs-container'>
            <BoutonsRetourArriere handleBoutonsStatsClick={handleBoutonsStatsClick} nomBouton={nom}/>
            <div className='consigne-container'>{nom.toUpperCase()} : Sélectionnez le club</div>
            <div className='boutonsClubs-children'>
                <div
                    className='club-container'
                    onClick={() => handleBoutonsStatsClick("boutonsClubs", nom, perimetre, clubHote.id, "clubHote", acteurs, nomActeurs, miTemps)}
                >
                    <div>
                        <img src={clubHote.photo} alt="Logo" />
                    </div>
                    <div>{clubHote.nom}</div>
                </div>
                <div
                    className='club-container'
                    onClick={() => handleBoutonsStatsClick("boutonsClubs", nom, perimetre, clubVisiteur.id, "clubVisiteur", acteurs, nomActeurs, miTemps)}
                >
                    <div>
                        <img src={clubVisiteur.photo} alt="Logo" />
                    </div>
                    <div>{clubVisiteur.nom}</div>
                </div>
            </div>
        </div>
    );
}

export default BoutonsClubs;
