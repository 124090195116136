import React, { useContext, useEffect, useState, useCallback } from 'react';
import { SettingsContext } from '../settings/RecuperationSettings';
import BoutonAction from '../boutons/boutonAction';
import { getEntrainements } from '../../api/entrainements/getEntrainements';
import { getGroupesEntrainements } from '../../api/entrainements/getGroupesEntrainements';
import { getStades } from '../../api/stades/getStades';
import TuileEntrainements from '../tuiles/tuilesEntrainements';
import { useNavigate, useLocation } from 'react-router-dom';
import UcFirst from '../fonctions/UcFirst'
import BoutonRecherche from '../boutons/boutonRecherche';
import globalStorage from '../fonctions/GlobalStorage';

function AfficherEntrainements() {

    ///////////////
    // VARIABLES //
    ///////////////
    
    // GLOBAL STORAGE
    const {gsSetTitreHeaderSecondaire} = globalStorage();

    const [ListeEntrainements, setListeEntrainements] = useState([]);
    const [ListeGroupesEntrainements, setListeGroupesEntrainements] = useState([]);
    const [ListeStades, setStades] = useState([]);
    const [SelectedId, setSelectedId] = useState([]);
    const [NbElements, setNbElements] = useState([]);
    const navigate = useNavigate(); // Utilisez useNavigate

    // RECUPERER PARAMETRE DE RECHERCHE
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const UrlRecherche = searchParams.get('recherche') || ''; // Obtenez la valeur de recherche de la chaîne de requête

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////
    // SETTINGS //
    //////////////

    const { settings } = useContext(SettingsContext);
    let WidthTuiles = '';

    if (Array.isArray(settings)) {
        const settingsWidthTuiles = settings.find((setting) => setting.name === 'WIDTH_TUILES');
        WidthTuiles = settingsWidthTuiles?.value || '';
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    const fetch = useCallback(async () => {
        // GET ENTRAINEMENTS
        let listeEntrainements = await getEntrainements();
        listeEntrainements = listeEntrainements.filter(item => item.statut !== 'supprimé')
        setListeEntrainements(listeEntrainements);

        // GET GROUPES ENTRAINEMENTS
        let listeGroupesEntrainements = await getGroupesEntrainements();
        listeGroupesEntrainements = listeGroupesEntrainements.filter(item => item.statut !== 'supprimé')
        setListeGroupesEntrainements(listeGroupesEntrainements);

        // GET STADES
        let listeStades = await getStades();
        listeStades = listeStades.filter(item => item.statut !== 'supprimé')
        setStades(listeStades);
    }, []);

    useEffect(() => {
        fetch();

        // EDIT TITRE HEADER
        gsSetTitreHeaderSecondaire('Entrainements')
    }, [fetch,gsSetTitreHeaderSecondaire]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////
    // HANDLE CLICK //
    //////////////////

    const handleBoutonClick = (e) => {
        navigate("ajouter")
    };

    async function handleOnClick(e) {
        const id = e.target.id;

        setSelectedId(id)

        if (Recherche !== '') {
            navigate(`?recherche=${Recherche}`);
        }

        // navigate(id);
    }

    async function handleOptionCacheClick(e) {
        e.stopPropagation();
        const id = e.target.id
        const value = e.target.value
        setSelectedId(id)
        let url

        navigate(`?recherche=${Recherche}`);
        url = value.split(' ')[0] + '/' + id
        navigate(url)
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////
    // FILTRES //
    /////////////

    const [Recherche, setRecherche] = useState(UrlRecherche);

    const handleRechercheChange = (e) => {
        setRecherche(e.target.value);
        if (e.target.value === '') {
            navigate('')
        }
    };

    const filtered = ListeEntrainements.filter((result) => {
        const nomComplet =
            result.nom.toLowerCase() +
            result.description.toLowerCase() +
            result.recurrence.toLowerCase() +
            (result.jour_recurrence !== null ? result.jour_recurrence.toLowerCase() : '') +
            result.date_debut.toLowerCase() +
            (result.date_fin !== null ? result.date_fin.toLowerCase() : '') +
            result.heure_rdv.toLowerCase() +
            result.heure_debut.toLowerCase() +
            result.heure_fin.toLowerCase();

        const listeStades = ListeStades.find(itemFind => parseInt(itemFind.id) === parseInt(result.id_stade));
        const nomStade = listeStades ? listeStades.nom.toLowerCase() : '';
        const adresse = listeStades ? listeStades.adresse.toLowerCase() : '';
        const ville = listeStades ? listeStades.ville.toLowerCase() : '';
        const codePostal = listeStades ? listeStades.code_postal.toLowerCase() : '';
        const surfaceDeJeu = listeStades ? listeStades.surface_de_jeu.toLowerCase() : '';
        return (
            nomComplet.includes(Recherche.toLowerCase()) ||
            nomStade.includes(Recherche.toLowerCase()) ||
            adresse.includes(Recherche.toLowerCase()) ||
            ville.includes(Recherche.toLowerCase()) ||
            codePostal.includes(Recherche.toLowerCase()) ||
            surfaceDeJeu.includes(Recherche.toLowerCase())
        );
    });

    // Déplacez setNbElements ici
    useEffect(() => {
        setNbElements(filtered.length);
    }, [filtered]);

    return (
        <div className='entrainements-children'>
            <div>
                <div className='boutons-container'>
                    <BoutonAction content={"Ajouter un entrainement"} addClass={'bg-principal'} handleBoutonClick={handleBoutonClick} />
                </div>

                <div className='boutonRecherche-container'>
                    <BoutonRecherche recherche={Recherche} handleRechercheChange={handleRechercheChange} />
                </div>

                <div className='infosTuile-container'>{"Nb d'entrainement : " + NbElements}</div>
                <div className='tuile-container ' style={{ maxWidth: 'calc((20px * ' + NbElements + ') + (' + WidthTuiles + ' * ' + NbElements + '))' }}>
                    {ListeEntrainements.length > 0 ? (
                        filtered.map((item, index) => {

                            // RECUPERATION HORRAIRE ENTRAINEMENT
                            const date = new Date(item.date_debut);
                            const optionsDay = { day: 'numeric' };
                            const optionsMonth = { month: 'short' };
                            const formattedDateDay = date.toLocaleDateString('fr-FR', optionsDay).toUpperCase();
                            const formattedDateMonth = date.toLocaleDateString('fr-FR', optionsMonth).toUpperCase();

                            // RECUPERATION INFOS STADES
                            const infosStades = (ListeStades.filter(itemFilter => parseInt(itemFilter.id) === parseInt(item.id_stade)))
                            const stade = infosStades.length > 0 ? infosStades[0].nom : ''

                            // RECUPERATION INFOS GROUPES
                            let groupes = '';
                            const listeGroupesEntrainements = ListeGroupesEntrainements;

                            for (let index = 0; index < listeGroupesEntrainements.length; index++) {
                                const element = listeGroupesEntrainements[index].entrainements + ',';

                                if (element.includes(item.id + ',')) {
                                    if (groupes === '') {
                                        groupes = listeGroupesEntrainements[index].nom;
                                    } else {
                                        groupes = groupes + ', ' + listeGroupesEntrainements[index].nom;
                                    }
                                }
                            }

                            return (
                                <div className='key' key={index}>
                                    <TuileEntrainements
                                        var_handleOnClick={handleOnClick}
                                        var_id={item.id}
                                        var_date={[formattedDateDay + ' ' + formattedDateMonth]}
                                        var_heure={[item.heure_debut]}
                                        var_infos={[
                                            UcFirst(item.nom.toUpperCase()),
                                            'Description : ' + UcFirst(item.description.toLowerCase()),
                                            'Groupe : ' + groupes,
                                            ' - ',
                                            UcFirst(stade.toUpperCase()),
                                            UcFirst(item.heure_debut.toLowerCase()) + ' - ' + UcFirst(item.heure_fin.toLowerCase())
                                        ]}
                                        var_arrayOptions={['informations', 'présence']}
                                        var_handleOptionClick={handleOptionCacheClick}
                                        var_selectedId={SelectedId}
                                    />
                                </div>
                            )
                        })
                    ) : (
                        <div className='general-aucunElement'>Aucun élément trouvé.</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AfficherEntrainements;
