// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.general-cacherElement{
    display: none;
}

.general-desactiverClic{
  pointer-events: none;
  cursor: default;
}

.general-displayNone{
  display: none!important;
}

.general-aucunElement{
  width: 100%;
  text-align: center;
  position: absolute;
  margin-top: 10px;
}

.general-PleinEcran{
  height: 100vh;
}

/*//////////////
// RESPONSIVE //
//////////////*/

@media (max-width: 768px) {
    .general-responsiveCacherElement {
      display: none;
    }
  }

`, "",{"version":3,"sources":["webpack://./src/styles/General.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;EACE,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;;eAEe;;AAEf;IACI;MACE,aAAa;IACf;EACF","sourcesContent":[".general-cacherElement{\n    display: none;\n}\n\n.general-desactiverClic{\n  pointer-events: none;\n  cursor: default;\n}\n\n.general-displayNone{\n  display: none!important;\n}\n\n.general-aucunElement{\n  width: 100%;\n  text-align: center;\n  position: absolute;\n  margin-top: 10px;\n}\n\n.general-PleinEcran{\n  height: 100vh;\n}\n\n/*//////////////\n// RESPONSIVE //\n//////////////*/\n\n@media (max-width: 768px) {\n    .general-responsiveCacherElement {\n      display: none;\n    }\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
