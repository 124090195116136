import React from 'react';
import TuileStade from '../tuiles/tuilesStades';

function OngletStade({ infosStade, clubHote } ) {
    return (
        <div className='matchsStade-container'>
            <TuileStade nomClub={clubHote.nom} nomStade={infosStade.nom} adresse={infosStade.adresse} codePostal={infosStade.code_postal} ville={infosStade.ville} surfaceDeJeu={infosStade.surface_de_jeu} />
        </div>

    );
}

export default OngletStade;
