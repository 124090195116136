// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filtres-container{
    text-align: start;
}

.listeFiltre-container{
    display: grid;
    border-radius: 5px;
    padding: 10px;
    gap: 5px;
}

.listeFiltre-children select{
    width: 100%;
}

.aucunResultat{
    margin-top: 10px;
}

/*///////////////
// COMPOSITION //
///////////////*/

.selectionJoueur-container .listeFiltre-container{
    grid-template-columns: repeat(3, 1fr);
}

.selectionDirigeant-container .listeFiltre-container{
    grid-template-columns: repeat(1, 1fr);
}`, "",{"version":3,"sources":["webpack://./src/styles/Filtres.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;gBAEgB;;AAEhB;IACI,qCAAqC;AACzC;;AAEA;IACI,qCAAqC;AACzC","sourcesContent":[".filtres-container{\n    text-align: start;\n}\n\n.listeFiltre-container{\n    display: grid;\n    border-radius: 5px;\n    padding: 10px;\n    gap: 5px;\n}\n\n.listeFiltre-children select{\n    width: 100%;\n}\n\n.aucunResultat{\n    margin-top: 10px;\n}\n\n/*///////////////\n// COMPOSITION //\n///////////////*/\n\n.selectionJoueur-container .listeFiltre-container{\n    grid-template-columns: repeat(3, 1fr);\n}\n\n.selectionDirigeant-container .listeFiltre-container{\n    grid-template-columns: repeat(1, 1fr);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
