// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*/////////////////////////
// GROUPES ENTRAINEMENTS //
/////////////////////////*/

.groupesEntrainements-children {
    text-align: center;
}

.groupesEntrainements-children .groupes-container {
    padding-top: 10px;
}

.groupesEntrainements-children .groupes-children {
    border: solid;
}

/*//////////////////////////////////////
// INFORMATIONS GROUPES ENTRAINEMENTS //
//////////////////////////////////////*/

.informationsGroupesEntrainements-container .listValue-container {
    border: solid 2px purple;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.informationsGroupesEntrainements-container .joueur-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    border-radius: 5px;
    align-items: center;
    padding: 5px;
    gap: 5px;
}

.informationsGroupesEntrainements-container .joueur-container .photo {
    width: 60px;
    height: 60px;
}

.informationsGroupesEntrainements-container .joueur-container .photo img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.informationsGroupesEntrainements-container .joueur-container .infosJoueur {
}`, "",{"version":3,"sources":["webpack://./src/styles/Entrainements.css"],"names":[],"mappings":"AAAA;;0BAE0B;;AAE1B;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;;uCAEuC;;AAEvC;IACI,wBAAwB;IACxB,aAAa;IACb,qCAAqC;IACrC,SAAS;AACb;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,QAAQ;AACZ;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;AACA","sourcesContent":["/*/////////////////////////\n// GROUPES ENTRAINEMENTS //\n/////////////////////////*/\n\n.groupesEntrainements-children {\n    text-align: center;\n}\n\n.groupesEntrainements-children .groupes-container {\n    padding-top: 10px;\n}\n\n.groupesEntrainements-children .groupes-children {\n    border: solid;\n}\n\n/*//////////////////////////////////////\n// INFORMATIONS GROUPES ENTRAINEMENTS //\n//////////////////////////////////////*/\n\n.informationsGroupesEntrainements-container .listValue-container {\n    border: solid 2px purple;\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 10px;\n}\n\n.informationsGroupesEntrainements-container .joueur-container {\n    display: grid;\n    grid-template-columns: 1fr 2fr;\n    border-radius: 5px;\n    align-items: center;\n    padding: 5px;\n    gap: 5px;\n}\n\n.informationsGroupesEntrainements-container .joueur-container .photo {\n    width: 60px;\n    height: 60px;\n}\n\n.informationsGroupesEntrainements-container .joueur-container .photo img {\n    width: 100%;\n    height: 100%;\n    border-radius: 50%;\n}\n\n.informationsGroupesEntrainements-container .joueur-container .infosJoueur {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
