import React from 'react';
import UcFirst from '../fonctions/UcFirst';

const TuileMatch = ({ var_id, var_infos, var_date, var_heure, var_description, var_clubHote, var_clubVisiteur, var_score, var_resultat, var_handleOnClick, var_handleOptionClick, var_arrayOptions, var_selectedIdMatch }) => {

    return (
        <div className='tuile-children match bs-principal bg-secondaire ' onClick={var_handleOnClick} id={var_id}>
            <div id={var_id} className='description-container'>{var_description}</div>
            <div id={var_id} className='infos-container'>
                <div id={var_id} className='date-container'>
                    {var_date.map((item, index) => (
                        <div id={var_id} key={index} className="">
                            {item}
                        </div>
                    ))}
                    <hr />
                    <div id={var_id}>{var_heure}</div>
                </div>

                <div id={var_id} className='infos'>
                    <div id={var_id} className='tableauDesScores-container'>
                        <div id={var_id} className='photo-container'>
                            <img id={var_id} src={var_clubHote.photo} alt="hote" />
                            <div id={var_id}>{var_clubHote.nom}</div>
                        </div>
                        <div id={var_id} className='score-container'>
                            <div id={var_id} className='score'>{var_score}</div>
                            <div id={var_id} className={'resultat bg-' + var_resultat}>{UcFirst(var_resultat)}</div>
                        </div>
                        <div id={var_id} className='photo-container'>
                            <img id={var_id} src={var_clubVisiteur.photo} alt="hote" />
                            <div id={var_id}>{var_clubVisiteur.nom}</div>
                        </div>
                    </div>

                    {var_infos.map((item, index) => (
                        item === "Inactive" ? (
                            <div id={var_id} key={index} style={{ display: 'flex' }}>
                                <div id={var_id} style={{ marginRight: '3px' }}>Licence : </div>
                                <div id={var_id} key={index} className="cl-licenceInactive">{item}</div>
                            </div>
                        ) : item === "Active" ? (
                            <div id={var_id} key={index} style={{ display: 'flex' }}>
                                <div id={var_id} style={{ marginRight: '3px' }}>Licence : </div>
                                <div id={var_id} key={index} className="cl-licenceActive">{item}</div>
                            </div>
                        ) : (
                            <div id={var_id} key={index} className="">
                                {item}
                            </div>
                        )
                    ))}
                </div>
            </div>

            <div className={parseInt(var_selectedIdMatch) !== parseInt(var_id) ? 'general-displayNone' : 'optionCache-container bg-modalFont'}>
                {var_arrayOptions.map((item, index) => (
                    <button id={var_id} value={item} key={index} className='bg-principal' onClick={var_handleOptionClick}>
                        {UcFirst(item.toLowerCase())}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default TuileMatch;
