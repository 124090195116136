function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();    
    
    const timeString = `${hours}:${minutes}:${seconds}`;
    return timeString;
}

export default getCurrentTime;