import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
////////////////////////////////////////////////////////////////////////////////////////
import CalculateMinutesDiff from '../fonctions/CalculateMinutesDiff';
import getCurrentTime from '../fonctions/getCurrentTime';
import UcFirst from '../fonctions/UcFirst';
import Navbar from '../fonctions/Navbar';
import BoutonsStatsMatchs from './BoutonsStatsMatchs'
import OngletResume from './OngletResume'
import OngletStade from './OngletStade'
import OngletStats from './OngletStats'
import OngletClassement from './OngletClassement'
import OngletComposition from './OngletComposition'
////////////////////////////////////////////////////////////////////////////////////////
import { getMatchsInfosByIdMatch } from '../../api/matchs/getMatchsInfosByIdMatch';
import { getMatchsById } from '../../api/matchs/getMatchsById';
import { getCategoriesById } from '../../api/categories/getCategoriesById';
import { getCompetitionsById } from '../../api/competitions/getCompetitionsById';
import { getClubsById } from '../../api/clubs/getClubsById';
import { getStadesById } from '../../api/stades/getStadesById';
/////////////////////////////////////////////////////////////////////////////////////////
import { updateMatchsInfos } from '../../api/matchs/updateMatchsInfos';
import { updateMatchsScoresById } from '../../api/matchs/updateMatchsScoresById';
/////////////////////////////////////////////////////////////////////////////////////////
import Modal from '../Modal'
import ModalOuiNon from '../modal/ModalOuiNon';
import { useNavigate } from 'react-router-dom';
import globalStorage from '../fonctions/GlobalStorage';

function MatchEnCours() {
    // RECUPERATION ID MATCH
    const location = useLocation();
    const currentURL = location.pathname;
    const parts = currentURL.split("/"); // Divise la chaîne en segments en utilisant le séparateur "/"
    const valeur = parts.pop(); // Récupère le dernier élément du tableau
    const idMatch = valeur.replace(/%20/g, " "); // Remplace les %20 en espace

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // VARIABLES

    // GLOBAL STORAGE
    const {gsSetTitreHeaderSecondaire} = globalStorage();

    const [MatchsInfosByIdMatch, setMatchsInfosByIdMatch] = useState([]);
    const [HeureDebutChrono, setHeureDebutChrono] = useState([]);
    const [MatchByIdMatch, setMatchByIdMatch] = useState([]);
    const [Categorie, setCategorie] = useState("");
    const [Competition, setCompetition] = useState("");
    //const [Adversaire, setAdversaire] = useState("");
    const [InfosStade, setInfosStade] = useState([]);
    const [ClubHote, setClubHote] = useState([]);
    const [ClubVisiteur, setClubVisiteur] = useState([]);
    const [Menu, setMenu] = useState(["maj", "résumé", "stats", "compos", "stade", "classement"])
    const [selectedMenu, setSelectedMenu] = useState(Menu[0]);
    const [menuContent, setMenuContent] = useState([]);
    const ChronoRef = useRef(null);

    const [ShowModal, setShowModal] = useState(false);
    const [ContentModal, setContentModal] = useState(false);
    const [InfosModal, setInfosModal] = useState(false);
    const [ReloadPage, setReloadPage] = useState(false);
    const navigate = useNavigate();


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // FONCTION
    const fetchId = useCallback(async () => {
        setInfosModal()

        // RECUPERATION Infos 
        const matchsInfos = await getMatchsInfosByIdMatch(idMatch);
        let heureDebutChrono = "";
        setMatchsInfosByIdMatch(...matchsInfos);
        setHeureDebutChrono(matchsInfos[0].debut_mi_temps)
        heureDebutChrono = matchsInfos[0].debut_mi_temps

        // RECUPERATION MATCHS
        const matchs = await getMatchsById(idMatch);
        setMatchByIdMatch(matchs);
        //console.log(matchs[0].id_categorie)

        // RECUPERATION CATEGORIE
        const categorie = await getCategoriesById(matchs[0].id_categorie);
        setCategorie(categorie[0].nom);

        // RECUPERATION COMPETITION
        const competition = await getCompetitionsById(matchs[0].id_competition);
        setCompetition(competition[0].nom);
        //console.log(competition[0].nom)

        // RECUPERATION STADE
        const infosStade = await getStadesById(matchs[0].id_stade);
        setInfosStade(...infosStade);
        //console.log(infosStade)

        // RECUPERATION ADVERSAIRE
        const adversaire = await getClubsById(matchs[0].id_adversaire);
        //setAdversaire(...adversaire);
        //console.log(adversaire)

        // RECUPERATION CLUB PRINCIPALE
        const clubPrincipal = await getClubsById(1);
        //console.log(clubPrincipal)

        // RECUPERATION CLUB HOTE ET CLUB VISITEUR
        if (matchs[0].emplacement === "Extérieur") {
            setClubHote(...adversaire);
            setClubVisiteur(...clubPrincipal);
            setMenuContent(
                <BoutonsStatsMatchs
                    fetchId={fetchId}
                    clubHote={adversaire[0]}
                    clubVisiteur={clubPrincipal[0]}
                    idMatch={idMatch}
                    heureDebutChrono={heureDebutChrono}
                    miTemps={matchsInfos[0].mi_temps}
                    dureeMiTemps={matchs[0].duree_mi_temps}
                    nbMitemps={matchs[0].nb_mi_temps}
                    nbProlongation={matchs[0].nb_prolongation}
                    dureeProlongation={matchs[0].duree_prolongation}
                    scoreHote={matchsInfos[0].score_hote}
                    scoreVisiteur={matchsInfos[0].score_visiteur}
                />
            )

            // EDIT TITRE HEADER
            gsSetTitreHeaderSecondaire(adversaire[0].acronyme+' vs '+clubPrincipal[0].acronyme)
        } else {
            setClubHote(...clubPrincipal);
            setClubVisiteur(...adversaire);
            setMenuContent(
                <BoutonsStatsMatchs
                    fetchId={fetchId}
                    clubHote={clubPrincipal[0]}
                    clubVisiteur={adversaire[0]}
                    idMatch={idMatch}
                    heureDebutChrono={heureDebutChrono}
                    miTemps={matchsInfos[0].mi_temps}
                    dureeMiTemps={matchs[0].duree_mi_temps}
                    nbMitemps={matchs[0].nb_mi_temps}
                    nbProlongation={matchs[0].nb_prolongation}
                    dureeProlongation={matchs[0].duree_prolongation}
                    scoreHote={matchsInfos[0].score_hote}
                    scoreVisiteur={matchsInfos[0].score_visiteur}
                />
            )

            // EDIT TITRE HEADER
            gsSetTitreHeaderSecondaire(clubPrincipal[0].acronyme+' vs '+adversaire[0].acronyme)
        }

        // MODIFICATION ELEMENT EN FONCTION DU STATUT MTCH
        if (matchsInfos[0].statut_match === "termine") {
            setMenu(["résumé", "stats", "compos", "stade", "classement"])
            setMenuContent(<OngletResume idClubHote={adversaire[0].id} dureeMiTemps={matchs[0].duree_mi_temps} dureeProlongation={matchs[0].duree_prolongation} />);
            setSelectedMenu("résumé")
        }
    }, [idMatch, gsSetTitreHeaderSecondaire]);

    useEffect(() => {
        fetchId();
    }, [fetchId]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // ACTUALISATION DE L'HEURE ACTUELLE
    const [currentTime, setCurrentTime] = useState(getCurrentTime());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(getCurrentTime());
        }, 10000); // Actualise toutes les 10 secondes (10000 millisecondes)

        return () => {
            clearInterval(interval); // Nettoie l'intervalle lors du démontage du composant
        };
    }, []);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // CLIC SUR LE MENU
    const handleMenuClick = (menuName) => {
        setSelectedMenu(menuName);

        // Modifier le contenu en fonction du menu sélectionné
        if (menuName === "maj") {
            setMenuContent(
                <BoutonsStatsMatchs
                    fetchId={fetchId}
                    clubHote={ClubHote}
                    clubVisiteur={ClubVisiteur}
                    idMatch={idMatch}
                    heureDebutChrono={HeureDebutChrono}
                    miTemps={MatchsInfosByIdMatch.mi_temps}
                    dureeMiTemps={MatchByIdMatch[0].duree_mi_temps}
                    nbMitemps={MatchByIdMatch[0].nb_mi_temps}
                    dureeProlongation={MatchByIdMatch[0].duree_prolongation}
                    nbProlongation={MatchByIdMatch[0].nb_prolongation}
                    scoreHote={MatchsInfosByIdMatch.score_hote}
                    scoreVisiteur={MatchsInfosByIdMatch.score_visiteur}
                />
            );
        } else if (
            menuName === "résumé") {
            setMenuContent(
                <OngletResume
                    idClubHote={ClubHote.id}
                    dureeMiTemps={MatchByIdMatch[0].duree_mi_temps}
                    dureeProlongation={MatchByIdMatch[0].duree_prolongation}
                />
            );
        } else if (menuName === "stats") {
            setMenuContent(
                <OngletStats
                    idClubHote={ClubHote.id}
                    idClubVisiteur={ClubVisiteur.id}
                />
            );
        } else if (menuName === "compos") {
            setMenuContent(
                <OngletComposition
                />
            );
        } else if (menuName === "classement") {
            setMenuContent(
                <OngletClassement
                />
            );
        } else if (menuName === "stade") {
            setMenuContent(
                <OngletStade
                    clubHote={ClubHote}
                    infosStade={InfosStade}
                />
            );
        } else {
            setMenuContent("");
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // CLIC BOUTONS ATIONS MATCH
    async function handleActionMatchClick(e) {
        let updateBdd;
        let mi_temps;
        let numMiTemps = parseInt(MatchsInfosByIdMatch.mi_temps.charAt(0), 10);
        let nbMiTemps = MatchByIdMatch[0].nb_mi_temps;

        if (numMiTemps < nbMiTemps) {
            if (e.target.textContent === "TERMINER MI-TEMPS") {
                updateBdd = await updateMatchsInfos(
                    MatchsInfosByIdMatch.id_match,
                    MatchsInfosByIdMatch.debut_match,
                    MatchsInfosByIdMatch.debut_mi_temps,
                    MatchsInfosByIdMatch.fin_match,
                    MatchsInfosByIdMatch.mi_temps,
                    'mi-temps',
                    'demarrer mi-temps',
                    MatchsInfosByIdMatch.score_hote,
                    MatchsInfosByIdMatch.score_visiteur
                );
            } else if (e.target.textContent === "DEMARRER MI-TEMPS") {
                numMiTemps = numMiTemps + 1
                if (numMiTemps > 1) {
                    mi_temps = numMiTemps + "ème mi-temps"
                } else {
                    mi_temps = numMiTemps + "ère mi-temps"
                }
                updateBdd = await updateMatchsInfos(
                    MatchsInfosByIdMatch.id_match,
                    MatchsInfosByIdMatch.debut_match, getCurrentTime(),
                    MatchsInfosByIdMatch.fin_match, mi_temps,
                    'en cours',
                    'terminer mi-temps',
                    MatchsInfosByIdMatch.score_hote,
                    MatchsInfosByIdMatch.score_visiteur
                );
                console.log(updateBdd)
            }
        } else {
            if (e.target.textContent.toLowerCase() === "démarrer prolongation") {
                console.log("ICI")

                let nbProlongation = MatchByIdMatch[0].nb_prolongation;
                console.log(nbProlongation)



            } else {
                const affichageModal = 'Etes vous sur de vouloir arrêter le match ?';
                // AFFICHER MODAL
                setShowModal(true)
                setContentModal(
                    <ModalOuiNon handleClick={handleOuiNonClick} affichage={affichageModal} infos={MatchByIdMatch[0].nom} var_name={'arrêt match'} var_id={idMatch} />
                )
                setReloadPage(false)
            }
        }
        fetchId()
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    async function handleReloadPageClick(e) {
        if (ReloadPage === true) {
            navigate('/Matchs');
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    async function handleOuiNonClick(e) {
        const value = e.target.value

        if (value === 'oui') {
            let updateBdd;
            let scoreNul = false

            if (MatchsInfosByIdMatch.score_hote === MatchsInfosByIdMatch.score_visiteur) {
                scoreNul = true
            }

            updateBdd = await updateMatchsInfos(
                MatchsInfosByIdMatch.id_match,
                MatchsInfosByIdMatch.debut_match,
                MatchsInfosByIdMatch.debut_mi_temps,
                MatchsInfosByIdMatch.fin_match,
                MatchByIdMatch[0].nb_prolongation !== null && scoreNul === true ? 'prolongation' : '',
                MatchByIdMatch[0].nb_prolongation !== null && scoreNul === true ? 'prolongation' : 'termine',
                MatchByIdMatch[0].nb_prolongation !== null && scoreNul === true ? 'démarrer prolongation' : '',
                MatchsInfosByIdMatch.score_hote,
                MatchsInfosByIdMatch.score_visiteur
            );
            console.log(updateBdd)

            if ((MatchByIdMatch[0].nb_prolongation === null || (MatchByIdMatch[0].nb_prolongation !== null && scoreNul === false))) {
                // MAJ SCORE MATCH
                let resultat;

                if (MatchByIdMatch[0].emplacement === "Domicile") {
                    if (MatchsInfosByIdMatch.score_hote > MatchsInfosByIdMatch.score_visiteur) {
                        resultat = "victoire"
                    } else {
                        resultat = "defaite"
                    }
                } else {
                    if (MatchsInfosByIdMatch.score_hote < MatchsInfosByIdMatch.score_visiteur) {
                        resultat = "victoire"
                    } else {
                        resultat = "defaite"
                    }
                }

                if (MatchsInfosByIdMatch.score_hote === MatchsInfosByIdMatch.score_visiteur) {
                    resultat = "nul"
                }

                updateBdd = await updateMatchsScoresById(
                    MatchsInfosByIdMatch.id_match,
                    resultat,
                    MatchsInfosByIdMatch.score_hote + ' - ' + MatchsInfosByIdMatch.score_visiteur
                );
                console.log(updateBdd)
            }
            fetchId()
            setShowModal(false)
        }else{
            setShowModal(false)
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className='matchEnCours-children'>
            {MatchByIdMatch.map((item, index) => (
                <div key={index}>
                    <div className='descriptionMatch-container'>
                        {Competition} - {UcFirst(Categorie)} : {item.description}
                    </div>

                    <div className='tableauScore-container'>
                        <div className='tableauScore-children'>
                            <div><img src={ClubHote.photo} alt="Logo" /></div>
                            <div className='nomClub'>{ClubHote.nom}</div>
                        </div>
                        <div className='tableauScore-children'>
                            <div className='date'>{item.date.replace(/-/g, '/')} - {item.heure_match}</div>
                            <div className='score'>{MatchsInfosByIdMatch.score_hote} - {MatchsInfosByIdMatch.score_visiteur}</div>
                            <div>{MatchsInfosByIdMatch.statut_match === "en cours" ? MatchsInfosByIdMatch.mi_temps : ''}</div>
                            <div
                                className='chrono'
                                ref={ChronoRef}
                                style={{
                                    color: MatchsInfosByIdMatch.statut_match !== "en cours"
                                        ? 'red'
                                        : ''
                                }}
                            >
                                {MatchsInfosByIdMatch.statut_match === "termine" || MatchsInfosByIdMatch.statut_match === "mi-temps" || MatchsInfosByIdMatch.statut_match === "prolongation"
                                    ? MatchsInfosByIdMatch.statut_match.toUpperCase()
                                    : CalculateMinutesDiff(
                                        HeureDebutChrono,
                                        currentTime,
                                        MatchsInfosByIdMatch.mi_temps,
                                        item.duree_mi_temps,
                                        item.nb_mi_temps,
                                        item.nb_prolongation,
                                        item.duree_prolongation
                                    )
                                }
                            </div>
                        </div>
                        <div className='tableauScore-children'>
                            <div><img src={ClubVisiteur.photo} alt="Logo" /></div>
                            <div className='nomClub'>{ClubVisiteur.nom}</div>
                        </div>
                    </div>

                    <div className='boutonsActionMatch-container'>

                        <div
                            className={MatchsInfosByIdMatch.statut_match === 'termine' ? 'general-displayNone' : 'bg-principal'}
                            onClick={(e) => handleActionMatchClick(e)}
                        >
                            {MatchsInfosByIdMatch.bouton_match.toLocaleUpperCase()}
                        </div>

                    </div>

                    <Navbar menus={Menu} onClick={handleMenuClick} selectedMenu={selectedMenu} />
                    <div className='menu-container'>
                        {menuContent}
                    </div>
                </div>
            ))}

            <Modal
                showModal={ShowModal}
                contentModal={ContentModal}
                handleCloseModal={() => {
                    setShowModal(false);
                    setContentModal("");
                    handleReloadPageClick();
                }}
                infosModal={InfosModal}
            />
        </div>
    );
}

export default MatchEnCours;
