import React from 'react';

function BoutonsRetourArriere({ handleBoutonsStatsClick, nomBouton }) {

    return (
        <div className='boutonRetourArriere-container'>
            <div
                onClick={() => handleBoutonsStatsClick('retour')}
            >
                <i className="fa-solid fa-xmark"></i> Annuler
            </div>
        </div>
    );
}

export default BoutonsRetourArriere;
