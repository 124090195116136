// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boutonAction-container{
    display: inline-block;
    border-radius: 5px;
    padding: 8px;
    box-shadow: 3px 3px 5px rgb(114, 114, 114);
    cursor: pointer;
    margin-top: 30px;
}

.boutonRecherche-container{
    padding-top: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.boutonItineraire-container{
    display: inline-block;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles/Boutons.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,YAAY;IACZ,0CAA0C;IAC1C,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".boutonAction-container{\n    display: inline-block;\n    border-radius: 5px;\n    padding: 8px;\n    box-shadow: 3px 3px 5px rgb(114, 114, 114);\n    cursor: pointer;\n    margin-top: 30px;\n}\n\n.boutonRecherche-container{\n    padding-top: 5px;\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n\n.boutonItineraire-container{\n    display: inline-block;\n    border-radius: 5px;\n    padding: 5px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
