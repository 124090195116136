import React, { useState, useEffect, createContext } from 'react';
import { getSettings } from '../../api/settings/getSettings';

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState([
    { 
      name: 'COULEUR_MENU', value: '' 
    },
    { 
      name: 'COULEUR_BODY', value: '' 
    }
  ]);

  useEffect(() => {
    async function getSettingsLoad(){
        const settings = await getSettings();
        setSettings(settings);
    } 

    getSettingsLoad();
  }, []);

  return (
    <SettingsContext.Provider value={{ settings }}>
      {children}
    </SettingsContext.Provider>
  );
};
