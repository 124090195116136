import React, { useContext, useEffect, useState, useCallback } from 'react';
import { SettingsContext } from './settings/RecuperationSettings';
import { useNavigate } from 'react-router-dom';
import { getHome } from '../api/home/getHome';
import globalStorage from './fonctions/GlobalStorage';

const HeaderSecondaire = (props) => {
  ///////////////
  // VARIABLES //
  ///////////////

  // RETOUR ARRIERE
  const navigate = useNavigate();

  // GERER LA VISIBILITE DU TEST DE L'HeaderSecondaire
  const [VisibilityHeaderSecondaire] = useState(props.visibility);
  const [ListeHome, setListeHome] = useState([]);

  // GLOBAL STORAGE
  const { gsTitreHeaderSecondaire } = globalStorage();

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////
  // SETTINGS //
  //////////////

  const { settings } = useContext(SettingsContext);
  let NomSitePolice = '';

  if (Array.isArray(settings)) {
    const settingsNomSitePolice = settings.find((setting) => setting.name === 'NOM_SITE_POLICE');
    NomSitePolice = settingsNomSitePolice?.value || '';
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////
  // FONCTIONS //
  ///////////////

  const fetch = useCallback(async () => {
    let listeHome = await getHome();
    setListeHome(listeHome);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  // CHANGER LA TAILLE DU HEADER
  useEffect(() => {
    // CHANGER GRID TEMPLATE ROW SITE-CONTAINER
    document.documentElement.style.setProperty('--gridTemplateRows-siteContainer', '30px 1fr 66px');
  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////
  // HANDLE CLICK //
  //////////////////

  async function handleOnClick(e) {
    if (ListeHome.map(item => item.name.toLowerCase()).includes(e.target.id.toLowerCase()) || e.target.id.toLowerCase() === 'groupes entrainements') {
      navigate('/')
    } else {
      navigate(-1)
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="headerSecondaire-container bg-principal site-children">
      <div className='headerSecondaire-children left' onClick={handleOnClick}>
        <i id={props.headerSecondaire} className="fa-solid fa-arrow-left bg-principal"></i>
      </div>
      <div className={`headerSecondaire-children right bg-principal ${VisibilityHeaderSecondaire === "none" ? 'desactive' : ''}`} style={{ fontFamily: NomSitePolice }}>
        {gsTitreHeaderSecondaire}
      </div>
    </div>
  );
};

export default HeaderSecondaire;
