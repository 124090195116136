import axios from "axios";

export async function updateMatchsScoresById(id, resultat, score) {
    try{
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}updateMatchsScoresById/?id=${id}&resultat=${resultat}&score=${score}`)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `Update Matchs [${id} - ${score}] in database : SUCCES !`
            return msg;
        } 
    } catch (error) {
        console.log(error);
    }
}
