import React from 'react';

function Navbar({ menus, onClick, selectedMenu }) {

    const handleClick = (event, menuName) => {
        event.preventDefault();
        onClick(menuName);
    };

    return (
        <div className='navbar-container'>
            {menus.map((menu, index) => (
                <div
                    key={index}
                    onClick={(event) => handleClick(event, menu)}
                    className={selectedMenu === menu ? 'selected' : ''}
                >
                    {menu.toUpperCase()}
                </div>
            ))}
        </div>
    );
}

export default Navbar;
