import React from 'react';

function BoutonAction({ content, addClass, handleBoutonClick }) {
    return (
        <div
            className={'boutonAction-container ' + addClass}
            onClick={handleBoutonClick}
        >
            {content.toUpperCase()}
        </div>
    );
}

export default BoutonAction;
