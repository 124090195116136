import React, { useContext, useEffect, useState, useCallback } from 'react';
import { SettingsContext } from '../settings/RecuperationSettings';
import BoutonAction from '../boutons/boutonAction';
import { getClubs } from '../../api/clubs/getClubs';
import Tuile from '../tuiles/tuiles';
import { useNavigate, useLocation } from 'react-router-dom';
import BoutonRecherche from '../boutons/boutonRecherche';
import globalStorage from '../fonctions/GlobalStorage';

function AfficherClubs() {

    ///////////////
    // VARIABLES //
    ///////////////
    
    // GLOBAL STORAGE
    const {gsSetTitreHeaderSecondaire} = globalStorage();

    const [Clubs, setClubs] = useState([]);
    const [NbElements, setNbElements] = useState([]);
    const navigate = useNavigate(); // Utilisez useNavigate

    // RECUPERER PARAMETRE DE RECHERCHE
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const UrlRecherche = searchParams.get('recherche') || ''; // Obtenez la valeur de recherche de la chaîne de requête

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////
    // SETTINGS //
    //////////////

    const { settings } = useContext(SettingsContext);
    let WidthTuiles = '';

    if (Array.isArray(settings)) {
        const settingsWidthTuiles = settings.find((setting) => setting.name === 'WIDTH_TUILES');
        WidthTuiles = settingsWidthTuiles?.value || '';
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    const fetch = useCallback(async () => {
        let clubs = await getClubs();
        clubs = clubs.filter(item => item.statut !== 'supprimé')
        setClubs(clubs);
    }, []);

    useEffect(() => {
        fetch();

        // EDIT TITRE HEADER
        gsSetTitreHeaderSecondaire('Clubs')
    }, [fetch,gsSetTitreHeaderSecondaire]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////
    // HANDLE CLICK //
    //////////////////

    const handleBoutonClick = (e) => {
        navigate("ajouter")
    };

    async function handleOnClick(e) {
        const id = e.target.id;

        if (Recherche !== '') {
            navigate(`?recherche=${Recherche}`);
        }

        navigate(id);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////
    // FILTRES //
    /////////////

    const [Recherche, setRecherche] = useState(UrlRecherche);

    const handleRechercheChange = (e) => {
        setRecherche(e.target.value);
        if (e.target.value === '') {
            navigate('')
        }
    };

    const filtered = Clubs.filter((result) => {
        const nomComplet =
            result.nom.toLowerCase() +
            '' +
            result.acronyme.toLowerCase();
        return (
            nomComplet.includes(Recherche.toLowerCase())
        );
    });

    // Déplacez setNbElements ici
    useEffect(() => {
        setNbElements(filtered.length);
    }, [filtered]);

    return (
        <div className='clubs-children'>
            <div>
                <div className='boutons-container'>
                    <BoutonAction content={"Ajouter un club"} addClass={'bg-principal'} handleBoutonClick={handleBoutonClick} />
                </div>

                <div className='boutonRecherche-container'>
                    <BoutonRecherche recherche={Recherche} handleRechercheChange={handleRechercheChange} />
                </div>

                <div className='infosTuile-container'>{'Nb de clubs : ' + NbElements}</div>
                <div className='tuile-container ' style={{ maxWidth: 'calc((20px * ' + NbElements + ') + (' + WidthTuiles + ' * ' + NbElements + '))' }}>
                    {Clubs.length > 0 ? (
                        filtered.map((item, index) => {
                            return (
                                <div className='key' key={index}>
                                    <Tuile var_handleOnClick={handleOnClick} var_id={item.id} var_image={item.photo} var_infos={[item.nom.toUpperCase()]} />
                                </div>
                            )
                        })
                    ) : (
                        <div className='general-aucunElement'>Aucun élément trouvé.</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AfficherClubs;
