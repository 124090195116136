import axios from "axios";

export async function insertMatchsStats(id_match, id_club, nom, mi_temps, chrono, acteur_primaire, acteur_secondaire, score) {
    try {
        let apiUrl = `${process.env.REACT_APP_API_URL}insertMatchsStats/?id_match=${id_match}&nom=${nom}`;

        if (id_club && id_club !== null) {
            apiUrl += `&id_club=${id_club}`;
        }
        if (chrono && chrono !== null) {
            apiUrl += `&chrono=${chrono}`;
        }
        if (mi_temps && mi_temps !== null) {
            apiUrl += `&mi_temps=${mi_temps}`;
        }
        if (score && score !== null) {
            apiUrl += `&score=${score}`;
        }
        if (acteur_primaire && acteur_primaire !== null) {
            apiUrl += `&acteur_primaire=${acteur_primaire}`;
        }
        if (acteur_secondaire && acteur_secondaire !== null) {
            apiUrl += `&acteur_secondaire=${acteur_secondaire}`;
        }

        const { data } = await axios.get(apiUrl);

        if (JSON.stringify(data.affectedRows)) {
            const msg = `Insert [${nom}] in MatchsStats where idMatch = [${id_match}] : SUCCES !`
            return msg;
        }
    } catch (error) {
        console.log(error);
    }
}
