import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
////////////////////////////////////////////////////////////////////////////////////////
import { getMatchsStats } from '../../api/matchs/getMatchsStats';
////////////////////////////////////////////////////////////////////////////////////////
import UcFirst from '../fonctions/UcFirst';

function OngletStats(props) {
    // RECUPERATION ID MATCH
    const location = useLocation();
    const currentURL = location.pathname;
    const parts = currentURL.split("/"); // Divise la chaîne en segments en utilisant le séparateur "/"
    const valeur = parts.pop(); // Récupère le dernier élément du tableau
    const idMatch = valeur.replace(/%20/g, " "); // Remplace les %20 en espace

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // VARIABLES
    const [MatchsStatsByIdMatch, setMatchsStatsByIdMatch] = useState([]);
    const [uniqueNoms, setUniqueNoms] = useState([]);

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // FONCTIONS
    const extractUniqueNoms = useCallback((matchStats) => {
        // Use a Set to store unique "nom" values
        const uniqueNomsSet = new Set();

        matchStats.forEach((item) => {
            uniqueNomsSet.add(item.nom);
        });

        // Convert the Set back to an array
        const uniqueNomsArray = Array.from(uniqueNomsSet);

        // Sort the unique "nom" values alphabetically
        uniqueNomsArray.sort((a, b) => a.localeCompare(b));

        // Now you can set the sorted unique "nom" values to the state
        setUniqueNoms(uniqueNomsArray);
    }, []);


    const fetchMatchsStatsByIdMatch = useCallback(async () => {
        let matchsStatsByIdMatch = await getMatchsStats(idMatch);
        matchsStatsByIdMatch = matchsStatsByIdMatch.filter(itemFilter => itemFilter.id_match === parseInt(idMatch))
        setMatchsStatsByIdMatch(matchsStatsByIdMatch);
        extractUniqueNoms(matchsStatsByIdMatch); // Call the new function to get unique "nom" values
    }, [idMatch, extractUniqueNoms]);

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // USE EFFECT
    useEffect(() => {
        fetchMatchsStatsByIdMatch();
    }, [fetchMatchsStatsByIdMatch]);

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // Compter le nombre de statistiques pour le club hôte et le club visiteur
    const statsClubHote = (nom) => MatchsStatsByIdMatch.filter(item => item.nom === nom && item.id_club === props.idClubHote).length || 0;
    const statsClubVisiteur = (nom) => MatchsStatsByIdMatch.filter(item => item.nom === nom && item.id_club === props.idClubVisiteur).length || 0;

    return (
        <div className='matchsOngletStats-container'>
            {uniqueNoms.length > 0 ? (
                uniqueNoms.map((nom, index) => (
                    <div key={index} className='matchsOngletStats-children'>
                        {nom !== 'buts' ? (
                            <div>
                                <div className='stats-container'>
                                    <div className='statsClubHote'>
                                        {statsClubHote(nom)}
                                    </div>
                                    <div className='nomStat'>{UcFirst(nom)}</div>
                                    <div className='statsClubVisiteur'>
                                        {(statsClubVisiteur(nom))}
                                    </div>
                                    <div />
                                </div>
                                <div className='diagramme-container'>
                                    <div
                                        className='clubs-container clubHote'
                                        style={{ gridTemplateColumns: `${statsClubVisiteur(nom)}fr  ${statsClubHote(nom)}fr` }}
                                    >
                                        <div className='clubInactif'></div>
                                        <div
                                            className='clubActif'
                                            style={{
                                                backgroundColor: statsClubHote(nom) >= statsClubVisiteur(nom) ? 'red' : 'blue'
                                            }}
                                        ></div>
                                    </div>
                                    <div
                                        className='clubs-container clubVisiteur'
                                        style={{ gridTemplateColumns: `${statsClubVisiteur(nom)}fr  ${statsClubHote(nom)}fr` }}
                                    >
                                        <div
                                            className='clubActif'
                                            style={{
                                                backgroundColor: statsClubVisiteur(nom) >= statsClubHote(nom) ? 'red' : 'blue'
                                            }}
                                        ></div>
                                        <div className='clubInactif'></div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className='stats-container'>
                                    <div className='statsClubHote'>                                        
                                        {statsClubHote(nom) + statsClubHote("penalty réussis")}
                                    </div>
                                    <div className='nomStat'>{UcFirst(nom)}</div>
                                    <div className='statsClubVisiteur'>
                                        {statsClubVisiteur(nom) + statsClubVisiteur("penalty réussis")}
                                    </div>
                                    <div />
                                </div>
                                <div className='diagramme-container'>
                                    <div
                                        className='clubs-container clubHote'
                                        style={{ gridTemplateColumns: `${statsClubVisiteur(nom) + statsClubVisiteur("penalty réussis")}fr  ${statsClubHote(nom) + statsClubHote("penalty réussis")}fr` }}
                                    >
                                        <div className='clubInactif'></div>
                                        <div
                                            className='clubActif'
                                            style={{
                                                backgroundColor: statsClubHote(nom) + statsClubHote("penalty réussis") >= statsClubVisiteur(nom) + statsClubVisiteur("penalty réussis") ? 'red' : 'blue'
                                            }}
                                        ></div>
                                    </div>
                                    <div
                                        className='clubs-container clubVisiteur'
                                        style={{ gridTemplateColumns: `${statsClubVisiteur(nom) + statsClubVisiteur("penalty réussis")}fr  ${statsClubHote(nom) + statsClubHote("penalty réussis") + statsClubHote("penalty réussis")}fr` }}
                                    >
                                        <div
                                            className='clubActif'
                                            style={{
                                                backgroundColor: statsClubVisiteur(nom) + statsClubVisiteur("penalty réussis") >= statsClubHote(nom) + statsClubHote("penalty réussis") ? 'red' : 'blue'
                                            }}
                                        ></div>
                                        <div className='clubInactif'></div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <div style={{ textAlign: 'center' }}>Aucun élément trouvé.</div>
            )
            }
        </div >
    );
}

export default OngletStats;
