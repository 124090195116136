import React, { useState, useEffect } from 'react';
import UcFirst from '../fonctions/UcFirst';

function FormInputText({ var_nom, var_required, var_class, var_value, var_readOnly }) {
    return (

        <div className='inputText-container' style={{ gridArea: var_nom }}>
            <label>{UcFirst(var_nom)} : </label>
            <input type='text' readOnly={var_readOnly} className={var_class} name={var_nom} placeholder={UcFirst(var_nom)} required={var_required} defaultValue={var_value} />
        </div>
    );
}

function FormInputDate({ var_nom, var_required, var_class, var_value, var_readOnly, var_min, var_max }) {
    return (
        <div className='inputDate-container' style={{ gridArea: var_nom }}>
            <label>{UcFirst(var_nom)} : </label>
            <input
                type='date'
                readOnly={var_readOnly}
                className={var_class}
                name={var_nom}
                placeholder={UcFirst(var_nom)}
                required={var_required}
                defaultValue={var_value}
                min={var_min}
                max={var_max}
            />
        </div>
    );
}

function FormInputHeure({ var_nom, var_required, var_class, var_value, var_readOnly, var_min, var_max }) {
    return (
        <div className='inputHeure-container' style={{ gridArea: var_nom }}>
            <label>{UcFirst(var_nom)} : </label>
            <input
                type='time'
                readOnly={var_readOnly}
                className={var_class}
                name={var_nom}
                placeholder={UcFirst(var_nom)}
                required={var_required}
                defaultValue={var_value}
                min={var_min}
                max={var_max}
            />
        </div>
    );
}

function FormInputEmail({ var_nom, var_required, var_class, var_value, var_readOnly }) {
    return (
        <div className='inputEmail-container' style={{ gridArea: var_nom }}>
            <label>{UcFirst(var_nom)} : </label>
            <input type='email' readOnly={var_readOnly} className={var_class} name={var_nom} placeholder={UcFirst(var_nom)} required={var_required} defaultValue={var_value} />
        </div>
    );
}

function FormInputTel({ var_nom, var_required, var_class, var_value, var_readOnly }) {
    return (
        <div className='inputTel-container' style={{ gridArea: var_nom }}>
            <label>{UcFirst(var_nom)} : </label>
            <input type='tel' readOnly={var_readOnly} className={var_class} name={var_nom} placeholder={UcFirst(var_nom)} required={var_required} defaultValue={var_value} />
        </div>
    );
}

function FormInputHidden({ var_nom, var_value, var_required }) {
    return (
        <div className='inputHidden-container' style={{ gridArea: var_nom }}>
            <input required={var_required} type='text' name={var_nom} defaultValue={var_value} />
        </div>
    );
}

function FormInputSubmit({ var_nom, var_class, var_value }) {
    return (
        <div className='inputSubmit-container' style={{ gridArea: var_nom }}>
            <input className={var_class} type='submit' name={var_nom} value={UcFirst(var_value)} />
        </div>

    );
}

function FormBouton({ var_nom, var_class, var_value, var_handleButtonClick }) {
    return (
        <div className='bouton-container' style={{ gridArea: var_nom }}>
            <button
                type='button'
                className={var_class}
                name={var_nom}
                onClick={var_handleButtonClick}
            >
                {UcFirst(var_value)}
            </button>
        </div>

    );
}

function FormSelect({ var_nom, var_class, var_array, var_required, var_value, var_readOnly, var_handleOnChange, var_legendeLabel }) {
    const [ValueSelected, setValueSelected] = useState(var_value);

    const handleChange = (e) => {
        setValueSelected(e.target.value);
    };

    useEffect(() => {
        // Mettre à jour la valeur sélectionnée si var_value change (par exemple, lors d'un rechargement de la page)
        setValueSelected(var_value);
    }, [var_value]);

    return (
        <div className='select-container' style={{ gridArea: var_nom }}>
            <label>{UcFirst(var_nom)}</label> : <label className='labelLegende-container'>{var_legendeLabel}</label>
            <select
                className={var_class}
                name={var_nom}
                required={var_required}
                value={ValueSelected === undefined ? '' : ValueSelected}
                disabled={var_readOnly}
                onChange={(e) => {
                    handleChange(e);
                    if (var_handleOnChange) {
                        var_handleOnChange(e); // Call both handleChange and var_handleOnChange
                    }
                }}
            >
                <option value=""></option>
                {var_array.map((item, index) =>
                    <option
                        key={index}
                        value={item}
                    >
                        {UcFirst(item)}
                    </option>
                )}
            </select>
        </div >
    );
}

function FormSelectWithId({ var_nom, var_class, var_array, var_required, var_value, var_readOnly, var_handleOnChange, var_legendeLabel }) {
    const [ValueSelected, setValueSelected] = useState(var_value);

    const handleChange = (e) => {
        setValueSelected(e.target.value);
    };

    useEffect(() => {
        // Mettre à jour la valeur sélectionnée si var_value change (par exemple, lors d'un rechargement de la page)
        setValueSelected(var_value);
    }, [var_value]);

    return (
        <div className='select-container' style={{ gridArea: var_nom }}>
            <label>{UcFirst(var_nom)}</label> : <label className='labelLegende-container'>{var_legendeLabel}</label>
            <select
                className={var_class}
                name={var_nom}
                required={var_required}
                value={ValueSelected === undefined ? '' : ValueSelected}
                disabled={var_readOnly}
                onChange={(e) => {
                    handleChange(e);
                    if (var_handleOnChange) {
                        var_handleOnChange(e); // Call both handleChange and var_handleOnChange
                    }
                }}
            >
                <option value=""></option>
                {var_array.map((item, index) =>
                    <option
                        key={index}
                        value={item.id}
                    >
                        {UcFirst(item.nom)}
                    </option>
                )}
            </select>
        </div >
    );
}

function FormSelectNumeric({ var_nom, var_class, var_array, var_required, var_value, var_readOnly, var_handleOnChange }) {
    const [ValueSelected, setValueSelected] = useState(var_value);

    const handleChange = (e) => {
        setValueSelected(e.target.value);
    };

    useEffect(() => {
        // Mettre à jour la valeur sélectionnée si var_value change (par exemple, lors d'un rechargement de la page)
        if (var_value === null || var_value === undefined) {
            setValueSelected('');
        } else {
            setValueSelected(var_value);
        }
    }, [var_value]);

    return (
        <div className='select-container' style={{ gridArea: var_nom }}>
            <label>{UcFirst(var_nom)} :</label>
            <select
                className={var_class}
                name={var_nom}
                required={var_required}
                value={ValueSelected === undefined ? '' : ValueSelected}
                disabled={var_readOnly}
                onChange={(e) => {
                    handleChange(e);
                    if (var_handleOnChange) {
                        var_handleOnChange(e); // Call both handleChange and var_handleOnChange
                    }
                }}
            >
                <option value=""></option>
                {var_array.map((item, index) =>
                    <option
                        key={index}
                        value={item}
                    >
                        {item}
                    </option>
                )}
            </select>
        </div>
    );
}

function FormSelectMultiple({ var_nom, var_array, var_option, var_height, var_class, var_required, var_value, var_selectedIds, var_description_label, var_selectedMax }) {
    const [SelectedValues, setSelectedValues] = useState([]);
    const [SelectedId, setSelectedId] = useState([]);
    let maxValueReached

    if (parseInt(var_selectedMax) === parseInt(SelectedValues.length)) {
        maxValueReached = true
    }

    useEffect(() => {
        if (var_value !== '') {
            setSelectedValues(var_value.split(', '));
            setSelectedId(var_selectedIds)
        }
    }, [var_value, var_selectedIds]);

    const handleClick = (e) => {
        const value = e.currentTarget.getAttribute('data-value');
        const id = e.currentTarget.getAttribute('data-id');

        if (SelectedValues.includes(value)) {
            setSelectedValues(SelectedValues.filter(item => item !== value));
            setSelectedId(SelectedId.filter(item => item !== parseInt(id)));
        } else {
            setSelectedValues([...SelectedValues, UcFirst(value)]);
            setSelectedId([...SelectedId, parseInt(id)]);
        }
    };

    return (
        <div className='selectMultiple-container' style={{ gridArea: var_nom }}>
            <label>{UcFirst(var_nom)} : {var_description_label}</label>
            <div className={'selectedValue ' + var_class}>
                {SelectedValues.length > 0 ? SelectedValues.join(', ') : 'Aucun élément sélectionné'}
            </div>

            <FormInputHidden var_nom={var_nom} var_value={SelectedValues.join(', ')} var_required={var_required} />
            <FormInputHidden var_nom={var_nom + '_tabIds'} var_value={SelectedId.join(', ')} var_required={var_required} />

            <div className={'listValue-container ' + var_class} style={{ height: var_height }}>
                {
                    var_array.map((item, index) => (
                        var_option(item, (e) => handleClick(e), index, SelectedId, maxValueReached)
                    ))
                }
            </div>
        </div>
    );
}

function FormInputPhoto({ var_nom, var_height, var_width, var_required, var_value }) {
    const [previewURL, setPreviewURL] = useState(null);

    const handlePhotoChange = (e) => {
        const selectedFile = e.target.files[0];

        const imageURL = URL.createObjectURL(selectedFile);
        setPreviewURL(imageURL);
    };

    const defaultImageURL = var_value;
    const displayImageURL = previewURL || defaultImageURL;


    return (
        <div className='inputPhoto-container' style={{ gridArea: var_nom }}>
            <label htmlFor={`input-${var_nom}`} className="image-label">
                <img
                    src={displayImageURL}
                    alt="Preview"
                    style={{ width: var_width, height: var_height }}
                />
            </label>

            <input
                id={`input-${var_nom}`}
                type="file"
                accept="image/*"
                onChange={handlePhotoChange}
                style={{ display: 'none' }}
                name={var_nom}
                required={var_required}
            />
        </div>
    );
}

function FormTextarea({ var_nom, var_required, var_class, var_value, var_readOnly }) {
    return (

        <div className='inputTextarea-container' style={{ gridArea: var_nom }}>
            <label>{UcFirst(var_nom)} : </label>
            <textarea readOnly={var_readOnly} className={var_class} name={var_nom} placeholder={UcFirst(var_nom)} required={var_required} defaultValue={var_value}>

            </textarea>
        </div>
    );
}

function FormSelectPresence({ var_label, var_key, var_photo, var_class, var_value, var_readOnly, var_handleOnChange, var_name }) {
    const array_option = ['absent', 'absent justifié', 'présent', 'retard', 'retard justifié',]
    const [ValueSelected, setValueSelected] = useState([]);

    const handleChange = (e) => {
        setValueSelected(e.target.value);
    };

    useEffect(() => {
        // Mettre à jour la valeur sélectionnée si var_value change (par exemple, lors d'un rechargement de la page)
        setValueSelected(var_value);
    }, [var_value]);

    return (
        <div className={'selectPresence-container bg-' + ValueSelected} key={var_key}>
            <div className='infosJoueur'>
                <div className='photoJoueur'>
                    <img src={var_photo} alt={var_label} />
                </div>
                <div>{var_label}</div>
            </div>
            <div className='checkbox'>
                <select
                    id={var_name}
                    name={var_name}
                    value={ValueSelected.toString()}
                    className={var_class}
                    disabled={var_readOnly}
                    onChange={(e) => {
                        handleChange(e);
                        if (var_handleOnChange) {
                            var_handleOnChange(e); // Call both handleChange and var_handleOnChange
                        }
                    }}
                >   
                    {array_option.map((item, index) =>
                        <option
                            key={index}
                            value={item}
                        >
                            {UcFirst(item)}
                        </option>
                    )}
                </select>
            </div>
        </div>
    );
}

export {
    FormInputText,
    FormInputSubmit,
    FormSelectMultiple,
    FormSelectWithId,
    FormInputHidden,
    FormInputDate,
    FormInputHeure,
    FormInputEmail,
    FormInputTel,
    FormSelect,
    FormSelectNumeric,
    FormInputPhoto,
    FormBouton,
    FormTextarea,
    FormSelectPresence
};
