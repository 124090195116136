import AfficherHome from '../components/home/AfficherHome';

const Home = () => {
    return (
        <div className='home-container'>
            <AfficherHome />
        </div>
    );
};

export default Home;