import axios from "axios";

export async function insertEntrainements(
    nom,
    description,
    recurrence,
    jour_recurrence,
    date_debut,
    date_fin,
    heure_rdv,
    heure_debut,
    heure_fin,
    id_stade,
    statut,
) {
    try {
        let apiUrl = `${process.env.REACT_APP_API_URL}insertEntrainements/?nom=${nom}&description=${description}&recurrence=${recurrence}&date_debut=${date_debut}&heure_rdv=${heure_rdv}&heure_debut=${heure_debut}&heure_fin=${heure_fin}&id_stade=${id_stade}&statut=${statut}`;

        if (jour_recurrence) {
            apiUrl += `&jour_recurrence=${jour_recurrence}`;
        }
        if (date_fin) {
            apiUrl += `&date_fin=${date_fin}`;
        }
        const { data } = await axios.get(apiUrl);
        if (JSON.stringify(data.affectedRows)) {
            const msg = `Insert entrainement [${nom}] : [SUCCESS]`
            return msg;
        }
    } catch (error) {
        console.log(error);
    }
}
