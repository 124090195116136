import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormInputText, FormInputSubmit, FormInputPhoto, FormBouton, FormSelectMultiple } from '../formulaires/elementsFormulaires';
import { postFiles } from '../../api/files/postFiles';
import { getJoueurs } from '../../api/joueurs/getJoueurs';
import { getDirigeants } from '../../api/dirigeants/getDirigeants';
import { insertGroupesEntrainements } from '../../api/entrainements/insertGroupesEntrainements'
import { updateGroupesEntrainements } from '../../api/entrainements/updateGroupesEntrainements';
import { updateRestaurationSuppressionGroupeEntrainement } from '../../api/entrainements/updateRestaurationSuppressionGroupeEntrainement'
import UcFirst from '../fonctions/UcFirst';
import Modal from '../Modal'
import { useNavigate } from 'react-router-dom';
import ModalOuiNon from '../modal/ModalOuiNon';
import { v4 as uuidv4 } from 'uuid'
import { getGroupesEntrainements } from '../../api/entrainements/getGroupesEntrainements';
import { getEntrainements } from '../../api/entrainements/getEntrainements';
import globalStorage from '../fonctions/GlobalStorage';


function InformationsGroupesEntrainements() {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////
    // RECUPERATION ID ELEMENT //
    /////////////////////////////
    const location = useLocation();
    const currentURL = location.pathname;
    const parts = currentURL.split("/"); // Divise la chaîne en segments en utilisant le séparateur "/"
    const valeur = parts.pop(); // Récupère le dernier élément du tableau
    const IdElement = valeur.replace(/%20/g, " "); // Remplace les %20 en espace

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // VARIABLES //
    ///////////////

    // GLOBAL STORAGE
    const { gsSetTitreHeaderSecondaire } = globalStorage();

    const navigate = useNavigate();
    const [ListeGroupesEntrainements, setListeGroupesEntrainements] = useState([])

    const [ListeDirigeants, setListeDirigeants] = useState([])
    const [ListeDirigeantsSelected, setListeDirigeantsSelected] = useState([])
    const [SelectedDirigeantsIds, setSelectedDirigeantsIds] = useState([])

    const [ListeJoueurs, setListeJoueurs] = useState([])
    const [ListeJoueursSelected, setListeJoueursSelected] = useState([])
    const [SelectedJoueursIds, setSelectedJoueursIds] = useState([])

    const [ListeEntrainements, setListeEntrainements] = useState([])
    const [ListeEntrainementsSelected, setListeEntrainementsSelected] = useState([])
    const [SelectedEntrainementsIds, setSelectedEntrainementsIds] = useState([])

    const [ShowModal, setShowModal] = useState(false);
    const [ContentModal, setContentModal] = useState(false);
    const [InfosModal, setInfosModal] = useState(false);
    const [ReloadPage, setReloadPage] = useState(false);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    // GET MATCH
    const fetch = useCallback(async () => {
        // GET GROUPES ENTRAINEMENTS
        let listeGroupesEntrainements = await getGroupesEntrainements();
        listeGroupesEntrainements = listeGroupesEntrainements.filter(itemFilter => itemFilter.statut !== 'supprimé')
        setListeGroupesEntrainements(listeGroupesEntrainements)

        // GET JOUEURS
        let listeJoueurs = await getJoueurs();
        listeJoueurs = listeJoueurs.filter(itemFilter => itemFilter.statut !== 'supprimé')
        setListeJoueurs(listeJoueurs)

        // GET DIRIGEANTS
        let listeDirigeants = await getDirigeants();
        listeDirigeants = listeDirigeants.filter(itemFilter => itemFilter.statut !== 'supprimé')
        setListeDirigeants(listeDirigeants)

        // GET ENTRAINEMENTS
        let listeEntrainements = await getEntrainements();
        listeEntrainements = listeEntrainements.filter(itemFilter => itemFilter.statut !== 'supprimé')
        setListeEntrainements(listeEntrainements)

        if (IdElement !== 'ajouter') {
            const selectedGroupeEntrainement = listeGroupesEntrainements.filter(item => parseInt(item.id) === parseInt(IdElement) && item.statut !== 'supprimé')
            if (selectedGroupeEntrainement.length === 0) {
                navigate('/notFound');
            } else {
                const listeJoueursSelected = listeJoueurs
                    .filter(item => selectedGroupeEntrainement[0].joueurs.split(', ').includes(item.id.toString()))
                    .map(item => UcFirst(item.prenom) + ' ' + item.nom.toUpperCase());
                setListeJoueursSelected(listeJoueursSelected.join(', '))

                const listeDirigeantsSelected = listeDirigeants
                    .filter(item => selectedGroupeEntrainement[0].dirigeants.split(', ').includes(item.id.toString()))
                    .map(item => UcFirst(item.prenom) + ' ' + item.nom.toUpperCase());
                setListeDirigeantsSelected(listeDirigeantsSelected.join(', '))
                
                const listeEntrainementsSelected = listeEntrainements
                    .filter(item => selectedGroupeEntrainement[0].entrainements.split(', ').includes(item.id.toString()))
                    .map(item => item.nom.toUpperCase());
                setListeEntrainementsSelected(listeEntrainementsSelected.join(', '))

                setListeGroupesEntrainements(selectedGroupeEntrainement)
            }

            // GET ID JOUEURS SELECTIONNES
            let selectedJoueursIds = listeGroupesEntrainements[0].joueurs.split(', ')
            selectedJoueursIds = selectedJoueursIds.map(item => parseInt(item));
            setSelectedJoueursIds(selectedJoueursIds); // Un tableau d'IDs correspondants

            // GET ID DIRIGEANTS SELECTIONNES
            let selectedDirigeantsIds = listeGroupesEntrainements[0].dirigeants.split(', ')
            selectedDirigeantsIds = selectedDirigeantsIds.map(item => parseInt(item));
            setSelectedDirigeantsIds(selectedDirigeantsIds); // Un tableau d'IDs correspondants

            // GET ID DIRIGEANTS SELECTIONNES
            let selectedEntrainementsIds = listeGroupesEntrainements[0].entrainements.split(', ')
            selectedEntrainementsIds = selectedEntrainementsIds.map(item => parseInt(item));
            setSelectedEntrainementsIds(selectedEntrainementsIds); // Un tableau d'IDs correspondants

            // EDIT TITRE HEADER
            gsSetTitreHeaderSecondaire(selectedGroupeEntrainement[0].nom)
        } else {
            // EDIT TITRE HEADER
            gsSetTitreHeaderSecondaire('Ajouter groupe')
        }
    }, [IdElement, gsSetTitreHeaderSecondaire, navigate]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const options = (array, handleClick, index, SelectedId, maxValueReached) => {
        const isSelected = SelectedId && Array.isArray(SelectedId) && SelectedId.includes(array.id);

        return (
            <div
                className={'option-container bg-secondaire ' + (isSelected ? 'bg-selected' : maxValueReached === true ? 'general-desactiverClic' : '')}
                id={array.id}
                key={index}
                data-value={UcFirst(array.prenom.toLowerCase()) + ' ' + array.nom.toUpperCase()} // Utilisation de l'attribut data-value
                data-id={array.id} // Utilisation de l'attribut data-value
                onClick={(e) => handleClick(e)}
            >
                {UcFirst(array.prenom.toLowerCase()) + ' ' + array.nom.toUpperCase()}
            </div>
        )
    }

    const optionsEntrainements = (array, handleClick, index, SelectedId, maxValueReached) => {
        const isSelected = SelectedId && Array.isArray(SelectedId) && SelectedId.includes(array.id);

        return (
            <div
                className={'option-container bg-secondaire ' + (isSelected ? 'bg-selected' : maxValueReached === true ? 'general-desactiverClic' : '')}
                id={array.id}
                key={index}
                data-value={UcFirst(array.nom.toUpperCase())} // Utilisation de l'attribut data-value
                data-id={array.id} // Utilisation de l'attribut data-value
                onClick={(e) => handleClick(e)}
            >
                {UcFirst(array.nom.toUpperCase())}
            </div>
        )
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////
    // HANDLE //
    ////////////

    async function handleSubmit(e) {
        // VARIABLES
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const nom = formData.get('nom');
        let photo = formData.get('photo');
        const entrainements = formData.get('entrainements_tabIds');
        const joueurs = formData.get('joueurs_tabIds');
        const dirigeants = formData.get('dirigeants_tabIds');
        const commentaires = formData.get('commentaires');
        const statut = 'en ligne'
        const extension = photo.name.split('.').pop()

        // VERIFICATION
        const verifGroupeExistant = ListeGroupesEntrainements.filter(item => item.nom.toLowerCase() === nom.toLowerCase())
        let verifIdGroupeExistant;
        if (verifGroupeExistant.length > 0 && IdElement !== "ajouter") {
            verifIdGroupeExistant = ListeGroupesEntrainements.find(item => item.nom.toLowerCase() === nom.toLowerCase()).id
        }

        if (verifGroupeExistant.length > 0 && parseInt(verifIdGroupeExistant) !== parseInt(IdElement)) {
            const verifGroupeStatut = ListeGroupesEntrainements.find(item => item.nom.toLowerCase() === nom.toLowerCase()).statut
            verifIdGroupeExistant = ListeGroupesEntrainements.find(item => item.nom.toLowerCase() === nom.toLowerCase()).id
            if (verifGroupeStatut === 'supprimé') {
                // AFFICHER MODAL
                const affichageModal = 'Voulez-vous restaurer le groupe suivant ?';
                setShowModal(true)
                setContentModal(
                    <ModalOuiNon handleClick={handleOuiNonClick} affichage={affichageModal} infos={nom} var_name={'restauration'} var_id={verifIdGroupeExistant} />
                )
            } else {
                // AFFICHER MODAL
                const affichageModal = 'Ce groupe existe déjà !';
                setShowModal(true)
                setContentModal(
                    <div>
                        {affichageModal}
                    </div>
                )
                setInfosModal(
                    <div>
                        {nom}
                    </div>
                )
                setReloadPage(false)
            }
        } else {
            const selectedFile = photo
            const destDefaultPictures = 'default'
            const destPictures = 'photos/groupes'
            let postFileStatus = '';

            if (selectedFile.name) {
                // AJOUT PHOTO DANS LE SERVEUR
                let nomPhoto
                if (IdElement === 'ajouter' || selectedFile.name.includes('default-')) {
                    nomPhoto = uuidv4();
                } else {
                    nomPhoto = ListeGroupesEntrainements[0].photo.split('/')
                    nomPhoto = nomPhoto[nomPhoto.length - 1];
                    nomPhoto = nomPhoto.split('.');
                    nomPhoto = nomPhoto[0];

                    if (nomPhoto.includes('default-')) {
                        nomPhoto = uuidv4();
                    }
                }

                const fileInput = document.getElementById('input-photo'); // Remplacez par l'élément input de type "file" de votre formulaire
                formData.append('photo', fileInput);
                const postFile = await postFiles(nomPhoto, destPictures, formData);
                // const postFile = 200
                postFileStatus = postFile
                photo = '/' + destPictures + '/' + nomPhoto + '.' + extension
            } else {
                postFileStatus = "N/A"
                if (IdElement === 'ajouter') {
                    photo = '/' + destDefaultPictures + '/default-groupes.png'
                } else {
                    photo = ListeGroupesEntrainements[0].photo
                }
            }

            // AFFICHER MODAL
            setShowModal(true)

            if (postFileStatus === 200 || postFileStatus === 'N/A') {
                let affichageModal = "";
                let insertSql = [];
                let updateSql = [];

                if (IdElement === 'ajouter') {
                    // INSERTION DONNEES EN BASE
                    insertSql = await insertGroupesEntrainements(nom, photo, entrainements, joueurs, dirigeants, commentaires, statut);
                    console.log(insertSql)
                    affichageModal = 'Le groupe suivant a bien été ajouté';
                } else {
                    // MISE A JOUR DONNEES EN BASE
                    updateSql = await updateGroupesEntrainements(IdElement, nom, photo, entrainements, joueurs, dirigeants, commentaires, statut);
                    console.log(updateSql)
                    affichageModal = 'Le groupe suivant a bien été mis à jour';
                }

                if (insertSql.includes("SUCCESS") || updateSql.includes("SUCCESS")) {
                    setReloadPage(true)
                    setContentModal(
                        <div>{affichageModal}</div>
                    )
                    setInfosModal(
                        <div>{nom.toUpperCase()}</div>
                    )
                }
            } else {
                setContentModal(
                    <div>
                        <div>
                            <i className="fa-solid fa-circle-xmark fa-beat" style={{ color: "#ff0000" }}></i> Une erreur s'est produite lors de l'envoie du formulaire
                            <div>{postFileStatus}</div>
                        </div>
                    </div>
                )
            }
        }
    };

    async function handleButtonClick(e) {
        const value = e.target.name

        const form = document.querySelector('.formulaire-container');
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const nom = formData.get('nom').toUpperCase();

        if (value === 'supprimer') {
            // AFFICHER MODAL
            const affichageModal = 'Voulez-vous vraiment supprimer le groupe suivant ?';

            setShowModal(true)
            setContentModal(
                <ModalOuiNon handleClick={handleOuiNonClick} affichage={affichageModal} infos={nom} var_name={'suppression'} />
            )
            setReloadPage(false)
        }
    }

    async function handleOuiNonClick(e) {
        const name = e.target.name
        const value = e.target.value
        const id = e.target.id

        const form = document.querySelector('.formulaire-container');
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const nom = formData.get('nom');
        let photo = ListeGroupesEntrainements[0].photo
        const entrainements = formData.get('entrainements_tabIds');
        const joueurs = formData.get('joueurs_tabIds');
        const dirigeants = formData.get('dirigeants_tabIds');
        const commentaires = formData.get('commentaires');

        if (name === 'suppression') {
            if (value === 'oui') {
                const statut = 'supprimé'

                // MISE A JOUR DONNEES EN BASE
                const updateSql = await updateGroupesEntrainements(IdElement, nom, photo, entrainements, joueurs, dirigeants, commentaires, statut);
                const affichageModal = 'Le groupe suivant a bien été supprimé';

                if (updateSql.includes("SUCCESS")) {
                    // AFFICHER MODAL
                    setContentModal(
                        <div>
                            <div>{affichageModal}</div><br />
                            <div className='cl-principalBold'>{nom}</div>
                        </div>
                    )
                }
                setReloadPage(true)
            } else {
                setShowModal(false)
            }
        } else if (name === 'restauration') {
            if (value === 'oui') {
                const updateSql = await updateRestaurationSuppressionGroupeEntrainement(id, 'en ligne');
                if (updateSql.includes("SUCCESS")) {
                    const affichageModal = 'Le groupe suivant a bien été restauré !';
                    // AFFICHER MODAL
                    setContentModal(
                        <div>
                            <div>{affichageModal}</div><br />
                            <div className='cl-principalBold'>{nom}</div>
                        </div>
                    )
                    setReloadPage(true)
                }
            } else {
                setShowModal(false)
            }
        }

    }

    async function handleReloadPageClick(e) {
        if (ReloadPage === true) {
            navigate('/entrainements/groupes');
        }
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className='informationsGroupesEntrainements-children'>
            <form
                className='formulaire-container'
                onSubmit={(e) => { handleSubmit(e) }}
            >
                <FormInputPhoto
                    var_readOnly={false}
                    var_nom={'photo'}
                    var_height='150px'
                    var_width='150px'
                    var_value={ListeGroupesEntrainements.length > 0 && IdElement !== 'ajouter' ? ListeGroupesEntrainements[0].photo : '/icones/ajouter image.png'}
                />
                <FormInputText
                    var_readOnly={false}
                    var_nom={'nom'}
                    var_class={"bg-main"}
                    var_required={true}
                    var_value={ListeGroupesEntrainements.length > 0 && IdElement !== 'ajouter' ? ListeGroupesEntrainements[0].nom : ''}
                />
                <FormSelectMultiple
                    var_option={optionsEntrainements}
                    var_nom={'entrainements'}
                    var_description_label={''}
                    var_class={"bg-main entrainement"}
                    var_array={ListeEntrainements}
                    // var_height={'100px'}
                    var_value={ListeEntrainementsSelected.length > 0 && IdElement !== 'ajouter' ? ListeEntrainementsSelected : ''}
                    var_selectedIds={SelectedEntrainementsIds}
                    var_required={true}
                    var_selectedMax={''}
                />
                <FormSelectMultiple
                    var_option={options}
                    var_nom={'joueurs'}
                    var_description_label={''}
                    var_class={"bg-main joueurs"}
                    var_array={ListeJoueurs}
                    // var_height={'100px'}
                    var_value={ListeJoueursSelected.length > 0 && IdElement !== 'ajouter' ? ListeJoueursSelected : ''}
                    var_selectedIds={SelectedJoueursIds}
                    var_required={true}
                    var_selectedMax={''}
                />
                <FormSelectMultiple
                    var_option={options}
                    var_nom={'dirigeants'}
                    var_description_label={''}
                    var_class={"bg-main dirigeants"}
                    var_array={ListeDirigeants}
                    // var_height={'100px'}
                    var_value={ListeDirigeantsSelected.length > 0 && IdElement !== 'ajouter' ? ListeDirigeantsSelected : ''}
                    var_selectedIds={SelectedDirigeantsIds}
                    var_required={false}
                    var_selectedMax={''}
                />
                <FormInputText
                    var_readOnly={false}
                    var_nom={'commentaires'}
                    var_class={"bg-main"}
                    var_required={false}
                    var_value={ListeGroupesEntrainements.length > 0 && IdElement !== 'ajouter' ? ListeGroupesEntrainements[0].commentaires : ''}
                />
                <FormInputSubmit
                    var_nom={'submit'}
                    var_value={IdElement === 'ajouter' ? 'ajouter' : 'sauvegarder'}
                    var_class={'bg-principal'}
                />
                {IdElement !== 'ajouter' && (
                    <FormBouton
                        var_nom={'supprimer'}
                        var_value={'supprimer'}
                        var_class={'bg-supprimer'}
                        var_handleButtonClick={handleButtonClick}
                    />
                )}
            </form>

            <Modal
                showModal={ShowModal}
                contentModal={ContentModal}
                handleCloseModal={() => {
                    setShowModal(false);
                    setContentModal("");
                    handleReloadPageClick();
                }}
                infosModal={InfosModal}
            />
        </div>
    )
}

export default InformationsGroupesEntrainements;
