import axios from "axios";

export async function updateEntrainements(
    id_entrainement,
    nom,
    description,
    recurrence,
    jour_recurrence,
    date_debut,
    date_fin,
    heure_rdv,
    heure_debut,
    heure_fin,
    id_stade,
    statut,
) {
    try {
        const { data } = await axios.get(`
            ${process.env.REACT_APP_API_URL}updateEntrainements/?id_entrainement=${id_entrainement}&nom=${nom}&description=${description}&recurrence=${recurrence}&jour_recurrence=${jour_recurrence}&date_debut=${date_debut}&date_fin=${date_fin}&heure_rdv=${heure_rdv}&heure_debut=${heure_debut}&heure_fin=${heure_fin}&id_stade=${id_stade}&statut=${statut}
        `)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `Update entrainement [${nom}] : [SUCCESS]`
            return msg;
        }
    } catch (error) {
        console.log(error);
    }
}
