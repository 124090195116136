import axios from "axios";

export async function insertMatchsInfos(id_match, debut_match, mi_temps, statut_match, bouton_match){
    try{
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}insertMatchsInfos/?id_match=${id_match}&debut_match=${debut_match}&debut_mi_temps=${debut_match}&mi_temps=${mi_temps}&statut_match=${statut_match}&bouton_match=${bouton_match}`)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `Insert MatchsInfos [${id_match}] in database : SUCCES !`
            return msg;
        } 
    } catch (error) {
        console.log(error);
    }
}
