import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import "./styles/App.css"
import Main from './components/Main';
import Footer from './components/Footer';
import HeaderPrincipal from './components/HeaderPrincipal';
import HeaderSecondaire from './components/HeaderSecondaire';
import globalStorage from './components/fonctions/GlobalStorage';

const App = () => {
  const { gsDisplayFooter, gsDisplayHeader, gsDisplayHeaderPrincipal, gsDisplayHeaderSecondaire } = globalStorage();

  return (
    <BrowserRouter>
      <div className='site-container'>
        {gsDisplayHeader && gsDisplayHeaderPrincipal &&
          <HeaderPrincipal />
        }
        {gsDisplayHeader && gsDisplayHeaderSecondaire &&
          <HeaderSecondaire />
        }
        <Main />
        {gsDisplayFooter &&
          <Footer />
        }
      </div>
    </BrowserRouter>
  );
};

export default App;