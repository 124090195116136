import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const handleAccueilClick = () => {
    navigate('/');
  };

  return (
    <div className="footer-container bg-principal site-children">
      <div className='accueilContainer' onClick={handleAccueilClick}>
        <i className="fa-solid fa-house"></i>
        <div>Accueil</div>
      </div>
      <div className='accueilContainer' onClick={handleAccueilClick}>
        <i className="fa-sharp fa-solid fa-circle-xmark"></i>
        <div>None</div>
      </div>
      <div className='accueilContainer' onClick={handleAccueilClick}>
        <i className="fa-sharp fa-solid fa-circle-xmark"></i>
        <div>None</div>
      </div>
      <div className='accueilContainer' onClick={handleAccueilClick}>
        <i className="fa-sharp fa-solid fa-circle-xmark"></i>
        <div>None</div>
      </div>
    </div>
  );
};

export default Footer;
