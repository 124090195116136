import axios from "axios";

export async function updateGroupesEntrainements(
    id_groupe_entrainement,
    nom,
    photo,
    entrainements,
    joueurs,
    dirigeants,
    commentaires,
    statut,
) {
    try {
        const { data } = await axios.get(`
            ${process.env.REACT_APP_API_URL}updateGroupesEntrainements/?id_groupe_entrainement=${id_groupe_entrainement}&nom=${nom}&photo=${photo}&entrainements=${entrainements}&joueurs=${joueurs}&dirigeants=${dirigeants}&commentaires=${commentaires}&statut=${statut}
        `)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `Update groupe entrainement [${id_groupe_entrainement}] : [SUCCESS]`
            return msg;
        }
    } catch (error) {
        console.log(error);
    }
}
