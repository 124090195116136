import axios from "axios";

export async function insertStades(
    id_club,
    nom,
    adresse,
    ville,
    code_postal,
    surface_de_jeu,
    statut
) {
    try {
        const { data } = await axios.get(`
            ${process.env.REACT_APP_API_URL}insertStades/?id_club=${id_club}&nom=${nom}&adresse=${adresse}&code_postal=${code_postal}&ville=${ville}&surface_de_jeu=${surface_de_jeu}&statut=${statut}
        `)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `SUCCESS`
            return msg;
        }
    } catch (error) {
        console.log(error);
    }
}
