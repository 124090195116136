import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import BoutonAction from '../boutons/boutonAction';
import Composition from '../compositions/Composition';
import { getCompositions } from '../../api/compositions/getCompositions';
import globalStorage from '../fonctions/GlobalStorage';

function InformationsCompositions() {
    ///////////////
    // VARIABLES //
    ///////////////

    // GLOBAL STORAGE
    const {gsSetTitreHeaderSecondaire} = globalStorage();

    // RECUPERATION ID MATCH
    const location = useLocation();
    const currentURL = location.pathname;
    const parts = currentURL.split("/"); // Divise la chaîne en segments en utilisant le séparateur "/"
    const valeur = parts.pop(); // Récupère le dernier élément du tableau
    const IdMatch = valeur.replace(/%20/g, " "); // Remplace les %20 en espace

    const [ShowComposition, setShowComposition] = useState(false);
    const [HiddenElement, setHiddenElement] = useState(false);
    const [TabComposition, setTabComposition] = useState([]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    window.scrollTo(0, 0);

    const fetchId = useCallback(async () => {
        // GET COMPOSITION
        let composition = await getCompositions();
        composition = composition.filter(item => parseInt(item.id_match) === parseInt(IdMatch))
        setTabComposition(composition)

        // EDIT TITRE HEADER
        gsSetTitreHeaderSecondaire('Composition match')
    }, [IdMatch, gsSetTitreHeaderSecondaire]);

    useEffect(() => {
        fetchId();
    }, [fetchId]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////
    // HANDLE CLICK //
    //////////////////

    const handleBoutonClick = async (event) => {
        setShowComposition(true)
        setHiddenElement(true)
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div className='matchsComposition-children'>
            {TabComposition.length > 0 ? (
                <Composition tabComposition={TabComposition} show={true} />
            ) : (
                <div>
                    <div className='composition'>
                        <Composition tabComposition={TabComposition} show={ShowComposition} />
                    </div>
                    <div className={HiddenElement === true ? 'boutons-container general-displayNone' : 'boutons-container'}>
                        <BoutonAction content={"Ajouter une compo."} addClass={'bg-principal'} handleBoutonClick={handleBoutonClick} />
                    </div>
                    <div className={HiddenElement === true ? 'aucunResultat general-displayNone' : 'aucunResultat'}>Aucune composition trouvée</div>
                </div>
            )}
        </div>
    );
}

export default InformationsCompositions;
