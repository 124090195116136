import React, { } from 'react';
import AfficherDirigeants from '../components/dirigeants/AfficherDirigeants';
import AfficherInformationsDirigeants from '../components/dirigeants/AfficherInformationsDirigeants';

const Dirigeants = () => {

    return (
        <div className='dirigeants-container'>
            <AfficherDirigeants />
        </div>
    );
};

const AjouterDirigeants = () => {
    return (
        <div className='informationsDirigeants-container'>
            <AfficherInformationsDirigeants />
        </div>
    );
};

const InformationsDirigeants = () => {
    return (
        <div className='informationsDirigeants-container'>
            <AfficherInformationsDirigeants />
        </div>
    );
};

export { Dirigeants, AjouterDirigeants, InformationsDirigeants };