import React from 'react';

function TuileStade({ nomClub, nomStade, adresse, codePostal, ville, surfaceDeJeu }) {
    return (
        <div className="infosStadeContainer bg-infosStade bs-principal">
            <div>{nomClub}</div><br />
            <div>{nomStade.toUpperCase()}</div>
            <div>{adresse.toUpperCase()}</div>
            <div>{codePostal.toUpperCase()} {ville.toUpperCase()}</div><br />
            <div>{surfaceDeJeu.toUpperCase()}</div>
        </div>
    );
}

export default TuileStade;
