import React from 'react';
import AfficherCompositions from '../components/compositions/AfficherCompositions';
import AfficherInformationsCompositions from '../components/compositions/AfficherInformationsCompositions';

const Compositions = () => {
    
    return (
        <div className='compositions-container'>
            <AfficherCompositions />
        </div>
    );
};

const InformationsCompositions = () => {

    return (
        <div className='informationsCompositions-container'>
            <AfficherInformationsCompositions />
        </div>
    );
};

export { Compositions, InformationsCompositions };