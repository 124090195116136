import React from 'react';

function ModalOuiNon({ handleClick, affichage, infos, var_name, var_id }) {
    ///////////////
    // VARIABLES //
    ///////////////

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////
    // FONCTION CLICK //
    ////////////////////

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className='modalOuiNon-container'>
            {affichage}
            <div className='cl-principalBold modal-infos'>
                {infos}
            </div>
            <div className='bouton-container'>
                <button id={var_id} className='oui bg-modalOui' name={var_name} value='oui' onClick={(e) => handleClick(e)}>OUI</button>
                <button id={var_id} className='non bg-modalNon' name={var_name} value='non' onClick={(e) => handleClick(e)}>NON</button>
            </div>
        </div>
    );
}

export default ModalOuiNon;
