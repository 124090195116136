import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormSelectPresence, FormInputSubmit } from '../formulaires/elementsFormulaires';
import { getJoueurs } from '../../api/joueurs/getJoueurs';
import UcFirst from '../fonctions/UcFirst';
import TrierParPrenom from '../fonctions/TrierParPrenom';
import Modal from '../Modal'
import { useNavigate } from 'react-router-dom';
import { getGroupesEntrainements } from '../../api/entrainements/getGroupesEntrainements';
import { getEntrainements } from '../../api/entrainements/getEntrainements';
import { updatePresenceEntrainements } from '../../api/entrainements/updatePresenceEntrainements';


function InformationsEntrainements() {

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////
    // RECUPERATION ID ELEMENT //
    /////////////////////////////
    const location = useLocation();
    const currentURL = location.pathname;
    const parts = currentURL.split("/"); // Divise la chaîne en segments en utilisant le séparateur "/"
    const valeur = parts.pop(); // Récupère le dernier élément du tableau
    const IdElement = valeur.replace(/%20/g, " "); // Remplace les %20 en espace

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // VARIABLES //
    ///////////////

    const navigate = useNavigate();
    const [ListeJoueurs, setListeJoueurs] = useState([]);
    const [ListeEntrainements, setListeEntrainements] = useState([]);
    const [NomEntrainement, setNomEntrainement] = useState([]);

    const [ShowModal, setShowModal] = useState(false);
    const [ContentModal, setContentModal] = useState(false);
    const [InfosModal, setInfosModal] = useState(false);
    const [ReloadPage, setReloadPage] = useState(false);

    const [TotalJoueurs, setTotalJoueurs] = useState(0);
    const [JoueursPresents, setJoueursPresents] = useState([]);
    const [JoueursAbsents, setJoueursAbsents] = useState([]);
    const [JoueursRetards, setJoueursRetards] = useState([]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    // GET Entrainement
    const fetch = useCallback(async () => {
        // GET GROUPES ENTRAINEMENTS
        let listeGroupesEntrainements = await getGroupesEntrainements();
        listeGroupesEntrainements = listeGroupesEntrainements.filter(itemFilter => itemFilter.statut !== 'supprimé')
        listeGroupesEntrainements = listeGroupesEntrainements.filter(itemFilter => itemFilter.entrainements.includes(IdElement))

        // GET ENTRAINEMENTS
        let listeEntrainements = await getEntrainements();
        listeEntrainements = listeEntrainements.filter(itemFilter => itemFilter.statut !== 'supprimé')
        listeEntrainements = listeEntrainements.filter(itemFilter => itemFilter.id === parseInt(IdElement))
        setListeEntrainements(listeEntrainements)
        setNomEntrainement(listeEntrainements[0].nom)

        // GET JOUEURS ENTRAINEMENTS
        let listeJoueursEntrainements = listeGroupesEntrainements.reduce((acc, item) => {
            const joueurs = item.joueurs.split(', ');
            return acc.concat(joueurs);
        }, []);

        const suppressionDesDoublons = new Set(listeJoueursEntrainements);
        listeJoueursEntrainements = [...suppressionDesDoublons];

        // GET JOUEURS
        let listeJoueurs = await getJoueurs();
        listeJoueurs = listeJoueurs.filter(itemFilter => itemFilter.statut !== 'supprimé')
        let listeJoueursSelected = []

        listeJoueursEntrainements.forEach(element => {
            const joueur = listeJoueurs.find(itemFilter => itemFilter.id === parseInt(element));
            if (joueur) {
                listeJoueursSelected.push(joueur);
            }
        });

        let totalJoueursPresents = listeJoueursEntrainements
        if (listeEntrainements[0].presences === '') {
            totalJoueursPresents = listeJoueursEntrainements
            setJoueursPresents(totalJoueursPresents)
        } else {
            totalJoueursPresents = listeEntrainements[0].presences.split(', ')
            setJoueursPresents(totalJoueursPresents)
        }

        const joueursAbsents = listeEntrainements[0].absences.split(', ')
        const joueursAbsentsJustifies = listeEntrainements[0].absences_justifies.split(', ')
        const joueursRetards = listeEntrainements[0].retards.split(', ')
        const joueursRetardsJustifies = listeEntrainements[0].retards_justifies.split(', ')

        let totalJoueursAbsents = joueursAbsents.concat(joueursAbsentsJustifies)
        totalJoueursAbsents = totalJoueursAbsents.filter(function (element) {
            return element !== '';
        });
        let totalJoueursRetards = joueursRetards.concat(joueursRetardsJustifies)
        totalJoueursRetards = totalJoueursRetards.filter(function (element) {
            return element !== '';
        });

        setJoueursAbsents(totalJoueursAbsents)
        setJoueursRetards(totalJoueursRetards)

        // GET LISTE IDs JOUEURS
        setTotalJoueurs(parseInt(totalJoueursPresents.length) + parseInt(totalJoueursAbsents.length) + parseInt(totalJoueursRetards.length))

        listeJoueursSelected.sort(TrierParPrenom);
        setListeJoueurs(listeJoueursSelected)
    }, [IdElement]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////
    // HANDLE //
    ////////////

    async function handleSubmit(e) {
        // VARIABLES
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);

        let presents = []
        let absents = []
        let absentsJustifies = []
        let retards = []
        let retardsJustifies = []

        ListeJoueurs.forEach(element => {
            const valueInput = formData.get(element.id);

            if (valueInput === "présent") {
                presents.push(element.id)
            } else if (valueInput === "absent") {
                absents.push(element.id)
            } else if (valueInput === "absent justifié") {
                absentsJustifies.push(element.id)
            } else if (valueInput === "retard") {
                retards.push(element.id)
            } else if (valueInput === "retard justifié") {
                retardsJustifies.push(element.id)
            }
        });

        presents = presents.join(', ')
        absents = absents.join(', ')
        absentsJustifies = absentsJustifies.join(', ')
        retards = retards.join(', ')
        retardsJustifies = retardsJustifies.join(', ')

        const updateSql = await updatePresenceEntrainements(IdElement, presents, absents, absentsJustifies, retards, retardsJustifies);
        console.log(updateSql);
        const affichageModal = "La fiche de présence de l'entrainement suivant a été mis à jour"

        if (updateSql.includes("SUCCESS")) {
            setShowModal(true)
            setReloadPage(true)
            setContentModal(
                <div>{affichageModal}</div>
            )
            setInfosModal(
                <div>{NomEntrainement.toUpperCase()}</div>
            )
        }
    };

    async function handleReloadPageClick(e) {
        if (ReloadPage === true) {
            navigate('/Entrainements');
        }
    }

    async function handlePresenceChange(e) {
        const value = e.target.value;
        const id = parseInt(e.target.id);

        let joueursPresents = [...JoueursPresents];
        let joueursAbsents = [...JoueursAbsents];
        let joueursRetards = [...JoueursRetards];

        // SUPPRESSION DE L'ID DES TABLEAUX
        joueursPresents = joueursPresents.filter(item => item !== id.toString());
        joueursAbsents = joueursAbsents.filter(item => item !== id.toString());
        joueursRetards = joueursRetards.filter(item => item !== id.toString());

        // AJOUT DE L'ID DANS LE TABLEAU
        if (value.includes('présent')) {
            joueursPresents.push(id.toString());
        } else if (value.includes('absent')) {
            joueursAbsents.push(id.toString());
        } else if (value.includes('retard')) {
            joueursRetards.push(id.toString());
        }

        setJoueursPresents(joueursPresents)
        setJoueursAbsents(joueursAbsents)
        setJoueursRetards(joueursRetards)
    }


    // // Déplacez setTotalJoueurs ici
    // useEffect(() => {
    //     setTotalJoueurs(ListeJoueurs);
    // }, [ListeJoueurs]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className='informationsEntrainements-children'>

            <form
                className='formulaire-container'
                onSubmit={(e) => { handleSubmit(e) }}
            >
                <div className='infosTuile-container'>{'Total joueurs : ' + TotalJoueurs} / {'Joueurs présents : ' + JoueursPresents.length} / {'Joueurs en retards : ' + JoueursRetards.length} / {'Joueurs absents : ' + JoueursAbsents.length}</div>
                {ListeJoueurs.length > 0 ? (
                    ListeJoueurs.map((item, index) => {
                        const presences = ListeEntrainements[0].presences.split(', ')
                        const absences = ListeEntrainements[0].absences.split(', ')
                        const absencesJustifies = ListeEntrainements[0].absences_justifies.split(', ')
                        const retards = ListeEntrainements[0].retards.split(', ')
                        const retardsJustifies = ListeEntrainements[0].retards_justifies.split(', ')
                        let value

                        if (presences !== null && presences.includes(item.id.toString())) {
                            value = 'présent'
                        } else if (absences !== null && absences.includes(item.id.toString())) {
                            value = 'absent'
                        } else if (absencesJustifies !== null && absencesJustifies.includes(item.id.toString())) {
                            value = 'absent justifié'
                        } else if (retards !== null && retards.includes(item.id.toString())) {
                            value = 'retard'
                        } else if (retardsJustifies !== null && retardsJustifies.includes(item.id.toString())) {
                            value = 'retard justifié'
                        } else {
                            value = 'présent'
                        }

                        return (
                            <FormSelectPresence
                                key={index} // Assurez-vous d'ajouter une clé unique ici
                                var_label={UcFirst(item.prenom.toLowerCase()) + ' ' + UcFirst(item.nom.toUpperCase())}
                                var_photo={item.photo}
                                var_readOnly={false}
                                var_name={item.id}
                                var_class={"bg-main"}
                                var_required={true}
                                var_value={value}
                                var_handleOnChange={handlePresenceChange}
                            />
                        );
                    })
                ) : (
                    <div>Aucun élément trouvé.</div>
                )}
                {ListeJoueurs.length > 0 &&
                    <FormInputSubmit
                        var_nom={'submit'}
                        var_value={IdElement === 'ajouter' ? 'ajouter' : 'sauvegarder'}
                        var_class={'bg-principal'}
                    />
                }
            </form>

            <Modal
                showModal={ShowModal}
                contentModal={ContentModal}
                handleCloseModal={() => {
                    setShowModal(false);
                    setContentModal("");
                    handleReloadPageClick();
                }}
                infosModal={InfosModal}
            />
        </div>
    )
}

export default InformationsEntrainements;
