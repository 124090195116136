// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.afficherGroupes-container{
}

.afficherGroupes-lien{
    text-decoration: none;
    color: black;
}

.afficherGroupes-tuile{
    border: solid 3px;
    font-size: 20px;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.afficherGroupes-nomGroupe{
}

.afficherGroupes-boutonsAction{
    margin-left: auto;
}

.afficherGroupes-boutonsAction button{
    display: inline-block;
    font-size: 15px;
    margin-left: 10px;
    border-radius: 5px;
    padding: 5px;
}

.afficherGroupes-modifier{
}

.afficherGroupes-supprimer{
}

.afficherGroupes-disableHref{
    pointer-events: none;
    cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/styles/AfficherTest.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;IACI,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,iBAAiB;IACjB,qBAAqB;IACrB,aAAa;IACb,mBAAmB;AACvB;;AAEA;AACA;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;AACA;;AAEA;AACA;;AAEA;IACI,oBAAoB;IACpB,eAAe;AACnB","sourcesContent":[".afficherGroupes-container{\n}\n\n.afficherGroupes-lien{\n    text-decoration: none;\n    color: black;\n}\n\n.afficherGroupes-tuile{\n    border: solid 3px;\n    font-size: 20px;\n    padding: 15px;\n    border-radius: 5px;\n    margin-bottom: 15px;\n    text-transform: uppercase;\n    font-weight: bold;\n    text-decoration: none;\n    display: flex;\n    align-items: center;\n}\n\n.afficherGroupes-nomGroupe{\n}\n\n.afficherGroupes-boutonsAction{\n    margin-left: auto;\n}\n\n.afficherGroupes-boutonsAction button{\n    display: inline-block;\n    font-size: 15px;\n    margin-left: 10px;\n    border-radius: 5px;\n    padding: 5px;\n}\n\n.afficherGroupes-modifier{\n}\n\n.afficherGroupes-supprimer{\n}\n\n.afficherGroupes-disableHref{\n    pointer-events: none;\n    cursor: default;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
