import React, { useEffect, useState } from 'react';
import { getCompositions } from '../../api/compositions/getCompositions';
import { getSystemesById } from '../../api/systemes/getSystemesById';
import { Link } from 'react-router-dom';
import BoutonRecherche from '../boutons/boutonRecherche';
import CapitalizeFirstLetter from "../fonctions/CapitalizeFirstLetter"

function AfficherCompositions() {
    // RÉCUPÉRATION Compositions DE LA BASE DE DONNÉES
    const [Compositions, setCompositions] = useState([]);
    const [recherche, setRecherche] = useState('');

    const fetchCompositions = async () => {
        const Compositions = await getCompositions();
        setCompositions(Compositions);
    };

    useEffect(() => {
        fetchCompositions();
    }, []);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // RÉCUPÉRATION Systemes DE LA BASE DE DONNÉES
    const [Systemes, setSystemes] = useState([]);

    useEffect(() => {
        Compositions.forEach(async (item) => {
            const systemeComposition = await getSystemesById(item.id_systeme);
            setSystemes((prevSystemes) => ({
                ...prevSystemes,
                [item.id_systeme]: systemeComposition,
            }));
        });
    }, [Compositions]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // RECHERCHE 
    const handleRechercheChange = (e) => {
        setRecherche(e.target.value);
    };

    const filtered = Compositions.filter((result) => {
        const nomComplet =
            result.nom.toLowerCase();

        const systemeComposition = Systemes[result.id_systeme];
        const nomSystemeComposition = systemeComposition ? systemeComposition[0].nom.toLowerCase() : '';

        return (
            nomComplet.includes(recherche.toLowerCase()) ||
            nomSystemeComposition.includes(recherche.toLowerCase())
        );
    });

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className='tuiles-container'>
            <BoutonRecherche recherche={recherche} handleRechercheChange={handleRechercheChange} />

            {filtered.length > 0 ? (
                filtered.map((item, index) => {
                    const systemeComposition = Systemes[item.id_systeme];
                    const nomSystemeComposition = systemeComposition ? systemeComposition[0].nom : '';

                    return (
                        <Link key={index} to={'' + item.id}>
                            <div className='tuiles-tuileChildren bs-principal'>
                                <div className='picture'>
                                    <img src="/logo.png" alt="Logo" />
                                </div>
                                <div className='name'>
                                    <span>{item.nom.toUpperCase()} </span>
                                    <span>Système : {CapitalizeFirstLetter(nomSystemeComposition)}</span>
                                </div>
                                <div className='icone'>
                                    <i className="fas fa-chevron-right"></i>
                                </div>
                            </div>
                        </Link>
                    );
                })
            ) : (
                <div>Aucun élément trouvé.</div>
            )}
        </div>
    );
}

export default AfficherCompositions;
