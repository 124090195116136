import AfficherTest from '../components/test/AfficherTest';

const Test = () => {
    return (
        <div className='test-container'>
            <AfficherTest />
        </div>
    );
};

export default Test;