import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Composition from '../compositions/Composition'
import { getCompositions } from '../../api/compositions/getCompositions';

function OngletComposition() {
    ///////////////
    // VARIABLES //
    ///////////////

    // RECUPERATION ID MATCH
    const location = useLocation();
    const currentURL = location.pathname;
    const parts = currentURL.split("/"); // Divise la chaîne en segments en utilisant le séparateur "/"
    const valeur = parts.pop(); // Récupère le dernier élément du tableau
    const IdMatch = valeur.replace(/%20/g, " "); // Remplace les %20 en espace

    const [TabComposition, setTabComposition] = useState([]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    const fetchId = useCallback(async () => {
        // GET COMPOSITION
        let composition = await getCompositions();
        composition = composition.filter(item => parseInt(item.id_match) === parseInt(IdMatch))
        setTabComposition(composition)
    }, [IdMatch]);

    useEffect(() => {
        fetchId();
    }, [fetchId]);

    return (
        <div className='ongletComposition-container'>
            <Composition tabComposition={TabComposition} show={true} />
        </div>
    );
}

export default OngletComposition;
