import axios from "axios";

export async function updateClubs(
    id_club,
    nom,
    acronyme,
    photo,
    statut
) {
    try {
        const { data } = await axios.get(`
            ${process.env.REACT_APP_API_URL}updateClubs/?id_club=${id_club}&nom=${nom}&acronyme=${acronyme}&photo=${photo}&statut=${statut}
        `)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `SUCCESS`
            return msg;
        }
    } catch (error) {
        console.log(error);
    }
}
