import React, { } from 'react';
import AfficherJoueurs from '../components/joueurs/AfficherJoueurs';
import AfficherInformationsJoueurs from '../components/joueurs/AfficherInformationsJoueurs';

const Joueurs = () => {

    return (
        <div className='joueurs-container'>
            <AfficherJoueurs />
        </div>
    );
};

const AjouterJoueurs = () => {
    return (
        <div className='informationsJoueurs-container'>
            <AfficherInformationsJoueurs />
        </div>
    );
};

const InformationsJoueurs = () => {
    return (
        <div className='informationsJoueurs-container'>
            <AfficherInformationsJoueurs />
        </div>
    );
};

export { Joueurs, AjouterJoueurs, InformationsJoueurs };