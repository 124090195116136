// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-container{
    display: flex;
    margin-top: 10px;
    border-top: solid 1px;
    border-bottom : solid 1px;
    padding: 5px;
    overflow: auto;
}

.navbar-container div{
    padding: 5px;
}

.navbar-container div.selected{
    padding: 5px;
    border-bottom: solid;
}

.menu-container .matchs-hidden{
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/Navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,qBAAqB;IACrB,yBAAyB;IACzB,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".navbar-container{\n    display: flex;\n    margin-top: 10px;\n    border-top: solid 1px;\n    border-bottom : solid 1px;\n    padding: 5px;\n    overflow: auto;\n}\n\n.navbar-container div{\n    padding: 5px;\n}\n\n.navbar-container div.selected{\n    padding: 5px;\n    border-bottom: solid;\n}\n\n.menu-container .matchs-hidden{\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
