import axios from "axios";

export async function updateRestaurationSuppressionMatch(
    id_joueur,
    statut
) {
    try {
        const { data } = await axios.get(`
            ${process.env.REACT_APP_API_URL}updateRestaurationSuppressionMatch/?id_joueur=${id_joueur}&statut=${statut}
        `)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `SUCCESS`
            return msg;
        }
    } catch (error) {
        console.log(error);
    }
}
