import React, { useContext, useEffect, useState } from 'react';
import { SettingsContext } from '../settings/RecuperationSettings';
import { getHome } from '../../api/home/getHome';
import Tuile from '../tuiles/tuiles';
import { useNavigate } from 'react-router-dom';


function AfficherHome() {
    //////////////
    // SETTINGS //
    //////////////

    const { settings } = useContext(SettingsContext);
    let WidthTuiles = '';

    if (Array.isArray(settings)) {
        const settingsWidthTuiles = settings.find((setting) => setting.name === 'WIDTH_TUILES');
        WidthTuiles = settingsWidthTuiles?.value || '';
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const navigate = useNavigate(); // Utilisez useNavigate
    const [NbElements, setNbElements] = useState([]);

    // RECUPERATION home DE LA BASE DE DONNEES
    const [Home, setHome] = useState([]);
    const fetchHome = async () => {
        const home = await getHome();
        setNbElements(home.length);
        setHome(home);
    };
    useEffect(() => {
        fetchHome();
    }, []);

    async function handleOnClick(e) {
        const id = e.target.id
        navigate(id)

        if (id === "Groupes d'entrainement") {
            navigate('/Entrainements/groupes')
        }
    }

    return (
        <div className='tuiles-container'>
            <div className='home-prochainMatch bs-principal'>
                Aucun match de programmé
            </div>
            <div className='home-prochainEntrainement bs-principal'>
                Aucun entrainement de programmé
            </div>

            <div className='tuile-container ' style={{ maxWidth: 'calc((20px * ' + NbElements + ') + (' + WidthTuiles + ' * ' + NbElements + '))' }}>
                {Home.length > 0 ? (
                    Home.map((item, index) => {
                        return (
                            <div className='key' key={index}>
                                <Tuile var_id={item.name} var_image='/logos/logo.png' var_infos={[item.name.toUpperCase()]} var_handleOnClick={handleOnClick} />
                            </div>
                        )
                    })
                ) : (
                    <div className='general-aucunElement'>Aucun élément trouvé.</div>
                )}
            </div>
        </div>
    );
}

export default AfficherHome;
