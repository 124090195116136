import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormInputText, FormInputSubmit, FormInputDate, FormInputEmail, FormInputTel, FormSelect, FormSelectMultiple, FormInputPhoto, FormBouton } from '../formulaires/elementsFormulaires';
import { getCategories } from '../../api/categories/getCategories'
import { postFiles } from '../../api/files/postFiles';
import { getDirigeants } from '../../api/dirigeants/getDirigeants';
import { getRoles } from '../../api/roles/getRoles'
import { insertDirigeants } from '../../api/dirigeants/insertDirigeants'
import { updateDirigeants } from '../../api/dirigeants/updateDirigeants';
import { getLicenceStatuts } from '../../api/licenceStatuts/getLicenceStatuts';
import { updateRestaurationSuppressionDirigeant } from '../../api/dirigeants/updateRestaurationSuppressionDirigeant'
import UcFirst from '../fonctions/UcFirst';
import Modal from '../Modal'
import { useNavigate } from 'react-router-dom';
import ModalOuiNon from '../modal/ModalOuiNon';
import { v4 as uuidv4 } from 'uuid'
import globalStorage from '../fonctions/GlobalStorage';

function InformationsDirigeants() {

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////
    // RECUPERATION ID ELEMENT //
    /////////////////////////////
    const location = useLocation();
    const currentURL = location.pathname;
    const parts = currentURL.split("/"); // Divise la chaîne en segments en utilisant le séparateur "/"
    const valeur = parts.pop(); // Récupère le dernier élément du tableau
    const IdElement = valeur.replace(/%20/g, " "); // Remplace les %20 en espace

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // VARIABLES //
    ///////////////

    // GLOBAL STORAGE
    const { gsSetTitreHeaderSecondaire } = globalStorage();

    const navigate = useNavigate();
    const [ListeCategories, setListeCategories] = useState([])
    const [SelectedCategoriesIds, setSelectedCategoriesIds] = useState([])
    const [ListeDirigeants, setListeDirigeants] = useState([])
    const [ListeRoles, setListeRoles] = useState([])
    const [ListeLicenceStatuts, setListeLicenceStatuts] = useState([])
    const [SelectedRolesIds, setSelectedRolesIds] = useState([])
    const [ShowModal, setShowModal] = useState(false);
    const [ContentModal, setContentModal] = useState(false);
    const [InfosModal, setInfosModal] = useState(false);
    const [ReloadPage, setReloadPage] = useState(false);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    const fetch = useCallback(async () => {

        // GET CATEGORIES
        const listeCategories = await getCategories();
        setListeCategories(listeCategories)

        // GET ROLES
        const listeRoles = await getRoles();
        setListeRoles(listeRoles)

        // GET LICENCE STATUTS
        const listeLicenceStatuts = await getLicenceStatuts();
        const nomsLicenceStatuts = listeLicenceStatuts.map(item => item.nom);
        setListeLicenceStatuts(nomsLicenceStatuts)

        // GET DIRIGEANTS
        const listeDirigeants = await getDirigeants();
        setListeDirigeants(listeDirigeants)

        if (IdElement !== 'ajouter') {
            const selectedDirigeant = listeDirigeants.filter(item => parseInt(item.id) === parseInt(IdElement) && item.statut !== 'supprimé')
            if (selectedDirigeant.length === 0) {
                navigate('/notFound');
            } else {
                setListeDirigeants(selectedDirigeant)
            }

            // GET ID CATEGORIES SELECTIONNES
            const selectedDirigeantCategories = selectedDirigeant[0].categories.toLowerCase(); // Nom sélectionné en minuscules
            const selectedCategoriesIds = listeCategories
                .filter(item => selectedDirigeantCategories.includes(item.nom.toLowerCase()))
                .map(item => item.id);
            setSelectedCategoriesIds(selectedCategoriesIds); // Un tableau d'IDs correspondants

            // GET ID ROLES SELECTIONNES
            const selectedDirigeantRoles = selectedDirigeant[0].roles.toLowerCase(); // Nom sélectionné en minuscules
            const selectedRolesIds = listeRoles
                .filter(item => selectedDirigeantRoles.includes(item.nom.toLowerCase()))
                .map(item => item.id);
            setSelectedRolesIds(selectedRolesIds); // Un tableau d'IDs correspondants

            // EDIT TITRE HEADER
            gsSetTitreHeaderSecondaire(selectedDirigeant[0].prenom+" "+selectedDirigeant[0].nom.toUpperCase())
        } else {
            // EDIT TITRE HEADER
            gsSetTitreHeaderSecondaire('Ajouter dirigeant')
        }
    }, [IdElement, gsSetTitreHeaderSecondaire, navigate]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const optionCategories = (array, handleClick, index, SelectedId) => {
        const isSelected = SelectedId && Array.isArray(SelectedId) && SelectedId.includes(array.id);

        return (
            <div
                className={'option-container bg-secondaire ' + (isSelected ? 'bg-selected' : '')}
                id={array.id}
                key={index}
                data-value={UcFirst(array.nom)} // Utilisation de l'attribut data-value
                data-id={array.id} // Utilisation de l'attribut data-value
                onClick={(e) => handleClick(e)}
            >
                {array.nom.toUpperCase()}
            </div>
        )
    }

    const optionRoles = (array, handleClick, index, SelectedId) => {
        const isSelected = SelectedId && Array.isArray(SelectedId) && SelectedId.includes(array.id);
        return (
            <div
                className={'option-container bg-secondaire ' + (isSelected ? 'bg-selected' : '')}
                id={array.id}
                key={index}
                data-value={UcFirst(array.nom)} // Utilisation de l'attribut data-value
                data-id={array.id} // Utilisation de l'attribut data-value
                onClick={(e) => handleClick(e)}
            >
                {array.nom.toUpperCase()}
            </div>
        )
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////
    // HANDLE //
    ////////////

    async function handleSubmit(e) {
        // VARIABLES
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const nom = formData.get('nom').toUpperCase();
        const prenom = UcFirst(formData.get('prenom').toLowerCase());
        let photo = formData.get('photo');
        const dateDeNaissance = formData.get('date de naissance');
        const email = formData.get('email').toLowerCase();
        const telephone = formData.get('téléphone');
        const categories = UcFirst(formData.get('catégories').toLowerCase());
        const roles = UcFirst(formData.get('rôles').toLowerCase());
        const numeroLicence = formData.get('numéro licence');
        const licence = UcFirst(formData.get('licence').toLowerCase());
        const statut = 'en ligne'
        const extension = photo.name.split('.').pop()

        // VERIFICATION
        const verifDirigeantExistant = ListeDirigeants.filter(item => item.nom.toLowerCase() === nom.toLowerCase() && item.prenom.toLowerCase() === prenom.toLowerCase())
        let verifIdDirigeantExistant;
        if (verifDirigeantExistant.length > 0 && IdElement !== "ajouter") {
            verifIdDirigeantExistant = ListeDirigeants.find(item => item.nom.toLowerCase() === nom.toLowerCase() && item.prenom.toLowerCase() === prenom.toLowerCase()).id
        }

        if (verifDirigeantExistant.length > 0 && parseInt(verifIdDirigeantExistant) !== parseInt(IdElement)) {
            const verifDirigeantstatut = ListeDirigeants.find(item => item.nom.toLowerCase() === nom.toLowerCase() && item.prenom.toLowerCase() === prenom.toLowerCase()).statut
            verifIdDirigeantExistant = ListeDirigeants.find(item => item.nom.toLowerCase() === nom.toLowerCase() && item.prenom.toLowerCase() === prenom.toLowerCase()).id
            if (verifDirigeantstatut === 'supprimé') {
                // AFFICHER MODAL
                const affichageModal = 'Voulez-vous restaurer le dirigeant suivant ?';
                setShowModal(true)
                setContentModal(
                    <ModalOuiNon handleClick={handleOuiNonClick} affichage={affichageModal} infos={prenom + ' ' + nom} var_name={'restauration'} var_id={verifIdDirigeantExistant} />
                )
            } else {
                // AFFICHER MODAL
                const affichageModal = 'Ce dirigeant existe déjà !';
                setShowModal(true)
                setContentModal(
                    <div>
                        {affichageModal}
                    </div>
                )
                setInfosModal(
                    <div>
                        {prenom + ' ' + nom}
                    </div>
                )
                setReloadPage(false)
            }
        } else {
            const selectedFile = photo
            const destDefaultPictures = 'default'
            const destPictures = 'photos/dirigeants'
            let postFileStatus = '';

            if (selectedFile.name) {
                // AJOUT PHOTO DANS LE SERVEUR
                let nomPhoto
                if (IdElement === 'ajouter') {
                    nomPhoto = uuidv4();
                } else {
                    nomPhoto = ListeDirigeants[0].photo.split('/')
                    nomPhoto = nomPhoto[nomPhoto.length - 1];
                    nomPhoto = nomPhoto.split('.');
                    nomPhoto = nomPhoto[0];

                    if (nomPhoto.includes('default-')) {
                        nomPhoto = uuidv4();
                    }
                }

                const fileInput = document.getElementById('input-photo'); // Remplacez par l'élément input de type "file" de votre formulaire
                formData.append('photo', fileInput);
                const postFile = await postFiles(nomPhoto, destPictures, formData);
                postFileStatus = postFile
                photo = '/' + destPictures + '/' + nomPhoto + '.' + extension
            } else {
                postFileStatus = "N/A"
                if (IdElement === 'ajouter') {
                    photo = '/' + destDefaultPictures + '/default-dirigeants.png'
                } else {
                    photo = ListeDirigeants[0].photo
                }
            }

            // AFFICHER MODAL
            setShowModal(true)

            if (postFileStatus === 200 || postFileStatus === 'N/A') {
                let affichageModal = "";
                let insertSql = [];
                let updateSql = [];

                if (IdElement === 'ajouter') {
                    // INSERTION DONNEES EN BASE
                    insertSql = await insertDirigeants(nom, prenom, photo, dateDeNaissance, email, telephone, categories, roles, numeroLicence, licence, statut);
                    affichageModal = 'Le dirigeant suivant a bien été ajouté';
                } else {
                    // MISE A JOUR DONNEES EN BASE
                    updateSql = await updateDirigeants(IdElement, nom, prenom, photo, dateDeNaissance, email, telephone, categories, roles, numeroLicence, licence, statut);
                    affichageModal = 'Le dirigeant suivant a bien été mis à jour';
                }

                if (insertSql.includes("SUCCESS") || updateSql.includes("SUCCESS")) {
                    setReloadPage(true)
                    setContentModal(
                        <div>{affichageModal}</div>
                    )
                    setInfosModal(
                        <div>{prenom} {nom}</div>
                    )
                }
            } else {
                setContentModal(
                    <div>
                        <div>
                            <i className="fa-solid fa-circle-xmark fa-beat" style={{ color: "#ff0000" }}></i> Une erreur s'est produite lors de l'envoie du formulaire
                            <div>{postFileStatus}</div>
                        </div>
                    </div>
                )
            }
        }
    };

    async function handleButtonClick(e) {
        const value = e.target.name

        const form = document.querySelector('.formulaire-container');
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const nom = formData.get('nom').toUpperCase();
        const prenom = UcFirst(formData.get('prenom').toLowerCase());


        if (value === 'supprimer') {
            // AFFICHER MODAL
            const affichageModal = 'Voulez-vous vraiment supprimer le dirigeant suivant ?';

            setShowModal(true)
            setContentModal(
                <ModalOuiNon handleClick={handleOuiNonClick} affichage={affichageModal} infos={prenom + ' ' + nom} var_name={'suppression'} />
            )
            setReloadPage(false)
        }
    }

    async function handleOuiNonClick(e) {
        const name = e.target.name
        const value = e.target.value
        const id = e.target.id

        const form = document.querySelector('.formulaire-container');
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const nom = formData.get('nom').toUpperCase();
        const prenom = UcFirst(formData.get('prenom').toLowerCase());
        let photo = ListeDirigeants[0].photo
        const dateDeNaissance = formData.get('date de naissance');
        const email = formData.get('email').toLowerCase();
        const telephone = formData.get('téléphone');
        const categories = UcFirst(formData.get('catégories').toLowerCase());
        const roles = UcFirst(formData.get('rôles').toLowerCase());
        const numeroLicence = formData.get('numéro licence');
        const licence = UcFirst(formData.get('licence').toLowerCase());

        if (name === 'suppression') {
            if (value === 'oui') {
                const statut = 'supprimé'

                // MISE A JOUR DONNEES EN BASE
                const updateSql = await updateDirigeants(IdElement, nom, prenom, photo, dateDeNaissance, email, telephone, categories, roles, numeroLicence, licence, statut);
                const affichageModal = 'Le dirigeant suivant a bien été supprimé';

                if (updateSql.includes("SUCCESS")) {
                    // AFFICHER MODAL
                    setContentModal(
                        <div>
                            <div>{affichageModal}</div><br />
                            <div className='cl-principalBold'>{prenom} {nom}</div>
                        </div>
                    )
                }
                setReloadPage(true)
            } else {
                setShowModal(false)
            }
        } else if (name === 'restauration') {
            if (value === 'oui') {
                const updateSql = await updateRestaurationSuppressionDirigeant(id, 'en ligne');
                if (updateSql.includes("SUCCESS")) {
                    const affichageModal = 'Le dirigeant suivant a bien été restauré !';
                    // AFFICHER MODAL
                    setContentModal(
                        <div>
                            <div>{affichageModal}</div><br />
                            <div className='cl-principalBold'>{prenom + ' ' + nom}</div>
                        </div>
                    )
                    setReloadPage(true)
                }
            } else {
                setShowModal(false)
            }
        }

    }

    async function handleReloadPageClick(e) {
        if (ReloadPage === true) {
            navigate('/dirigeants');
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className='informationsDirigeants-children'>
            <form
                className='formulaire-container'
                onSubmit={(e) => { handleSubmit(e) }}
            >
                <FormInputPhoto
                    var_readOnly={false}
                    var_nom={'photo'}
                    var_height='150px'
                    var_width='150px'
                    var_value={ListeDirigeants.length > 0 && IdElement !== 'ajouter' ? ListeDirigeants[0].photo : '/icones/ajouter image.png'}
                />
                <FormInputText
                    var_readOnly={false}
                    var_nom={'nom'}
                    var_class={"bg-main"}
                    var_required={true}
                    var_value={ListeDirigeants.length > 0 && IdElement !== 'ajouter' ? ListeDirigeants[0].nom : ''}
                />
                <FormInputText
                    var_readOnly={false}
                    var_nom={'prenom'}
                    var_class={"bg-main"}
                    var_required={true}
                    var_value={ListeDirigeants.length > 0 && IdElement !== 'ajouter' ? ListeDirigeants[0].prenom : ''}
                />
                <FormInputDate
                    var_readOnly={false}
                    var_nom={'date de naissance'}
                    var_class={"bg-main"}
                    var_required={true}
                    var_value={ListeDirigeants.length > 0 && IdElement !== 'ajouter' ? ListeDirigeants[0].date_de_naissance : ''}
                />
                <FormInputEmail
                    var_readOnly={false}
                    var_nom={'email'}
                    var_class={"bg-main"}
                    var_value={ListeDirigeants.length > 0 && IdElement !== 'ajouter' ? ListeDirigeants[0].email : ''}
                />
                <FormInputTel
                    var_readOnly={false}
                    var_nom={'téléphone'}
                    var_class={"bg-main"}
                    var_value={ListeDirigeants.length > 0 && IdElement !== 'ajouter' ? ListeDirigeants[0].telephone : ''}
                />
                <FormSelectMultiple
                    var_option={optionCategories}
                    var_nom={'catégories'}
                    var_class={"bg-main categories"}
                    var_array={ListeCategories}
                    // var_height={'100px'}
                    var_value={ListeDirigeants.length > 0 && IdElement !== 'ajouter' ? ListeDirigeants[0].categories.toUpperCase() : ''}
                    var_selectedIds={SelectedCategoriesIds}
                    var_required={false}
                />
                <FormSelectMultiple
                    var_option={optionRoles}
                    var_nom={'rôles'}
                    var_class={"bg-main roles"}
                    var_array={ListeRoles}
                    // var_height={'100px'}
                    var_value={ListeDirigeants.length > 0 && IdElement !== 'ajouter' ? ListeDirigeants[0].roles.toUpperCase() : ''}
                    var_selectedIds={SelectedRolesIds}
                    var_required={true}
                />
                <FormInputTel
                    var_readOnly={false}
                    var_nom={'numéro licence'}
                    var_class={"bg-main"}
                    var_value={ListeDirigeants.length > 0 && IdElement !== 'ajouter' ? ListeDirigeants[0].numero_licence : ''}
                />
                <FormSelect
                    var_readOnly={false}
                    var_nom={'licence'}
                    var_class={"bg-main"}
                    var_array={ListeLicenceStatuts}
                    var_required={true}
                    var_value={ListeDirigeants.length > 0 && IdElement !== 'ajouter' ? ListeDirigeants[0].licence : ''}
                />
                <FormInputSubmit
                    var_nom={'submit'}
                    var_value={IdElement === 'ajouter' ? 'ajouter' : 'sauvegarder'}
                    var_class={'bg-principal'}
                />
                {IdElement !== 'ajouter' && (
                    <FormBouton
                        var_nom={'supprimer'}
                        var_value={'supprimer'}
                        var_class={'bg-supprimer'}
                        var_handleButtonClick={handleButtonClick}
                    />
                )}
            </form>

            <Modal
                showModal={ShowModal}
                contentModal={ContentModal}
                handleCloseModal={() => {
                    setShowModal(false);
                    setContentModal("");
                    handleReloadPageClick();
                }}
                infosModal={InfosModal}
            />
        </div>
    )
}

export default InformationsDirigeants;
