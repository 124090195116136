import React, { useState, useEffect, useCallback } from 'react';
import Modal from '../Modal';
import { FormSelectMultiple } from '../formulaires/elementsFormulaires'
import UcFirst from '../fonctions/UcFirst';
import { getCategories } from '../../api/categories/getCategories';

function BoutonRecherche({ recherche, handleRechercheChange }) {
    ///////////////
    // VARIABLES //
    ///////////////

    const [ShowModal, setShowModal] = useState(false);
    const [ContentModal, setContentModal] = useState(false);

    const [ListeCategories, setListeCategories] = useState([]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////
    // RECUPERATION INFOS BDD //
    ////////////////////////////
    const fetch = useCallback(async () => {
        // GET CATEGORIES
        const listeCategories = await getCategories();
        setListeCategories(listeCategories)
    }, []);

    useEffect(() => {
        fetch();
    }, [fetch]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    // CLIC BOUTON FILTRE
    const handleFilterClick = () => {
        setShowModal(true)
        setContentModal(AffichageBoutonsFiltres)
        console.log('FILTER')
    }

    // AFFICHAGE BOUTONS FILTRES
    const AffichageBoutonsFiltres = () => (
        <div className='formulaire-container filtres-container'>
            <FormSelectMultiple var_nom={'Catégorie'} var_array={ListeCategories} var_option={optionCategorie} var_height={'100px'} var_class={''} />
        </div>
    );

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////
    // OPTIONS //
    /////////////

    const optionCategorie = (array, handleClick, index, SelectedId) => {
        return (
            <div
                className={SelectedId.filter(itemFilter => parseInt(itemFilter) === parseInt(array.id)) > 0 ? 'bg-selected' : ""}
                id={array.id}
                onClick={(e) => handleClick(e)}
                key={index}
                data-value={UcFirst(array.nom.toLowerCase())} // Utilisation de l'attribut data-value
                data-id={array.id} // Utilisation de l'attribut data-value
            // 
            >
                <div className='infosJoueur'>
                    {UcFirst(array.nom.toLowerCase())}<br />
                </div>
            </div>
        )
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className='tuiles-rechercheContainer'>
            <input
                type='text'
                value={recherche}
                onChange={handleRechercheChange}
                placeholder='Recherche ...'
            />

            <button onClick={handleFilterClick}>
                Filtres
            </button>

            <Modal
                showModal={ShowModal}
                contentModal={ContentModal}
                handleCloseModal={() => {
                    setShowModal(false);
                    setContentModal("");
                }}
            />

        </div>
    );
}

export default BoutonRecherche;
