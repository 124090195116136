import React, { useRef } from 'react';
import Draggable from 'react-draggable';
import '../../styles/AfficherTest.css';


function AfficherTest() {
    const unElementRef = useRef(null);
    const deuxElementRef = useRef(null);
    const troisElementRef = useRef(null);
    const quatreElementRef = useRef(null);

    return (
        <div className='element-container'>
            <Draggable nodeRef={unElementRef}>
                <div ref={unElementRef} className='element un'></div>
            </Draggable>
            <Draggable nodeRef={deuxElementRef}>
                <div ref={deuxElementRef} className='element deux'></div>
            </Draggable>
            <Draggable nodeRef={troisElementRef}>
                <div ref={troisElementRef} className='element trois'></div>
            </Draggable>
            <Draggable nodeRef={quatreElementRef}>
                <div ref={quatreElementRef} className='element quatre'></div>
            </Draggable>
        </div>
    );
}

export default AfficherTest;