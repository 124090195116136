import axios from "axios";

export async function updateMatchsInfosScores(id_match, score_hote, score_visiteur){
    try{
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}updateMatchsInfosScores/?id_match=${id_match}&score_hote=${score_hote}&score_visiteur=${score_visiteur}`)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `Insert MatchsInfosScores [${id_match}] in database : SUCCES !`
            return msg;
        } 
    } catch (error) {
        console.log(error);
    }
}
