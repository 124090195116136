import axios from "axios";

export async function updateMatchs(
    id_match,
    nom,
    description,
    id_categorie,
    id_competition,
    id_adversaire,
    emplacement,
    date,
    heure_match,
    heure_rdv,
    lieu_rdv,
    id_stade,
    score,
    resultat,
    nb_mi_temps,
    duree_mi_temps,
    nb_prolongation,
    duree_prolongation,
    statut
) {
    try {
        const { data } = await axios.get(`
            ${process.env.REACT_APP_API_URL}updateMatchs/?id_match=${id_match}&nom=${nom}&description=${description}&id_categorie=${id_categorie}&id_competition=${id_competition}&id_adversaire=${id_adversaire}&emplacement=${emplacement}&date=${date}&heure_match=${heure_match}&heure_rdv=${heure_rdv}&lieu_rdv=${lieu_rdv}&id_stade=${id_stade}&score=${score}&resultat=${resultat}&nb_mi_temps=${nb_mi_temps}&duree_mi_temps=${duree_mi_temps}&nb_prolongation=${nb_prolongation}&duree_prolongation=${duree_prolongation}&statut=${statut}
        `)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `SUCCESS`
            return msg;
        }
    } catch (error) {
        console.log(error);
    }
}
