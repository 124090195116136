import React, { } from 'react';
import AfficherEntrainements from '../components/entrainements/AfficherEntrainements';
import AfficherGroupesEntrainements from '../components/entrainements/AfficherGroupesEntrainements';
import AfficherInformationsEntrainements from '../components/entrainements/AfficherInformationsEntrainements';
import AfficherPresencesEntrainements from '../components/entrainements/AfficherPresencesEntrainements';
import AfficherInformationsGroupesEntrainements from '../components/entrainements/AfficherInformationsGroupesEntrainements';

//////////////////
// ENTRAINEMENT //
//////////////////

const Entrainements = () => {
    return (
        <div className='entrainements-container'>
            <AfficherEntrainements />
        </div>
    );
};

const AjouterEntrainements = () => {
    return (
        <div className='informationsEntrainements-container'>
            <AfficherInformationsEntrainements />
        </div>
    );
};

const InformationsEntrainements = () => {
    return (
        <div className='informationsEntrainements-container'>
            <AfficherInformationsEntrainements />
        </div>
    );
};

const PresencesEntrainements = () => {
    return (
        <div className='presencesEntrainements-container'>
            <AfficherPresencesEntrainements />
        </div>
    );
};

/////////////////////////
// GROUPE ENTRAINEMENT //
/////////////////////////

const GroupesEntrainements = () => {
    return (
        <div className='groupesEntrainements-container'>
            <AfficherGroupesEntrainements />
        </div>
    );
};

const AjouterGroupesEntrainements = () => {
    return (
        <div className='informationsGroupesEntrainements-container'>
            <AfficherInformationsEntrainements />
        </div>
    );
};

const InformationsGroupesEntrainements = () => {
    return (
        <div className='informationsGroupesEntrainements-container'>
            <AfficherInformationsGroupesEntrainements />
        </div>
    );
};

export {
    Entrainements,
    AjouterEntrainements,
    InformationsEntrainements,
    PresencesEntrainements,

    GroupesEntrainements,
    AjouterGroupesEntrainements,
    InformationsGroupesEntrainements
};