import axios from "axios";

export async function updateMatchsInfos(id_match, debut_match, debut_mi_temps, fin_match, mi_temps, statut_match, bouton_match, score_hote, score_visiteur){
    try{
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}updateMatchsInfos/?id_match=${id_match}&debut_match=${debut_match}&debut_mi_temps=${debut_mi_temps}&fin_match=${fin_match}&mi_temps=${mi_temps}&statut_match=${statut_match}&bouton_match=${bouton_match}&score_hote=${score_hote}&score_visiteur=${score_visiteur}`)
        if (JSON.stringify(data.affectedRows)) {
            const msg = `Update MatchsInfos [${id_match}] in database : SUCCES !`
            return msg;
        } 
    } catch (error) {
        console.log(error);
    }
}
