// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*///////////////////
// VUE PRINCIPALES //
///////////////////*/

.element-container{
    border: solid;
    margin-top: 40px;
    display: flex;
    gap: 10px;
}

.element{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.element.un{
    background-color: blue;
}

.element.deux{
    background-color: red;
}

.element.trois{
    background-color: green;
}

.element.quatre{
    background-color: purple;
}`, "",{"version":3,"sources":["webpack://./src/styles/Test.css"],"names":[],"mappings":"AAAA;;oBAEoB;;AAEpB;IACI,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":["/*///////////////////\n// VUE PRINCIPALES //\n///////////////////*/\n\n.element-container{\n    border: solid;\n    margin-top: 40px;\n    display: flex;\n    gap: 10px;\n}\n\n.element{\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n}\n\n.element.un{\n    background-color: blue;\n}\n\n.element.deux{\n    background-color: red;\n}\n\n.element.trois{\n    background-color: green;\n}\n\n.element.quatre{\n    background-color: purple;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
