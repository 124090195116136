import React, { useContext, useEffect, useState, useCallback } from 'react';
import { SettingsContext } from '../settings/RecuperationSettings';
import BoutonAction from '../boutons/boutonAction';
import { getGroupesEntrainements } from '../../api/entrainements/getGroupesEntrainements';
import { getJoueurs } from '../../api/joueurs/getJoueurs';
import Tuile from '../tuiles/tuiles';
import { useNavigate, useLocation } from 'react-router-dom';
import UcFirst from '../fonctions/UcFirst'
import BoutonRecherche from '../boutons/boutonRecherche';
import globalStorage from '../fonctions/GlobalStorage';

function AfficherGroupesEntrainements() {

    ///////////////
    // VARIABLES //
    ///////////////

    // GLOBAL STORAGE
    const {gsSetTitreHeaderSecondaire} = globalStorage();

    const [ListeGroupesEntrainements, setListeGroupesEntrainements] = useState([]);
    const [ListeJoueurs, setListeJoueurs] = useState([]);
    const [NbElements, setNbElements] = useState([]);
    const navigate = useNavigate(); // Utilisez useNavigate

    // RECUPERER PARAMETRE DE RECHERCHE
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const UrlRecherche = searchParams.get('recherche') || ''; // Obtenez la valeur de recherche de la chaîne de requête

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////
    // SETTINGS //
    //////////////

    const { settings } = useContext(SettingsContext);
    let WidthTuiles = '';

    if (Array.isArray(settings)) {
        const settingsWidthTuiles = settings.find((setting) => setting.name === 'WIDTH_TUILES');
        WidthTuiles = settingsWidthTuiles?.value || '';
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    const fetch = useCallback(async () => {
        // GET ENTRAINEMENTS
        let listeGroupesEntrainements = await getGroupesEntrainements();
        listeGroupesEntrainements = listeGroupesEntrainements.filter(item => item.statut !== 'supprimé')
        setListeGroupesEntrainements(listeGroupesEntrainements);

        // GET STADES
        let listeJoueurs = await getJoueurs();
        listeJoueurs = listeJoueurs.filter(item => item.statut !== 'supprimé')
        setListeJoueurs(listeJoueurs);
    }, []);

    useEffect(() => {
        fetch();

        // EDIT TITRE HEADER
        gsSetTitreHeaderSecondaire('Groupes entrainements')
    }, [fetch,gsSetTitreHeaderSecondaire]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////
    // HANDLE CLICK //
    //////////////////

    const handleBoutonClick = (e) => {
        navigate("ajouter")
    };

    async function handleOnClick(e) {
        const id = e.target.id;

        if (Recherche !== '') {
            navigate(`?recherche=${Recherche}`);
        }

        navigate(id);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////
    // FILTRES //
    /////////////

    const [Recherche, setRecherche] = useState(UrlRecherche);

    const handleRechercheChange = (e) => {
        setRecherche(e.target.value);
        if (e.target.value === '') {
            navigate('')
        }
    };

    const filtered = ListeGroupesEntrainements.filter((result) => {
        const nomComplet =
            result.nom.toLowerCase();

        const listeJoueurs = ListeJoueurs.find(itemFind => parseInt(itemFind.id) === parseInt(result.nom));
        const nomJoueur = listeJoueurs ? listeJoueurs.nom.toLowerCase() : '';
        return (
            nomComplet.includes(Recherche.toLowerCase()) ||
            nomJoueur.includes(Recherche.toLowerCase())
        );
    });

    // Déplacez setNbElements ici
    useEffect(() => {
        setNbElements(filtered.length);
    }, [filtered]);

    return (
        <div className='groupesEntrainements-children'>
            <div>
                <div className='boutons-container'>
                    <BoutonAction content={"Ajouter un groupe"} addClass={'bg-principal'} handleBoutonClick={handleBoutonClick} />
                </div>

                <div className='boutonRecherche-container'>
                    <BoutonRecherche recherche={Recherche} handleRechercheChange={handleRechercheChange} />
                </div>

                <div className='infosTuile-container'>{"Nb de groupes : " + NbElements}</div>
                <div className='tuile-container ' style={{ maxWidth: 'calc((20px * ' + NbElements + ') + (' + WidthTuiles + ' * ' + NbElements + '))' }}>
                    {ListeJoueurs.length > 0 ? (
                        filtered.map((item, index) => {
                            return (
                                <div className='key' key={index}>
                                    <Tuile
                                        var_handleOnClick={handleOnClick}
                                        var_id={item.id}
                                        var_image={item.photo}
                                        var_infos={[
                                            UcFirst(item.nom.toUpperCase())
                                        ]}
                                    />
                                </div>
                            )
                        })
                    ) : (
                        <div>Aucun élément trouvé.</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AfficherGroupesEntrainements;
