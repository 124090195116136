import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormInputText, FormInputSubmit, FormInputDate, FormInputEmail, FormInputTel, FormSelect, FormInputPhoto, FormBouton, FormSelectMultiple, FormTextarea } from '../formulaires/elementsFormulaires';
import { getCategories } from '../../api/categories/getCategories'
import { postFiles } from '../../api/files/postFiles';
import { getJoueurs } from '../../api/joueurs/getJoueurs';
import { getPostes } from '../../api/postes/getPostes';
import { getPiedFort } from '../../api/piedFort/getPiedFort';
import { getLicenceStatuts } from '../../api/licenceStatuts/getLicenceStatuts';
import { getGroupesEntrainements } from '../../api/entrainements/getGroupesEntrainements';
import { insertJoueurs } from '../../api/joueurs/insertJoueurs'
import { updateJoueurs } from '../../api/joueurs/updateJoueurs';
import { updateRestaurationSuppressionJoueur } from '../../api/joueurs/updateRestaurationSuppressionJoueur'
import UcFirst from '../fonctions/UcFirst';
import Modal from '../Modal'
import { useNavigate } from 'react-router-dom';
import ModalOuiNon from '../modal/ModalOuiNon';
import { v4 as uuidv4 } from 'uuid'
import { updateGroupesEntrainementsJoueurs } from '../../api/entrainements/updateGroupesEntrainementsJoueurs';
import { getMutations } from '../../api/mutations/getMutations';
import globalStorage from '../fonctions/GlobalStorage';


function InformationsJoueurs() {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////
    // RECUPERATION ID ELEMENT //
    /////////////////////////////
    const location = useLocation();
    const currentURL = location.pathname;
    const parts = currentURL.split("/"); // Divise la chaîne en segments en utilisant le séparateur "/"
    const valeur = parts.pop(); // Récupère le dernier élément du tableau
    const IdElement = valeur.replace(/%20/g, " "); // Remplace les %20 en espace

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // VARIABLES //
    ///////////////

    // GLOBAL STORAGE
    const { gsSetTitreHeaderSecondaire } = globalStorage();

    const navigate = useNavigate();
    const [ListeCategories, setListeCategories] = useState([])
    const [ListeJoueurs, setListeJoueurs] = useState([])
    const [ListePostes, setListePostes] = useState([])
    const [ListeGroupesEntrainements, setListeGroupesEntrainements] = useState([])
    const [ListeGroupesEntrainementsSelected, setListeGroupesEntrainementsSelected] = useState([])
    const [SelectedGroupesEntrainementsIds, setSelectedGroupesEntrainementsIds] = useState([])
    const [SelectedPostesIds, setSelectedPostesIds] = useState([])
    const [ListePiedFort, setListePiedFort] = useState([])
    const [ListeMutations, setListeMutations] = useState([])
    const [ListeLicenceStatuts, setListeLicenceStatuts] = useState([])
    const [ShowModal, setShowModal] = useState(false);
    const [ContentModal, setContentModal] = useState(false);
    const [InfosModal, setInfosModal] = useState(false);
    const [ReloadPage, setReloadPage] = useState(false);


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////
    // FONCTIONS //
    ///////////////

    // GET MATCH
    const fetch = useCallback(async () => {
        // GET JOUEURS
        const listeJoueurs = await getJoueurs();
        setListeJoueurs(listeJoueurs)

        // GET GROUPE ENTRAINEMENTS
        const listeGroupesEntrainements = await getGroupesEntrainements();
        setListeGroupesEntrainements(listeGroupesEntrainements)

        // GET CATEGORIES
        const listeCategories = await getCategories();
        const nomsCategories = listeCategories.map(item => item.nom);
        setListeCategories(nomsCategories)

        // GET POSTE
        const listePostes = await getPostes();
        setListePostes(listePostes)

        // GET PIED FORT
        const listePiedFort = await getPiedFort();
        setListePiedFort(listePiedFort)

        // GET MUTATION
        const listeMutations = await getMutations();
        setListeMutations(listeMutations)

        // GET LICENCE STATUTS
        const listeLicenceStatuts = await getLicenceStatuts();
        const nomsLicenceStatuts = listeLicenceStatuts.map(item => item.nom);
        setListeLicenceStatuts(nomsLicenceStatuts)

        if (IdElement !== 'ajouter') {
            const selectedJoueur = listeJoueurs.filter(item => parseInt(item.id) === parseInt(IdElement) && item.statut !== 'supprimé')
            if (selectedJoueur.length === 0) {
                navigate('/notFound');
            } else {
                let listeGroupesEntrainementsSelected = listeGroupesEntrainements.filter(item => item.joueurs.includes(IdElement))
                listeGroupesEntrainementsSelected = listeGroupesEntrainementsSelected.map(item => item.nom)
                setListeGroupesEntrainementsSelected(listeGroupesEntrainementsSelected.join(', '))

                setListeJoueurs(selectedJoueur)
            }

            // GET ID ROLES SELECTIONNES
            const selectedJoueursPostes = selectedJoueur[0].postes.toLowerCase(); // Postes sélectionnés en minuscules
            const selectedPostesIds = listePostes
                .filter(item => selectedJoueursPostes.split(', ').includes(item.nom.toLowerCase()))
                .map(item => item.id);
            setSelectedPostesIds(selectedPostesIds); // Un tableau d'IDs correspondants

            // GET ID GROUPES ENTRAINEMENTS SELECTIONNES
            let selectedGroupesEntrainementsIds = listeGroupesEntrainements.filter(item => item.joueurs.includes(IdElement))
            selectedGroupesEntrainementsIds = selectedGroupesEntrainementsIds.map(item => item.id)
            setSelectedGroupesEntrainementsIds(selectedGroupesEntrainementsIds); // Un tableau d'IDs correspondants

            // EDIT TITRE HEADER
            gsSetTitreHeaderSecondaire(selectedJoueur[0].prenom+" "+selectedJoueur[0].nom.toUpperCase())
        } else {
            // EDIT TITRE HEADER
            gsSetTitreHeaderSecondaire('Ajouter joueur')
        }
    }, [IdElement, gsSetTitreHeaderSecondaire, navigate]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const options = (array, handleClick, index, SelectedId, maxValueReached) => {
        const isSelected = SelectedId && Array.isArray(SelectedId) && SelectedId.includes(array.id);

        return (
            <div
                className={'option-container bg-secondaire ' + (isSelected ? 'bg-selected' : maxValueReached === true ? 'general-desactiverClic' : '')}
                id={array.id}
                key={index}
                data-value={UcFirst(array.nom)} // Utilisation de l'attribut data-value
                data-id={array.id} // Utilisation de l'attribut data-value
                onClick={(e) => handleClick(e)}
            >
                {UcFirst(array.nom.toUpperCase())}
            </div>
        )
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////
    // HANDLE //
    ////////////

    async function handleSubmit(e) {
        // VARIABLES
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const nom = UcFirst(formData.get('nom').toLowerCase());
        const prenom = UcFirst(formData.get('prenom').toLowerCase());
        let photo = formData.get('photo');
        const dateDeNaissance = formData.get('date de naissance');
        const email = formData.get('email').toLowerCase();
        const telephoneJoueur = formData.get('téléphone joueur');
        const telephonePere = formData.get('téléphone père');
        const telephoneMere = formData.get('téléphone mère');
        const categorie = UcFirst(formData.get('catégorie').toLowerCase());
        const postes = formData.get('postes');
        const piedFort = UcFirst(formData.get('pied fort').toLowerCase());
        const numeroLicence = formData.get('numéro licence');
        const licence = UcFirst(formData.get('licence').toLowerCase());
        const mutation = UcFirst(formData.get('mutation').toLowerCase());
        const commentaires = formData.get('commentaires');
        const statut = 'en ligne'
        const extension = photo.name.split('.').pop()

        const groupesEntrainements = formData.get('groupe entrainement_tabIds');

        // VERIFICATION
        const verifJoueurExistant = ListeJoueurs.filter(item => item.nom.toLowerCase() === nom.toLowerCase() && item.prenom.toLowerCase() === prenom.toLowerCase())
        let verifIdJoueurExistant;
        if (verifJoueurExistant.length > 0 && IdElement !== "ajouter") {
            verifIdJoueurExistant = ListeJoueurs.find(item => item.nom.toLowerCase() === nom.toLowerCase() && item.prenom.toLowerCase() === prenom.toLowerCase()).id
        }

        if (verifJoueurExistant.length > 0 && parseInt(verifIdJoueurExistant) !== parseInt(IdElement)) {
            const verifJoueurStatut = ListeJoueurs.find(item => item.nom.toLowerCase() === nom.toLowerCase() && item.prenom.toLowerCase() === prenom.toLowerCase()).statut
            verifIdJoueurExistant = ListeJoueurs.find(item => item.nom.toLowerCase() === nom.toLowerCase() && item.prenom.toLowerCase() === prenom.toLowerCase()).id
            if (verifJoueurStatut === 'supprimé') {
                // AFFICHER MODAL
                const affichageModal = 'Voulez-vous restaurer le joueur suivant ?';
                setShowModal(true)
                setContentModal(
                    <ModalOuiNon handleClick={handleOuiNonClick} affichage={affichageModal} infos={prenom + ' ' + nom} var_name={'restauration'} var_id={verifIdJoueurExistant} />
                )
            } else {
                // AFFICHER MODAL
                const affichageModal = 'Ce joueur existe déjà !';
                setShowModal(true)
                setContentModal(
                    <div>
                        {affichageModal}
                    </div>
                )
                setInfosModal(
                    <div>
                        {prenom + ' ' + nom}
                    </div>
                )
                setReloadPage(false)
            }
        } else {
            const selectedFile = photo
            const destDefaultPictures = 'default'
            const destPictures = 'photos/joueurs'
            let postFileStatus = '';

            if (selectedFile.name) {
                // AJOUT PHOTO DANS LE SERVEUR
                let nomPhoto
                if (IdElement === 'ajouter' || selectedFile.name.includes('default-')) {
                    nomPhoto = uuidv4();
                } else {
                    nomPhoto = ListeJoueurs[0].photo.split('/')
                    nomPhoto = nomPhoto[nomPhoto.length - 1];
                    nomPhoto = nomPhoto.split('.');
                    nomPhoto = nomPhoto[0];

                    if (nomPhoto.includes('default-')) {
                        nomPhoto = uuidv4();
                    }
                }

                const fileInput = document.getElementById('input-photo'); // Remplacez par l'élément input de type "file" de votre formulaire
                formData.append('photo', fileInput);
                const postFile = await postFiles(nomPhoto, destPictures, formData);
                // const postFile = 200
                postFileStatus = postFile
                photo = '/' + destPictures + '/' + nomPhoto + '.' + extension
            } else {
                postFileStatus = "N/A"
                if (IdElement === 'ajouter') {
                    photo = '/' + destDefaultPictures + '/default-joueurs.png'
                } else {
                    photo = ListeJoueurs[0].photo
                }
            }

            // AFFICHER MODAL
            setShowModal(true)

            if (postFileStatus === 200 || postFileStatus === 'N/A') {
                let affichageModal = "";
                let insertSql = [];
                let updateSql = [];

                if (IdElement === 'ajouter') {
                    // INSERTION DONNEES EN BASE
                    insertSql = await insertJoueurs(nom, prenom, photo, dateDeNaissance, email, telephoneJoueur, telephonePere, telephoneMere, categorie, postes, piedFort, numeroLicence, licence, mutation, commentaires, statut);
                    console.log(insertSql);

                    if (groupesEntrainements !== '') {
                        const listeJoueurs = await getJoueurs()
                        let idJoueurInsert = listeJoueurs.filter(item => item.nom === nom && item.prenom === prenom);
                        idJoueurInsert = idJoueurInsert[0].id

                        // MAJ TABLE GROUPES ENTRAINEMENTS
                        let tabGroupesEntrainementsSelected = groupesEntrainements.split(', ')

                        for (const element of tabGroupesEntrainementsSelected) {
                            const groupeEntrainement = ListeGroupesEntrainements.filter(item => item.id === parseInt(element))
                            let ListeJoueursNew = groupeEntrainement[0].joueurs + ', ' + idJoueurInsert
                            const idGroupeEntrainement = groupeEntrainement[0].id

                            if (tabGroupesEntrainementsSelected.includes(element.toString())) {
                                ListeJoueursNew = ListeJoueursNew.split(', ')
                                ListeJoueursNew = [...new Set(ListeJoueursNew)];
                                ListeJoueursNew = ListeJoueursNew.join(', ')
                                updateSql = await updateGroupesEntrainementsJoueurs(idGroupeEntrainement, ListeJoueursNew);
                            }
                            console.log(updateSql);
                        }
                    }

                    affichageModal = 'Le joueur suivant a bien été ajouté';
                } else {
                    // MISE A JOUR DONNEES EN BASE              
                    updateSql = await updateJoueurs(IdElement, nom, prenom, photo, dateDeNaissance, email, telephoneJoueur, telephonePere, telephoneMere, categorie, postes, piedFort, numeroLicence, licence, mutation, commentaires, statut);
                    console.log(updateSql);

                    // MAJ TABLE GROUPES ENTRAINEMENTS
                    let tabGroupesEntrainements = ListeGroupesEntrainements.map(item => item.id)
                    let tabGroupesEntrainementsSelected = groupesEntrainements.split(', ')

                    for (const element of tabGroupesEntrainements) {
                        const groupeEntrainement = ListeGroupesEntrainements.filter(item => item.id === parseInt(element))
                        let listeJoueursOri = groupeEntrainement[0].joueurs
                        let ListeJoueursNew = groupeEntrainement[0].joueurs + ', ' + IdElement
                        const idGroupeEntrainement = groupeEntrainement[0].id

                        if (tabGroupesEntrainementsSelected.includes(element.toString())) {
                            ListeJoueursNew = ListeJoueursNew.split(', ')
                            ListeJoueursNew = [...new Set(ListeJoueursNew)];
                            ListeJoueursNew = ListeJoueursNew.join(', ')
                            if (groupeEntrainement[0].joueurs === '') {
                                ListeJoueursNew = ListeJoueursNew.slice(2)
                            }
                            updateSql = await updateGroupesEntrainementsJoueurs(idGroupeEntrainement, ListeJoueursNew);
                        } else {
                            listeJoueursOri = ', ' + listeJoueursOri + ','
                            listeJoueursOri = listeJoueursOri.replace(', ' + IdElement + ',', '').slice(2)
                            if (listeJoueursOri.charAt(listeJoueursOri.length - 1) === ',') {
                                listeJoueursOri = listeJoueursOri.slice(0, -1);
                            }
                            updateSql = await updateGroupesEntrainementsJoueurs(idGroupeEntrainement, listeJoueursOri);
                        }
                        console.log(updateSql);
                    }

                    affichageModal = 'Le joueur suivant a bien été mis à jour';
                }

                if (insertSql.includes("SUCCESS") || updateSql.includes("SUCCESS")) {
                    setReloadPage(true)
                    setContentModal(
                        <div>{affichageModal}</div>
                    )
                    setInfosModal(
                        <div>{prenom} {nom.toUpperCase()}</div>
                    )
                }
            } else {
                setContentModal(
                    <div>
                        <div>
                            <i className="fa-solid fa-circle-xmark fa-beat" style={{ color: "#ff0000" }}></i> Une erreur s'est produite lors de l'envoie du formulaire
                            <div>{postFileStatus}</div>
                        </div>
                    </div>
                )
            }
        }
    };

    async function handleButtonClick(e) {
        const value = e.target.name

        const form = document.querySelector('.formulaire-container');
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const nom = UcFirst(formData.get('nom').toLowerCase());
        const prenom = UcFirst(formData.get('prenom').toLowerCase());


        if (value === 'supprimer') {
            // AFFICHER MODAL
            const affichageModal = 'Voulez-vous vraiment supprimer le joueur suivant ?';

            setShowModal(true)
            setContentModal(
                <ModalOuiNon handleClick={handleOuiNonClick} affichage={affichageModal} infos={prenom + ' ' + nom} var_name={'suppression'} />
            )
            setReloadPage(false)
        }
    }

    async function handleOuiNonClick(e) {
        const name = e.target.name
        const value = e.target.value
        const id = e.target.id

        const form = document.querySelector('.formulaire-container');
        const formData = new FormData(form);

        // VARIABLES FORMULAIRE
        const nom = UcFirst(formData.get('nom').toLowerCase());
        const prenom = UcFirst(formData.get('prenom').toLowerCase());
        let photo = ListeJoueurs[0].photo
        const dateDeNaissance = formData.get('date de naissance');
        const email = formData.get('email').toLowerCase();
        const telephoneJoueur = formData.get('téléphone joueur');
        const telephonePere = formData.get('téléphone père');
        const telephoneMere = formData.get('téléphone mère');
        const categorie = UcFirst(formData.get('catégorie').toLowerCase());
        const postes = formData.get('postes');
        const piedFort = UcFirst(formData.get('pied fort').toLowerCase());
        const numeroLicence = formData.get('numéro licence');
        const licence = UcFirst(formData.get('licence').toLowerCase());
        const mutation = formData.get('mutation');
        const commentaires = formData.get('commentaires');

        if (name === 'suppression') {
            if (value === 'oui') {
                const statut = 'supprimé'

                // MISE A JOUR DONNEES EN BASE
                const updateSql = await updateJoueurs(IdElement, nom, prenom, photo, dateDeNaissance, email, telephoneJoueur, telephonePere, telephoneMere, categorie, postes, piedFort, numeroLicence, licence, mutation, commentaires, statut);
                const affichageModal = 'Le joueur suivant a bien été supprimé';

                if (updateSql.includes("SUCCESS")) {
                    // AFFICHER MODAL
                    setContentModal(
                        <div>
                            <div>{affichageModal}</div><br />
                            <div className='cl-principalBold'>{prenom} {nom.toUpperCase()}</div>
                        </div>
                    )
                }
                setReloadPage(true)
            } else {
                setShowModal(false)
            }
        } else if (name === 'restauration') {
            if (value === 'oui') {
                const updateSql = await updateRestaurationSuppressionJoueur(id, 'en ligne');
                if (updateSql.includes("SUCCESS")) {
                    const affichageModal = 'Le joueur suivant a bien été restauré !';
                    // AFFICHER MODAL
                    setContentModal(
                        <div>
                            <div>{affichageModal}</div><br />
                            <div className='cl-principalBold'>{prenom + ' ' + nom}</div>
                        </div>
                    )
                    setReloadPage(true)
                }
            } else {
                setShowModal(false)
            }
        }

    }

    async function handleReloadPageClick(e) {
        if (ReloadPage === true) {
            navigate('/joueurs');
        }
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className='informationsJoueurs-children'>
            <form
                className='formulaire-container'
                onSubmit={(e) => { handleSubmit(e) }}
            >
                <FormInputPhoto
                    var_readOnly={false}
                    var_nom={'photo'}
                    var_height='150px'
                    var_width='150px'
                    var_value={ListeJoueurs.length > 0 && IdElement !== 'ajouter' ? ListeJoueurs[0].photo : '/icones/ajouter image.png'}
                />
                <FormInputText
                    var_readOnly={false}
                    var_nom={'nom'}
                    var_class={"bg-main"}
                    var_required={true}
                    var_value={ListeJoueurs.length > 0 && IdElement !== 'ajouter' ? ListeJoueurs[0].nom : ''}
                />
                <FormInputText
                    var_readOnly={false}
                    var_nom={'prenom'}
                    var_class={"bg-main"}
                    var_required={true}
                    var_value={ListeJoueurs.length > 0 && IdElement !== 'ajouter' ? ListeJoueurs[0].prenom : ''}
                />
                <FormInputDate
                    var_readOnly={false}
                    var_nom={'date de naissance'}
                    var_class={"bg-main"}
                    var_required={false}
                    var_value={ListeJoueurs.length > 0 && IdElement !== 'ajouter' ? ListeJoueurs[0].date_de_naissance : ''}
                />
                <FormInputEmail
                    var_readOnly={false}
                    var_nom={'email'}
                    var_class={"bg-main"}
                    var_value={ListeJoueurs.length > 0 && IdElement !== 'ajouter' ? ListeJoueurs[0].email : ''}
                    var_required={false}
                />
                <FormInputTel
                    var_readOnly={false}
                    var_nom={'téléphone joueur'}
                    var_class={"bg-main"}
                    var_value={ListeJoueurs.length > 0 && IdElement !== 'ajouter' ? ListeJoueurs[0].telephone_joueur : ''}
                />
                <FormInputTel
                    var_readOnly={false}
                    var_nom={'téléphone père'}
                    var_class={"bg-main"}
                    var_value={ListeJoueurs.length > 0 && IdElement !== 'ajouter' ? ListeJoueurs[0].telephone_pere : ''}
                />
                <FormInputTel
                    var_readOnly={false}
                    var_nom={'téléphone mère'}
                    var_class={"bg-main"}
                    var_value={ListeJoueurs.length > 0 && IdElement !== 'ajouter' ? ListeJoueurs[0].telephone_mere : ''}
                />
                <FormSelect
                    var_readOnly={false}
                    var_nom={'catégorie'}
                    var_class={"bg-main"}
                    var_array={ListeCategories}
                    var_required={true}
                    var_value={ListeJoueurs.length > 0 && IdElement !== 'ajouter' ? ListeJoueurs[0].categorie : ''}
                />
                <FormSelectMultiple
                    var_option={options}
                    var_nom={'groupe entrainement'}
                    var_description_label={''}
                    var_class={"bg-main groupesEntrainements"}
                    var_array={ListeGroupesEntrainements}
                    // var_height={'100px'}
                    var_value={ListeGroupesEntrainementsSelected.length > 0 && IdElement !== 'ajouter' ? ListeGroupesEntrainementsSelected : ''}
                    var_selectedIds={SelectedGroupesEntrainementsIds}
                    var_required={false}
                    var_selectedMax={''}
                />
                <FormSelectMultiple
                    var_option={options}
                    var_nom={'postes'}
                    var_description_label={'(3 postes maximum)'}
                    var_class={"bg-main postes"}
                    var_array={ListePostes}
                    // var_height={'100px'}
                    var_value={ListeJoueurs.length > 0 && IdElement !== 'ajouter' ? ListeJoueurs[0].postes : ''}
                    var_selectedIds={SelectedPostesIds}
                    var_required={true}
                    var_selectedMax={3}
                />
                <FormSelect
                    var_readOnly={false}
                    var_nom={'pied fort'}
                    var_class={"bg-main"}
                    var_array={ListePiedFort.map(item => item.nom)}
                    var_required={true}
                    var_value={ListeJoueurs.length > 0 && IdElement !== 'ajouter' ? ListeJoueurs[0].pied_fort : ''}
                />
                <FormInputTel
                    var_readOnly={false}
                    var_nom={'numéro licence'}
                    var_class={"bg-main"}
                    var_value={ListeJoueurs.length > 0 && IdElement !== 'ajouter' ? ListeJoueurs[0].numero_licence : ''}
                />
                <FormSelect
                    var_readOnly={false}
                    var_nom={'licence'}
                    var_class={"bg-main"}
                    var_array={ListeLicenceStatuts}
                    var_required={true}
                    var_value={ListeJoueurs.length > 0 && IdElement !== 'ajouter' ? ListeJoueurs[0].licence : ''}
                />
                <FormSelect
                    var_readOnly={false}
                    var_nom={'mutation'}
                    var_class={"bg-main"}
                    var_array={ListeMutations.map(item => item.nom)}
                    var_required={true}
                    var_value={ListeJoueurs.length > 0 && IdElement !== 'ajouter' ? ListeJoueurs[0].mutation : ''}
                />
                <FormTextarea
                    var_readOnly={false}
                    var_nom={'commentaires'}
                    var_class={"bg-main"}
                    var_required={false}
                    var_value={ListeJoueurs.length > 0 && IdElement !== 'ajouter' ? ListeJoueurs[0].commentaires : ''}
                />
                <FormInputSubmit
                    var_nom={'submit'}
                    var_value={IdElement === 'ajouter' ? 'ajouter' : 'sauvegarder'}
                    var_class={'bg-principal'}
                />
                {IdElement !== 'ajouter' && (
                    <FormBouton
                        var_nom={'supprimer'}
                        var_value={'supprimer'}
                        var_class={'bg-supprimer'}
                        var_handleButtonClick={handleButtonClick}
                    />
                )}
            </form>

            <Modal
                showModal={ShowModal}
                contentModal={ContentModal}
                handleCloseModal={() => {
                    setShowModal(false);
                    setContentModal("");
                    handleReloadPageClick();
                }}
                infosModal={InfosModal}
            />
        </div>
    )
}

export default InformationsJoueurs;
