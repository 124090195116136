import React, { useContext, useEffect } from 'react';
import { SettingsContext } from './settings/RecuperationSettings';
import { Link } from 'react-router-dom';

const HeaderPrincipal = () => {
  ///////////////////////////
  // RECUPERATION SETTINGS //
  ///////////////////////////

  const { settings } = useContext(SettingsContext);
  let NomSite = '';
  let NomSitePolice = '';
  let NomSiteTailleTexte = '';
  let NomSiteCouleurTexte = '';
  let LogoTaille = '';

  if (Array.isArray(settings)) {
    const settingsNomSite = settings.find((setting) => setting.name === 'NOM_SITE');
    NomSite = settingsNomSite?.value || '';
    const settingsNomSitePolice = settings.find((setting) => setting.name === 'NOM_SITE_POLICE');
    NomSitePolice = settingsNomSitePolice?.value || '';
    const settingsNomSiteTailleTexte = settings.find((setting) => setting.name === 'NOM_SITE_TAILLE_TEXTE');
    NomSiteTailleTexte = settingsNomSiteTailleTexte?.value || '';
    const settingsNomSiteCouleurTexte = settings.find((setting) => setting.name === 'NOM_SITE_COULEUR_TEXTE');
    NomSiteCouleurTexte = settingsNomSiteCouleurTexte?.value || '';
    const settingsLogoTaille = settings.find((setting) => setting.name === 'LOGO_TAILLE');
    LogoTaille = settingsLogoTaille?.value || '';
  }

  // CHANGER LA TAILLE DU HEADER
  useEffect(() => {
    // CHANGER GRID TEMPLATE ROW SITE-CONTAINER
    document.documentElement.style.setProperty('--gridTemplateRows-siteContainer', '66px 1fr 66px');
  }, []);


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="header-container bg-principal site-children">
      <div className='header-logoContainer'>
        <Link to="/">
          <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" style={{ maxHeight: LogoTaille, maxWidth: LogoTaille }} />
        </Link>
      </div>
      <div className='header-nomSiteContainer'>
        <Link to="/" style={{ color: NomSiteCouleurTexte, fontSize: NomSiteTailleTexte, fontFamily: NomSitePolice }}>
          <div className="header-titre bg-principal">{NomSite}</div>
        </Link>
      </div>
    </div>
  );
};

export default HeaderPrincipal;
